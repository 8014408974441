import {
    Box,
    CardBody,
    HStack,
    Heading,
    Image,
    Stack,
    Text,
    VStack,
    CardFooter,
    Button,
    Card,
    SimpleGrid
} from '@chakra-ui/react';
import React from 'react';

export default function SavedCars() {
    return (
        <>
            <VStack p={4} w={'100%'} alignItems={'start'} bg={'#FBFBFB'} mb={8}>
                <Text color={'#575757'} fontWeight={600} fontSize={'sm'} pb={4}>
                    Your Saved Cars
                </Text>

                <SimpleGrid
                 columns={{ base: 1, md: 2, lg: 3 }}
                 spacing='6'
                >
                
                {/* 1. Saved Card */}
                <Card maxW='xs'>
                    <CardBody>
                        <Image
                            src='./car1.png'
                            alt='car image'
                            borderRadius='lg'
                        />

                        <HStack
                            w={'88%'}
                            justifyContent={'space-between'}
                            position={'absolute'}
                            top='5'
                        >
                            <Box
                                bg='#FFFFFF'
                                display='flex'
                                justifyContent='center'
                                alignItems='center'
                                rounded='25'
                                p={1}
                                ml={2}
                            >
                                <HStack>
                                    <Image src='./star.png' height={18} />
                                    <Text opacity={'0.9'} fontSize={'xs'} pr={1} fontWeight={600}>78 Points</Text>
                                </HStack>
                            </Box>

                            <Image mr={2} src='./save_fill.png' h={38} />

                        </HStack>

                        <Stack mt='4' spacing='2'>
                            <Heading fontWeight={800} opacity={'0.8'} letterSpacing={'1px'} size={'sm'}>RAM RAM PICKUP</Heading>
                            <HStack justify={'space-between'}>
                                <Text color={'#707070'} opacity={'0.8'} fontSize={'sm'} fontWeight={'500'}>2019</Text>
                                <Text color={'#E22937'} opacity={'0.8'} fontSize={'md'} fontWeight={'700'}>$33,999</Text>
                            </HStack>
                            <HStack justify={'space-between'}>
                                <Text color={'#707070'} opacity={'0.8'} fontSize={'xs'} fontWeight={'400'}>Miles</Text>
                                <Text color={'#707070'} opacity={'0.8'} fontSize={'xs'} fontWeight={'400'}>131,576</Text>
                            </HStack>
                        </Stack>
                    </CardBody>
                    <CardFooter pt={0} pb={4}>
                        <Button
                            //   onClick={() => navigate('/car-details')}
                            bg="#153373" color={'white'}
                            py={4}
                            rounded={'34px'}
                            width="100%"
                            fontSize={'xs'}
                        >
                            Send Notification
                        </Button>
                    </CardFooter>
                </Card>

                {/* 2nd Saved Car */}
                <Card maxW='xs'>
                    <CardBody>
                        <Image
                            src='./car1.png'
                            alt='car image'
                            borderRadius='lg'
                        />

                        <HStack
                            w={'88%'}
                            justifyContent={'space-between'}
                            position={'absolute'}
                            top='5'
                        >
                            <Box
                                bg='#FFFFFF'
                                display='flex'
                                justifyContent='center'
                                alignItems='center'
                                rounded='25'
                                p={1}
                                ml={2}
                            >
                                <HStack>
                                    <Image src='./star.png' height={18} />
                                    <Text opacity={'0.9'} fontSize={'xs'} pr={1} fontWeight={600}>78 Points</Text>
                                </HStack>
                            </Box>

                            <Image mr={2} src='./save_fill.png' h={38} />

                        </HStack>

                        <Stack mt='4' spacing='2'>
                            <Heading fontWeight={800} opacity={'0.8'} letterSpacing={'1px'} size={'sm'}>BMW X3</Heading>
                            <HStack justify={'space-between'}>
                                <Text color={'#707070'} opacity={'0.8'} fontSize={'sm'} fontWeight={'500'}>2019</Text>
                                <Text color={'#E22937'} opacity={'0.8'} fontSize={'md'} fontWeight={'700'}>$33,999</Text>
                            </HStack>
                            <HStack justify={'space-between'}>
                                <Text color={'#707070'} opacity={'0.8'} fontSize={'xs'} fontWeight={'400'}>Miles</Text>
                                <Text color={'#707070'} opacity={'0.8'} fontSize={'xs'} fontWeight={'400'}>131,576</Text>
                            </HStack>
                        </Stack>
                    </CardBody>
                    <CardFooter pt={0} pb={4}>
                        <Button
                            variant='outline' 
                            color={'#E22937'}
                            borderColor={'#E22937'}
                            py={4}
                            rounded={'34px'}
                            width="100%"
                            fontSize={'xs'}
                        >
                            Stop Notification
                        </Button>
                    </CardFooter>
                </Card>

                {/* 3rd Saved Car */}
                <Card maxW='xs'>
                    <CardBody>
                        <Image
                            src='./car1.png'
                            alt='car image'
                            borderRadius='lg'
                        />

                        <HStack
                            w={'88%'}
                            justifyContent={'space-between'}
                            position={'absolute'}
                            top='5'
                        >
                            <Box
                                bg='#FFFFFF'
                                display='flex'
                                justifyContent='center'
                                alignItems='center'
                                rounded='25'
                                p={1}
                                ml={2}
                            >
                                <HStack>
                                    <Image src='./star.png' height={18} />
                                    <Text opacity={'0.9'} fontSize={'xs'} pr={1} fontWeight={600}>78 Points</Text>
                                </HStack>
                            </Box>

                            <Image mr={2} src='./save_fill.png' h={38} />

                        </HStack>

                        <Stack mt='4' spacing='2'>
                            <Heading fontWeight={800} opacity={'0.8'} letterSpacing={'1px'} size={'sm'}>HONDA PILOT</Heading>
                            <HStack justify={'space-between'}>
                                <Text color={'#707070'} opacity={'0.8'} fontSize={'sm'} fontWeight={'500'}>2019</Text>
                                <Text color={'#E22937'} opacity={'0.8'} fontSize={'md'} fontWeight={'700'}>$33,999</Text>
                            </HStack>
                            <HStack justify={'space-between'}>
                                <Text color={'#707070'} opacity={'0.8'} fontSize={'xs'} fontWeight={'400'}>Miles</Text>
                                <Text color={'#707070'} opacity={'0.8'} fontSize={'xs'} fontWeight={'400'}>131,576</Text>
                            </HStack>
                        </Stack>
                    </CardBody>
                    <CardFooter pt={0} pb={4}>
                    <Button
                            variant='outline' 
                            color={'#E22937'}
                            borderColor={'#E22937'}
                            py={4}
                            rounded={'34px'}
                            width="100%"
                            fontSize={'xs'}
                        >
                            Stop Notification
                        </Button>
                    </CardFooter>
                </Card>

                </SimpleGrid>
            </VStack>
        </>
    )
}
