import React from 'react'
import {
    VStack,
    Heading,
    Text,
    Image,

} from '@chakra-ui/react';
import NotAuthLayout from '../../components/auth/NotAuthLayout';


export default function RegisterDone() {

    return (
        <NotAuthLayout>
            <VStack w='100%' align={'center'} pt={12} pr={{ md: 24}}>
                <VStack color={'#ffffff'}>
                    <Heading fontSize='3xl' fontWeight={'bold'}>Account Created,</Heading>
                    <Text fontSize='1xl' mb={2} opacity={'0.7'}>Please login to your account</Text>
                </VStack>
                <Image src='/done.png' h={150} />
            </VStack>
        </NotAuthLayout>
    );
}



