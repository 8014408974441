import { Flex, Text } from '@chakra-ui/react'
import React from 'react'

export default function BreadCrumb({imageUrl, title}) {
  return (
    <Flex
    maxW={'100%'}
     style={{
        backgroundImage: imageUrl,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center'
     }}
     display={{base:'none', md:'flex'}}
    >
     <Text 
     color={'#FFFFFF'} 
     fontSize={{base:'1xl', md:'2xl'}}
     fontWeight={'500'}
     px={40}
     py={9}
     >
       {title}
     </Text>
    </Flex>
  )
}
