import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalFooter,
    Button,
    Text,
    VStack,
    Flex,
    Image,
    ModalCloseButton,
    Container,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

export default function VinNumber({ isOpen, onClose, onOpen }) {
    const navigate = useNavigate();

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose} isCentered onOpen size={{base:'xs', md:'xl'}} >
                <ModalOverlay />
                <ModalContent>
                
                    <VStack mx={'6'} pt={8}>
                    <ModalCloseButton />
                        <Text
                         color={'#102756'}
                         fontWeight={'400'}
                         fontSize={'xs'}
                         textAlign='center'
                         pb={2}
                        >
                        VIN Number
                        </Text>

                        <Container bg={'#F2F2F2'} w={'80%'}>
                        <Text 
                         p={2} 
                         textAlign='center' 
                         fontSize={{ base: 'sm', md: 'md' }} 
                         fontWeight={'500'} 
                         color={'#102756'} 
                        >
                          5 N P D H 4 A W 0 D H 2 1 3 9 2 4
                        </Text>
                        </Container>

                        <Image src='./vin_demo.png' h={200} my={{base:6, md:2}} />
                    </VStack>

                </ModalContent>
            </Modal>
        </>
    )
}


