import React from 'react';
import { Modal, ModalOverlay, ModalContent, ModalBody, Text, Box, useColorMode, keyframes } from '@chakra-ui/react';

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const LoadingModal = ({ isOpen }) => {
  const { colorMode } = useColorMode();
  const bgColor = { light: 'white', dark: 'gray.800' };
  const textColor = { light: 'gray.800', dark: 'white' };

  return (
    <Modal isOpen={isOpen} onClose={() => {}} isCentered>
      <ModalOverlay
        css={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'rgba(0, 0, 0, 0.75)',
          position: 'absolute',
          zIndex: 100,
          height: '100%',
          width: '100%',
          animation: `${fadeIn} 0.3s ease-in-out`,
        }}
      />
      <ModalContent
        bg={bgColor[colorMode]}
        borderRadius="lg"
        boxShadow="xl"
        p={6}
        display="flex"
        flexDirection="column"
        alignItems="center"
        animation={`${fadeIn} 0.3s ease-in-out`}
      >
        <ModalBody display="flex" flexDirection="column" alignItems="center" justifyContent="center" py={6}>
          <Box
            css={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '40px',
              height: '40px',
              animation: `${spin} 1s linear infinite`,
              border: '4px solid rgba(0, 0, 0, 0.1)',
              borderTop: `4px solid ${colorMode === 'light' ? '#3498db' : '#90cdf4'}`,
              borderRadius: '50%',
            }}
          />
          <Text mt={4} fontSize="lg" color={textColor[colorMode]} fontWeight="bold">
            Loading...
          </Text>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default LoadingModal;
