import {
    Button,
    Card,
    SimpleGrid,
    VStack,
    Text,
    HStack,
    useDisclosure,

} from '@chakra-ui/react';
import React from 'react';
import { Divider } from '@chakra-ui/react';
import { FaRegQuestionCircle } from "react-icons/fa";
import UploadPictureInstructions from '../../../models/UploadPictureInstructions';
import ImageUploadBox from './ImageUploadBox';
import { notifyError } from '../../utils/ToastNotifications';

export default function VinNumber({ currentStep, setCurrentStep ,formik }) {
    const { isOpen, onOpen, onClose } = useDisclosure();

    const handleFileSelect = (file, type) => {
        const fileNames = {
            exterior: 'Exterior Picture',
            dashboard: 'Dashboard Picture',
            engine: 'Engine Picture',
            trunk: 'Trunk Picture',
            accelerometer: 'Accelerometer Picture',
            roof: 'Roof Picture',
            seats: 'Seats Picture',
        };

        const fileExtension = file.name.split('.').pop();
        const newFileName = `${fileNames[type]}.${fileExtension}`;
        const newFile = new File([file], newFileName, { type: file.type });

        formik.setFieldValue('vehiclePhotos', [
            ...formik.values.vehiclePhotos.filter(photo => photo.name !== newFileName),
            newFile
        ]);
    };
    const handleFileDelete = (type) => {
        const fileNames = {
            exterior: 'Exterior Picture',
            dashboard: 'Dashboard Picture',
            engine: 'Engine Picture',
            trunk: 'Trunk Picture',
            accelerometer: 'Accelerometer Picture',
            roof: 'Roof Picture',
            seats: 'Seats Picture',
        };

        formik.setFieldValue('vehiclePhotos', formik.values.vehiclePhotos.filter(photo => !photo.name.startsWith(fileNames[type])));
    };
    const handleContinue = async () => {
        const errors = await formik.validateForm();
        formik.setTouched({
            vehiclePhotos: true,
        });

        if (Object.keys(errors).length === 0) {
            setCurrentStep(currentStep + 1);
        }else{
            notifyError('Upload the All Required Photos!')
        }
    };

console.log(formik.values.vehiclePhotos);
    return (
        <Card
            bg='white'
            borderRadius={'48px'}
            style={{
                boxShadow: '0px 10px 99px #00000029'
            }}
            py={{ base: '8', lg: '6' }}
            px={{ base: '6', md: '12', lg: '16' }}
            w={{ base: '80%', md: '90%', lg: '60%' }}
            my={{ base: 4, md: 6 }}
            mb={{ base: 4, md: 52, lg: 24 }}
        >

            <VStack maxW='100%' w='100%' justifyContent='start' alignItems='start'>
                <HStack w={'100%'} justify={'space-between'} mr={4}>
                    <Text
                        textAlign={{ base: 'center', md: 'start' }}
                        w={{ base: '100%', md: '80%' }}
                        fontSize={{ base: 'xs', md: 'sm', lg: 'md' }}
                        fontWeight={'500'}
                        color={'#153373'}
                        opacity={'0.8'}
                    >
                        Upload Vehicle Pictures
                    </Text>

                    <FaRegQuestionCircle
                        cursor={'pointer'}
                        onClick={() => onOpen()}
                        color='#153373'
                    />

                    <UploadPictureInstructions isOpen={isOpen} onClose={onClose} onOpen={onOpen} />

                </HStack>
                <Divider borderWidth={'1px'} borderColor={'#e3e1e1'} alignItems={'center'} w={'100%'} my={2} />
            </VStack>

            <SimpleGrid
                columns={{ base: 1, md: 2, lg: 4 }}
                spacing={{ base: '8', lg: '4' }}
                w={'100%'}
                py={4}
            >

                {/* <Box border={"2px dashed #707070"} p={6} borderRadius={12}>
                    <VStack spacing={1} width={'100%'} alignContent={'center'} justify={'center'} px={4}>
                        <FiUpload color='#25BC9D' size={'22px'} />
                        <Text color='#25BC9D' fontSize={'9px'} fontWeight={600} textAlign={'center'}>
                            Upload/Drop a 3D File here.
                        </Text>
                        <Text color='#A0A0A0' fontSize={'9px'}>
                            (max 25 MB)
                        </Text>
                    </VStack>
                </Box> */}
                
                <VStack>
                    <Text
                        w={'100%'}
                        textAlign={'start'}
                        color={'#575757'}
                        fontSize={'sm'}
                        fontWeight={600}
                        opacity={'0.9'}
                    >
                        Exterior Picture<Text color={'#f33'} as='sup'>*</Text>
                    </Text>
                    <ImageUploadBox onFileSelect={handleFileSelect} onFileDelete={handleFileDelete} type="exterior" />
                    
                </VStack>

                <VStack>
                    <Text
                        w={'100%'}
                        textAlign={'start'}
                        color={'#575757'}
                        fontSize={'sm'}
                        fontWeight={600}
                        opacity={'0.9'}
                    >
                        Dashboard Picture<Text color={'#f33'} as='sup'>*</Text>
                    </Text>
                    <ImageUploadBox onFileSelect={handleFileSelect} onFileDelete={handleFileDelete} type="dashboard" />
                    
                </VStack>

                <VStack>
                    <Text
                        w={'100%'}
                        textAlign={'start'}
                        color={'#575757'}
                        fontSize={'sm'}
                        fontWeight={600}
                        opacity={'0.9'}
                    >
                        Engine Picture<Text color={'#f33'} as='sup'>*</Text>
                    </Text>
                    <ImageUploadBox onFileSelect={handleFileSelect} onFileDelete={handleFileDelete} type="engine" />
                </VStack>

                <VStack>
                    <Text
                        w={'100%'}
                        textAlign={'start'}
                        color={'#575757'}
                        fontSize={'sm'}
                        fontWeight={600}
                        opacity={'0.9'}
                    >
                        Trunk Picture<Text color={'#f33'} as='sup'>*</Text>
                    </Text>
                    <ImageUploadBox onFileSelect={handleFileSelect} onFileDelete={handleFileDelete} type="trunk" />
                </VStack>

                <VStack>
                    <Text
                        w={'100%'}
                        textAlign={'start'}
                        color={'#575757'}
                        fontSize={'sm'}
                        fontWeight={600}
                        opacity={'0.9'}
                    >
                        Accelerometer Picture<Text color={'#f33'} as='sup'>*</Text>
                    </Text>
                    <ImageUploadBox onFileSelect={handleFileSelect} onFileDelete={handleFileDelete} type="accelerometer" />
                </VStack>

                <VStack>
                    <Text
                        w={'100%'}
                        textAlign={'start'}
                        color={'#575757'}
                        fontSize={'sm'}
                        fontWeight={600}
                        opacity={'0.9'}
                    >
                        Roof Picture<Text color={'#f33'} as='sup'>*</Text>
                    </Text>
                    <ImageUploadBox onFileSelect={handleFileSelect} onFileDelete={handleFileDelete} type="roof" />
                </VStack>

                <VStack>
                    <Text
                        w={'100%'}
                        textAlign={'start'}
                        color={'#575757'}
                        fontSize={'sm'}
                        fontWeight={600}
                        opacity={'0.9'}
                    >
                        Seats Picture<Text color={'#f33'} as='sup'>*</Text>
                    </Text>
                    <ImageUploadBox onFileSelect={handleFileSelect} onFileDelete={handleFileDelete} type="seats" />
                </VStack>

            </SimpleGrid>

            <Button
                colorScheme="red"
                py={6}
                rounded={'34px'}
                w={{ base: '60%', md: '30%', lg: '20%' }}
                position={'absolute'}
                bottom={'0'}
                left={'50%'}
                transform={'translate(-50%, 50%)'}
                fontSize={'xs'}
                onClick={() =>handleContinue()}
            >
                Continue
            </Button>
        </Card>
    )
}


