import { Button, Flex, FormControl, SimpleGrid, Text, VStack, Select, Container } from '@chakra-ui/react';
import React from 'react';
import { FaArrowUp } from "react-icons/fa";
import { years } from '../utils/DropDataList';

export default function ApplyFilter({formik,applyFilter}) {
 
    return (
        <Flex
            direction={'column'}
            w={'100%'}
            bg={'#ECF0F3'}
            align={'center'}
            py={4}
            position={'sticky'}
            bottom={0}
            transform={`"translateY(${90}%)"`}
            // transform={` "translateY(${applyFilter}%)" `}
        // zIndex={100}
        >
            <Container
                w={'100%'}
                justifyContent={'center'}
                position={'absolute'}
                top={'0'}
                left={'50%'}
                transform={'translate(-50%, 50%)'}
                display={{ base: 'flex', md: 'none', lg: 'none' }}
            >
                <FaArrowUp />
            </Container>

            <SimpleGrid
                mt={{base:4, md:0}}
                columns={{ base: 2, md: 3, lg: 4 }}
                spacing='4'
                width={'100%'}
                justifyContent={'center'}
                px={{ base: '8', md: '12', lg: '44' }}>

                <VStack align={'start'}>
                    <Text color={'#707070'} fontSize={'xs'} opacity={'0.8'} fontWeight={'400'}>Select Year</Text>
                    <FormControl>
                        <Select
                            placeholder='Select'
                            id='year'
                            name='year'
                            onChange={formik.handleChange}
                            value={formik.values.year}
                            rounded={26}
                            bg={'#fffff'}
                            fontSize={'xs'}
                            color={'#707070'}
                        >
                            {years?.map((year) => (
                                  <option key={year} value={year}>
                                    {year}
                                  </option>
                                ))}

                                
                        </Select>
                    </FormControl>
                </VStack>

                <VStack align={'start'}>
                    <Text fontSize={'xs'} color={'#707070'} opacity={'0.8'} fontWeight={'400'}>Select Make</Text>
                    <FormControl>
                        <Select
                            placeholder='Select Make'
                            id='make'
                            name='make'
                            onChange={formik.handleChange}
                            value={formik.values.make}
                            rounded={26}
                            bg={'#fffff'}
                            fontSize={'xs'}
                            color={'#707070'}
                        >
                                <option value='Toyota'>Toyota</option>
                                <option value='Honda'>Honda</option>
                                <option value='Ford'>Ford</option>
                                <option value='BMW'>BMW</option>
                        </Select>
                    </FormControl>
                </VStack>

                <VStack align={'start'}>
                    <Text fontSize={'xs'} color={'#707070'} opacity={'0.8'} fontWeight={'400'}>Select Model</Text>
                    <FormControl>
                        <Select
                            placeholder='Select'
                            id='model'
                            name='model'
                            onChange={formik.handleChange}
                            value={formik.values.model}
                            rounded={26}
                            bg={'#fffff'}
                            fontSize={'xs'}
                            color={'#707070'}
                        >
                            <option value='option1'>model1</option>
                            <option value='option1'>model2</option>
                            <option value='option2'>model3</option>
                            <option value='option3'>model4</option>
                        </Select>
                    </FormControl>
                </VStack>

                <VStack align={'start'}>
                    <Text fontSize={'xs'} color={'#707070'} opacity={'0.8'} fontWeight={'400'}>Select Trim</Text>
                    <FormControl>
                        <Select
                            placeholder='Select'
                            id='trims'
                            name='trims'
                            onChange={formik.handleChange}
                            value={formik.values.trims}
                            rounded={26}
                            bg={'#fffff'}
                            fontSize={'xs'}
                            color={'#707070'}
                        >
                            <option value='option1'>trim1</option>
                            <option value='option1'>trim2</option>
                            <option value='option2'>trim3</option>
                            <option value='option3'>trim4</option>
                        </Select>
                    </FormControl>
                </VStack>

                <VStack align={'start'}>
                    <Text fontSize={'xs'} color={'#707070'} opacity={'0.8'} fontWeight={'400'}>Score Range</Text>
                    <FormControl>
                        <Select
                            placeholder='Select'
                            id='priceRange'
                            name='priceRange'
                            onChange={formik.handleChange}
                            value={formik.values.priceRange}
                            rounded={26}
                            bg={'#fffff'}
                            fontSize={'xs'}
                            color={'#707070'}
                        >
                            <option value='option1'>priceRange1</option>
                            <option value='option1'>priceRange2</option>
                            <option value='option2'>priceRange3</option>
                            <option value='option3'>priceRange4</option>
                        </Select>
                    </FormControl>
                </VStack>

                <VStack align={'start'}>
                    <Text fontSize={'xs'} color={'#707070'} opacity={'0.8'} fontWeight={'400'}>Vehicle Mileage</Text>
                    <FormControl>
                        <Select
                            placeholder='Select'
                            id='mileageRange'
                            name='mileageRange'
                            onChange={formik.handleChange}
                            value={formik.values.mileageRange}
                            rounded={26}
                            bg={'#fffff'}
                            fontSize={'xs'}
                            color={'#707070'}
                        >
                            <option value='option1'>mileageRange1</option>
                            <option value='option1'>mileageRange2</option>
                            <option value='option2'>mileageRange3</option>
                            <option value='option3'>mileageRange4</option>
                        </Select>
                    </FormControl>
                </VStack>

                <VStack align={'start'}>
                    <Text fontSize={'xs'} color={'#707070'} opacity={'0.8'} fontWeight={'400'}>Distance From me</Text>
                    <FormControl>
                        <Select
                            placeholder='Select'
                            id='selectDistance'
                            name='selectDistance'
                            onChange={formik.handleChange}
                            value={formik.values.selectDistance}
                            rounded={26}
                            bg={'#fffff'}
                            fontSize={'xs'}
                            color={'#707070'}
                        >
                            <option value='option1'>24km</option>
                            <option value='option1'>32km</option>
                            <option value='option2'>114km</option>
                            <option value='option3'>34km</option>
                        </Select>
                    </FormControl>
                </VStack>

                <Button
                    fontSize={'xs'}
                    mt={6}
                    bg="#153373"
                    color={'white'}
                    py={6}
                    rounded={'34px'}
                    width={"100%"}
                    onClick={() =>formik.handleSubmit()}
                    
                >
                    Apply Filter
                </Button>
            </SimpleGrid>
        </Flex>


    )
}


