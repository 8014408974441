import {
    VStack,
    Text,
    Box,
    FormControl,
    Input,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { FiUpload } from "react-icons/fi";

const ImageUploadBox = ({ onFileSelect, onFileDelete, type }) => {
    const [selectedFile, setSelectedFile] = useState(null);

    const handleChange = (event) => {
        const file = event.target.files[0];
        if (file && file.type.startsWith('image/')) {
            setSelectedFile(file);
            onFileSelect(file, type);
        } else {
            setSelectedFile(null);
            alert('Please select an image file.');
        }
    };

    const handleDelete = () => {
        setSelectedFile(null);
        onFileDelete(type);
    };

    return (
        <Box border={"2px dashed #707070"} p={6} borderRadius={12}>
            <VStack spacing={1} width={'100%'} alignContent={'center'} justify={'center'} px={4}>
                {!selectedFile ? (
                    <>
                        <FiUpload color='#25BC9D' size={'22px'} cursor="pointer" />
                        <FormControl as="label" htmlFor={`file-upload-${type}`}>
                            <Input
                                id={`file-upload-${type}`}
                                type="file"
                                accept="image/*"
                                onChange={handleChange}
                                hidden
                            />
                            <Text cursor={'pointer'} color='#25BC9D' fontSize={'9px'} fontWeight={600} textAlign={'center'}>
                                Upload/Drop an Image here.
                            </Text>
                            <Text color='#A0A0A0' fontSize={'9px'} textAlign={'center'}>
                                (max 25 MB)
                            </Text>
                        </FormControl>
                    </>
                ) : (
                    <Box mt={2} position="relative">
                        <Box as="img" src={URL.createObjectURL(selectedFile)} alt="Selected Image" width="100%"  maxHeight={'250px'} />
                        <Text cursor="pointer" color='#FF0000' fontSize={'9px'} fontWeight={600} textAlign={'center'} onClick={handleDelete}>
                            Delete
                        </Text>
                    </Box>
                )}
            </VStack>
        </Box>
    );
};

export default ImageUploadBox;
