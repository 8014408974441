import React from 'react';
import NavBar from '../../components/home/navBar/NavBar';
import BreadCrumb from '../../components/home/navBar/BreadCrumb';
import SubscribeForm from '../../components/SubscribeForm';
import Buttom from '../../components/home/footer/Bottom';
import Footer from '../../components/home/footer/Footer';
import GetCarLoan from '../../components/car/GetCarLoan';
import { Box, Text, VStack} from '@chakra-ui/react';
import { Divider } from '@chakra-ui/react';

export default function GetALoanCar() {
    return (
        <>
            <NavBar />
            <BreadCrumb imageUrl={"url('./sell_a_vehicle.png')"} title={'Financing Solution'} />

            <Box maxW={'100%'} px={{ base: '8', md: '20' }} py={12}>
                <GetCarLoan />
            </Box>

            <VStack pb={12} mx={{base:'4', md:'0'}}>
                <Text
                    fontSize='2xl'
                    fontWeight={'500'}
                    color={'#153373'}
                    pt={4}
                    letterSpacing={1}
                    opacity={'0.9'}
                    align={'center'}
                >
                    SIMPLIFYING THE PURCHASING PROCESS
                </Text>

                <Divider borderWidth={'2px'} borderColor={'red'} alignItems={'center'} w={{ base: '25%', md: '20%', lg: '10%' }} mb={4} />

                <Text
                    fontSize={'14px'}
                    w={{ base: 'auto', md: '85%', lg: '65%' }}
                    color={'#707070'}
                    opacity={'0.8'}
                >
                    Cars for Sale & Loans Marketplace work with competitive financing companies that provide great rates on all new and pre-owned vehicles. Our advisors will guide you through the entire process, from the application to the final signature. 
                </Text>

                <Text
                    fontSize={'14px'}
                    w={{ base: 'auto', md: '85%', lg: '65%' }}
                    color={'#707070'}
                    opacity={'0.8'}
                >
                    We accept payments via credit card, check, and cash, and offer flexible financing options. So whether you want to buy cash or finance a vehicle, we’ve got the right plan that fits your needs.
                </Text>
            </VStack>

            <SubscribeForm />
            <Footer />
        </>
    )
}

