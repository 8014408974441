import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalFooter,
    Button,
    Text,
    VStack,
    Flex,
    Image,
    ModalCloseButton,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

export default function OfferMadeSuccess({ isOpen, onClose, onOpen }) {
    const navigate = useNavigate();

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose} isCentered onOpen size={{base:'xs', md:'lg'}}>
                <ModalOverlay />
                <ModalContent>
                
                    <VStack mx={'6'} pt={8}>
                    <ModalCloseButton />
                        <Flex w={'100%'} alignItems={'center'} justifyContent={'center'} mt={{ base: '4', md: '0' }}>
                            <Image src='./done.png' h={100} />
                        </Flex>

                        <Text textAlign='center' w={{ base: '100%', md: '80%' }} fontSize={{ base: 'md', md: 'xl' }} fontWeight={'500'} color={'#153373'} opacity={'0.8'}>
                            Offer Made Successfully!
                        </Text>

                        <Text
                            color={'#707070'}
                            opacity={'0.8'}
                            fontWeight={'400'}
                            fontSize={'xs'}
                            pt={4}
                            textAlign='center'
                            pb={8}
                        >
                            Your offer request has been sent, you will see the car owner details once your offer is accepted.
                        </Text>
                    </VStack>

                </ModalContent>
            </Modal>
        </>
    )
}

