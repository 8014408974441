import React from 'react'
import TopBar from '../home/navBar/TopBar'
import NavBar from '../home/navBar/NavBar'
import BreadCrumb from '../home/navBar/BreadCrumb'
import SubscribeForm from '../SubscribeForm'
import Bottom from '../home/footer/Bottom'
import Footer from '../home/footer/Footer'
import { Text, VStack } from '@chakra-ui/react'

export default function PrivacyPolicy() {
    return (
        <>
            <TopBar />
            <NavBar />
            <BreadCrumb imageUrl={"url('./people.png')"} title={'Privacy Policy'} />

            <VStack py={12} w={'100%'} textAlign={'center'} spacing={4}>
                <Text
                    fontSize={'14px'}
                    w={{ base: 'auto', md: '85%', lg: '65%' }}
                    color={'#707070'}
                    opacity={'0.8'}
                >
                    OUR COMMITMENT TO YOU
                </Text>

                <Text
                    fontSize={'14px'}
                    w={{ base: 'auto', md: '85%', lg: '65%' }}
                    color={'#707070'}
                    opacity={'0.8'}
                >
                    At APP, your privacy is a top priority. Your privacy is at the core of the way we design and build the services and products you know and love, so that you can fully trust them and focus on building meaningful connections.
                </Text>

                <Text
                    fontSize={'14px'}
                    w={{ base: 'auto', md: '85%', lg: '65%' }}
                    color={'#707070'}
                    opacity={'0.8'}
                >
                    We appreciate that you put your trust in us when you provide us with your information and we do not take this lightly.
                </Text>

                <Text
                    fontSize={'14px'}
                    w={{ base: 'auto', md: '85%', lg: '65%' }}
                    color={'#707070'}
                    opacity={'0.8'}
                >
                    We do not compromise with your privacy. We design all of our products and services with your privacy in mind. We involve experts from various fields, including legal, security, engineering, product design and others to make sure that no decision is taken without respect for your privacy.
                </Text>

                <Text
                    fontSize={'14px'}
                    w={{ base: 'auto', md: '85%', lg: '65%' }}
                    color={'#707070'}
                    opacity={'0.8'}
                >
                    We strive to be transparent in the way we process your data. Because we use many of the same online services you do, we know that insufficient information and overly complicated language are common issues in privacy policies. We take the exact opposite approach: we have written our Privacy Policy and related documents in plain language. We actually want you to read our policies and understand our privacy practices!
                </Text>

                <Text
                    fontSize={'14px'}
                    w={{ base: 'auto', md: '85%', lg: '65%' }}
                    color={'#707070'}
                    opacity={'0.8'}
                >
                    We work hard to keep your information secure We have teams dedicated to keeping your data safe and secure. We constantly update our security practices and invest in our security efforts to enhance the safety of your information.
                </Text>

                <Text
                    fontSize={'14px'}
                    w={{ base: 'auto', md: '85%', lg: '65%' }}
                    color={'#707070'}
                    opacity={'0.8'}
                >
                    PRIVACY POLICY
                </Text>

                <Text
                    fontSize={'14px'}
                    w={{ base: 'auto', md: '85%', lg: '65%' }}
                    color={'#707070'}
                    opacity={'0.8'}
                >
                    Welcome to APP Privacy Policy. Thank you for taking the time to read it.
                </Text>

                <Text
                    fontSize={'14px'}
                    w={{ base: 'auto', md: '85%', lg: '65%' }}
                    color={'#707070'}
                    opacity={'0.8'}
                >
                    We appreciate that you trust us with your information and we intend to always keep that trust. This starts with making sure you understand the information we collect, why we collect it, how it is used and your choices regarding your information. This Policy describes our privacy practices in plain language, keeping legal and technical jargon to a minimum.
                </Text>

                <Text
                    fontSize={'14px'}
                    w={{ base: 'auto', md: '85%', lg: '65%' }}
                    color={'#707070'}
                    opacity={'0.8'}
                >
                    This Privacy Policy applies beginning May 25, 2018. The previous version of this Privacy Policy, available here, will apply until then.
                </Text>

                <Text
                    fontSize={'14px'}
                    w={{ base: 'auto', md: '85%', lg: '65%' }}
                    color={'#707070'}
                    opacity={'0.8'}
                >
                    EFFECTIVE DATE: May 25, 2018
                </Text>

                <Text
                    fontSize={'14px'}
                    w={{ base: 'auto', md: '85%', lg: '65%' }}
                    color={'#707070'}
                    opacity={'0.8'}
                >
                    1. WHO WE ARE
                </Text>

                <Text
                    fontSize={'14px'}
                    w={{ base: 'auto', md: '85%', lg: '65%' }}
                    color={'#707070'}
                    opacity={'0.8'}
                >
                    2. WHERE THIS PRIVACY POLICY APPLIES
                </Text>

                <Text
                    fontSize={'14px'}
                    w={{ base: 'auto', md: '85%', lg: '65%' }}
                    color={'#707070'}
                    opacity={'0.8'}
                >
                    3. INFORMATION WE COLLECT
                </Text>

                <Text
                    fontSize={'14px'}
                    w={{ base: 'auto', md: '85%', lg: '65%' }}
                    color={'#707070'}
                    opacity={'0.8'}
                >
                    4. COOKIES AND OTHER SIMILAR DATA COLLECTION TECHNOLOGIES
                </Text>

                <Text
                    fontSize={'14px'}
                    w={{ base: 'auto', md: '85%', lg: '65%' }}
                    color={'#707070'}
                    opacity={'0.8'}
                >
                    5. HOW WE USE INFORMATION
                </Text>

                <Text
                    fontSize={'14px'}
                    w={{ base: 'auto', md: '85%', lg: '65%' }}
                    color={'#707070'}
                    opacity={'0.8'}
                >
                    6. HOW WE SHARE INFORMATION
                </Text>

                <Text
                    fontSize={'14px'}
                    w={{ base: 'auto', md: '85%', lg: '65%' }}
                    color={'#707070'}
                    opacity={'0.8'}
                >
                    7. CROSS-BORDER DATA TRANSFERS
                </Text>

                <Text
                    fontSize={'14px'}
                    w={{ base: 'auto', md: '85%', lg: '65%' }}
                    color={'#707070'}
                    opacity={'0.8'}
                >
                    8. YOUR RIGHTS
                </Text>

                <Text
                    fontSize={'14px'}
                    w={{ base: 'auto', md: '85%', lg: '65%' }}
                    color={'#707070'}
                    opacity={'0.8'}
                >
                    9. RESIDENTS OF CALIFORNIA
                </Text>

                <Text
                    fontSize={'14px'}
                    w={{ base: 'auto', md: '85%', lg: '65%' }}
                    color={'#707070'}
                    opacity={'0.8'}
                >
                    10. HOW WE PROTECT YOUR INFORMATION
                </Text>

            </VStack>

            <SubscribeForm />
            <Bottom />
            <Footer />
        </>
    )
}

