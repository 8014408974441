import React, { useEffect, useState } from 'react'
import {
    Flex,
    Box,
    Image,
    VStack

} from '@chakra-ui/react';
import ButtomBar from '../home/footer/BottomBar';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../providers/AuthProvider';

export default function DefaultLayout({ children }) {
    const { logged,user } = useAuth();
    const navigate = useNavigate();


    const [proceed,setProceed] = useState(false);

    useEffect(()=>{
        if(!logged || logged){
            setProceed(true);
        }
        // else if(logged && user?.account_status === 0){
        //     setProceed(true);
        // }else{
        //         navigate('/sell-a-car');
        // }
    },[logged]);


    return (
        <>
        {
            (proceed) &&
            <Box 
            display="flex" 
            flexDirection="column" 
            height="100vh" 
            width="100%"
          >

                        {children}
                 
        </Box>
                    }
        </>

    )
}

