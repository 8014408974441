import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalFooter,
    Button,
    Text,
    VStack,
    Flex,
    Image,
    ModalCloseButton,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

export default function PaymentSuccessful({ isOpen, onClose, onOpen }) {
    const navigate = useNavigate();

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose} isCentered onOpen size={{base:'xs', md:'sm'}} >
                <ModalOverlay />
                <ModalContent>
                
                    <VStack mx={'6'} pt={{base:6, md:12}}>
                    <ModalCloseButton />
                        <Flex w={'100%'} alignItems={'center'} justifyContent={'center'} mt={{ base: '4', md: '0' }}>
                            <Image src='./done.png' h={120} />
                        </Flex>

                        <Text pb={12} textAlign='center' w={{ base: '100%', md: '80%' }} fontSize={{ base: 'md', md: 'xl' }} fontWeight={'500'} color={'#153373'} opacity={'0.8'}>
                        Payment Is Successful
                        </Text>
                    </VStack>

                </ModalContent>
            </Modal>
        </>
    )
}


