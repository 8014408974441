import React from 'react'
import TopBar from '../home/navBar/TopBar'
import NavBar from '../home/navBar/NavBar'
import BreadCrumb from '../home/navBar/BreadCrumb'
import SubscribeForm from '../SubscribeForm'
import Bottom from '../home/footer/Bottom'
import Footer from '../home/footer/Footer'
import { Text, VStack } from '@chakra-ui/react'

export default function TermsAndCondition() {
    return (
        <>
            <TopBar />
            <NavBar />
            <BreadCrumb imageUrl={"url('./people.png')"} title={'Terms of Use'} />

            <VStack py={12} w={'100%'} textAlign={'center'} spacing={4}>
                <Text
                    fontSize={'14px'}
                    w={{ base: 'auto', md: '85%', lg: '65%' }}
                    color={'#707070'}
                    opacity={'0.8'}
                >
                    Last revised on June 11, 2019
                </Text>

                <Text
                    fontSize={'14px'}
                    w={{ base: 'auto', md: '85%', lg: '65%' }}
                    color={'#707070'}
                    opacity={'0.8'}
                >
                    Welcome to APP, operated by Match Group, LLC (“us,” “we,” the “Company” or “APP”).
                </Text>

                <Text
                    fontSize={'14px'}
                    w={{ base: 'auto', md: '85%', lg: '65%' }}
                    color={'#707070'}
                    opacity={'0.8'}
                >
                    Notice to California subscribers: You may cancel your subscription, without penalty or obligation, at any time prior to midnight of the third business day following the date you subscribed. If you subscribed using your Apple ID, refunds are handled by Apple, not Tinder. If you wish to request a refund, please visit https://getsupport.apple.com . If you subscribed using your Google Play Store account or through Tinder Online: Contact customer support
                </Text>

                <Text
                    fontSize={'14px'}
                    w={{ base: 'auto', md: '85%', lg: '65%' }}
                    color={'#707070'}
                    opacity={'0.8'}
                >
                    1. Acceptance of Terms of Use Agreement. By creating a Tinder account or by using any APP service, whether through a mobile device, mobile application or computer (collectively, the “Service”) you agree to be bound by (i) these Terms of Use, (ii) our Privacy Policy and Safety Tips , each of which is incorporated by reference into this Agreement, and (iii) any terms disclosed to you if you purchase or have purchased additional features, products or services we offer on the Service (collectively, this “Agreement”). If you do not accept and agree to be bound by all of the terms of this Agreement (other than the limited one-time opt out right for certain users provided for in Section 15), you should not use the Service.
                </Text>

                <Text
                    fontSize={'14px'}
                    w={{ base: 'auto', md: '85%', lg: '65%' }}
                    color={'#707070'}
                    opacity={'0.8'}
                >
                    We may make changes to this Agreement and to the Service from time to time. We may do this for a variety of reasons including to reflect changes in or requirements of the law, new features, or changes in business practices. The most recent version of this Agreement will be posted on the Service under Settings and also on gotinder.com, and you should regularly check for the most recent version. The most recent version is the version that applies. If the changes include material changes that affect your rights or obligations, we will notify you in advance of the changes by reasonable means, which could include notification through the Service or via email.
                </Text>

                <Text
                    fontSize={'14px'}
                    w={{ base: 'auto', md: '85%', lg: '65%' }}
                    color={'#707070'}
                    opacity={'0.8'}
                >
                    2. Eligibility. You must be at least 18 years of age to create an account onSHer and use the Service. By creating an account and using the Service, you represent and warrant that:
                </Text>

                <Text
                    fontSize={'14px'}
                    w={{ base: 'auto', md: '85%', lg: '65%' }}
                    color={'#707070'}
                    opacity={'0.8'}
                >
                    you can form a binding contract with SHer, you are not a person who is barred from using the Service under the laws of the United States or any other applicable jurisdiction–meaning that you do not appear on the U.S. Treasury Department’s list of Specially Designated Nationals or face any other similar prohibition, you will comply with this Agreement and all applicable local, state, national and international laws, rules and regulations, and you have never been convicted of or pled no contest to a felony, a sex crime, or any crime involving violence, and that you are not required to register as a sex offender with any state, federal or local sex offender registry.
                </Text>

                <Text
                    fontSize={'14px'}
                    w={{ base: 'auto', md: '85%', lg: '65%' }}
                    color={'#707070'}
                    opacity={'0.8'}
                >
                    3. Your Account. In order to use Tinder, you may sign in using your Facebook login. If you do so, you authorize us to access and use certain Facebook account information, including but not limited to your public Facebook profile and information about Facebook friends you share in common with other Tinder users. For more information regarding the information we collect from you and how we use it, please consult our Privacy Policy.
                </Text>

                <Text
                    fontSize={'14px'}
                    w={{ base: 'auto', md: '85%', lg: '65%' }}
                    color={'#707070'}
                    opacity={'0.8'}
                >
                    You are responsible for maintaining the confidentiality of your login credentials you use to sign up for Tinder, and you are solely responsible for all activities that occur under those credentials. If you think someone has gained access to your account, please immediately contact us.
                </Text>
            </VStack>

            <SubscribeForm />
            <Bottom />
            <Footer />
        </>
    )
}
