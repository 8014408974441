import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    Flex,
    chakra,
    Text,
    Container,
    VStack,
    Textarea,
    Button,
} from '@chakra-ui/react';

import { ChevronDownIcon } from '@chakra-ui/icons';
import NavBar from '../home/navBar/NavBar';
import ButtomBar from '../home/footer/BottomBar';
import { useState } from 'react';
import { useFormik } from 'formik';
import { SubmitFaqApi } from '../../Axios/Api';
import { notifyError, notifySuccess } from '../utils/ToastNotifications';
import AuthenticatedLayout from '../auth/AuthenticatedLayout';
import * as Yup from 'yup';

export default function Faq() {
    const [loading, setLoading] = useState(false);

    const questions = [
        {
            question: "What is Lorem Ipsum?",
            answer: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,"
        },
        {
            question: "Was is this site lorem ipsum?",
            answer: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,"
        },
        {
            question: "What is simply dummy text?",
            answer: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,"
        },
        {
            question: "What is Lorem Ipsum?",
            answer: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,"
        },
        {
            question: "Was is this site lorem ipsum?",
            answer: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,"
        },
    ];
    const validationSchema = Yup.object({
        content: Yup.string().required('Content is required')
    });
    const formik = useFormik({
        initialValues: {
            content: ""
        },
        validationSchema,
        onSubmit: async (values, { resetForm }) => {
            setLoading(true);
            try {
                const response = await SubmitFaqApi(values);
                notifySuccess(response.message);
                resetForm();
            } catch (error) {
                notifyError(error.response.data.message);
            } finally {
                setLoading(false);
            }
        }
    });

    return (
        <AuthenticatedLayout>
            <NavBar />

            <Flex
                minH={'100vh'}
                align={'center'}
                justify={'center'}
                style={{
                    backgroundImage: "url('/slider_bg2.png')",
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center center'
                }}
                px={12}
            >
                <Container>
                    <Text color={'#E22937'} opacity={'0.8'} fontWeight="bold" textAlign="center" pt={6}>
                        FAQs
                    </Text>
                    <Text fontSize={{ base: '2xl', md: '3xl' }} fontWeight="bold" textAlign="center">
                        Lorem Ipsum Dolar Is The Dummy Text
                    </Text>

                    <chakra.p fontSize={'14px'} color={'#343434'} opacity={'0.8'} textAlign="center">
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                    </chakra.p>

                    <Accordion allowMultiple width="100%" rounded="lg" my='6'>
                        {questions.map((question, index) => (
                            <AccordionItem key={index}>
                                <AccordionButton
                                    display="flex"
                                    alignItems="start"
                                    justifyContent="space-between"
                                    _active={{ bg: 'whatsapp.50' }}
                                    _hover={{ bg: 'whatsapp.50' }}
                                    p={4}>
                                    <Text textAlign='left' fontSize="md" fontWeight='700'>{question.question}</Text>
                                    <ChevronDownIcon fontSize="24px" />
                                </AccordionButton>
                                <AccordionPanel bg='whatsapp.50' pb={4}>
                                    <Text color="gray.600">
                                        {question.answer}
                                    </Text>
                                </AccordionPanel>
                            </AccordionItem>
                        ))}
                    </Accordion>

                    <VStack as="form" onSubmit={formik.handleSubmit} w={'100%'} mt={12} mb={8}>
                        <Text fontSize={'xs'} w={'100%'}>
                            Got any suggestions? Let us know!
                        </Text>

                        <Textarea
                            placeholder={formik.errors.content && formik.touched.content ? (formik.errors.content): "Your message"}
                            _placeholder={formik.errors.content && formik.touched.content ? {color:'red.400'}:{color:'gray.400'}}
                            size='lg'
                            w={'100%'}
                            id='content'
                            name='content'
                            value={formik.values.content}
                            onChange={formik.handleChange}
                            maxLength={250}
                            backgroundColor={'#FFFFFF'}
                            borderRadius={'12px'}
                            h={44}
                            resize={'none'}
                            isInvalid={formik.errors.content && formik.touched.content }
                            
                        />

                        <Button
                            type="submit"
                            colorScheme="red"
                            py={5}
                            rounded={'34px'}
                            w={{ base: '50%', md: '20%', lg: '12%' }}
                            bottom={'16'}
                            fontSize={'xs'}
                            zIndex={1}
                            isLoading={loading}
                        >
                            Submit
                        </Button>

                    </VStack>
                </Container>
            </Flex>

            <ButtomBar />
        </AuthenticatedLayout>
    )
}
