import React from 'react'
import NavBar from '../../components/home/navBar/NavBar'
import ButtomBar from '../../components/home/footer/BottomBar'
import {
    Button,
    HStack,
    Text,
    VStack
} from '@chakra-ui/react';
import MyListings from '../../components/car/MyListings';
import TradeRequests from '../../components/car/TradeRequests';
import BuyRequests from '../../components/car/BuyRequests';
import LoanRequests from '../../components/car/LoanRequests';
import { useNavigate } from 'react-router-dom';

export default function AllRequest() {
    const navigate = useNavigate();

    return (
        <>
            <NavBar />

            <VStack maxW={'100%'} mx={{ base: 6, md: 16 }} mt={6}>

                {/* My Listings */}
                <HStack w={'100%'} justifyContent={'space-between'}>
                    <Text
                        fontSize={{ base: 'xs', md: 'md' }}
                        fontWeight={'600'}
                        color={'#1D1C1A'}
                        opacity={'0.9'}
                    >
                        My Listings
                    </Text>

                    <Button
                        colorScheme="red"
                        rounded={'22px'}
                        w={{ base: 28, md: 44 }}
                        h={10}
                        fontSize={'xs'}
                        onClick={()=>navigate('/sell-a-car')}
                    >
                        Add Listing
                    </Button>
                </HStack>
                <MyListings />

                {/* Trade Requests */}
                <Text
                    fontSize={{ base: 'xs', md: 'md' }}
                    fontWeight={'600'}
                    color={'#1D1C1A'}
                    opacity={'0.9'}
                    width={'100%'}
                    alignItems={{ base: 'center', md: 'start' }}
                    mt={12}
                    mb={2}
                >
                    Trade Requests
                </Text>
                <TradeRequests />

                {/* Buy Requests */}
                <Text
                    fontSize={{ base: 'xs', md: 'md' }}
                    fontWeight={'600'}
                    color={'#1D1C1A'}
                    opacity={'0.9'}
                    width={'100%'}
                    alignItems={{ base: 'center', md: 'start' }}
                    mt={12}
                    mb={2}
                >
                    Buy Requests
                </Text>

                <BuyRequests />

                {/* Loan Requests */}
                <Text
                    fontSize={{ base: 'xs', md: 'md' }}
                    fontWeight={'600'}
                    color={'#1D1C1A'}
                    opacity={'0.9'}
                    width={'100%'}
                    alignItems={{ base: 'center', md: 'start' }}
                    mt={12}
                    mb={2}
                >
                    Loan Requests
                </Text>

                <LoanRequests />

            </VStack>
            <ButtomBar />
        </>
    )
}
