import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalCloseButton,
    Button,
    Text,
    VStack,
    FormControl,
    Input,
    Textarea,
} from '@chakra-ui/react';
import { Divider } from '@chakra-ui/react';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';

export default function CounterOffer({ isOpen, onClose, onOpen }) {
   const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            counter_offer: "",
            notes: "",
        },
        onSubmit: (values) => {
            console.log(values);
        }
    })

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose} isCentered onOpen size={{base:'xs', md:'sm'}}>
                <ModalOverlay />
                <ModalContent>
                    <VStack maxW='100%' w='100%' justifyContent='center' alignItems='start' p={8} mb={6}>
                        <ModalCloseButton />
                        <Text textAlign='start' w={{ base: '100%', md: '80%' }} fontSize={{ base: 'sm', md: 'md' }} fontWeight={'500'} color={'#153373'} opacity={'0.8'}>
                          Counter Offer
                        </Text>

                        <Divider borderWidth={'1px'} borderColor={'#e3e1e1'} alignItems={'center'} w={'100%'} />

                        <Text color={'#707070'} fontSize={'xs'} opacity={'0.8'} fontWeight={'400'} mt={4}>How much do you wanna counter offer for?</Text>
                        <FormControl>
                            <Input
                                type='number'
                                id='counter_offer'
                                name='counter_offer'
                                onChange={formik.handleChange}
                                value={formik.values.counter_offer}
                                required={true}
                                rounded={26}
                                placeholder='$'
                                py={2}
                                fontSize={'xs'}
                            />
                        </FormControl>

                        <Text color={'#707070'} fontSize={'xs'} opacity={'0.8'} fontWeight={'400'} mt={4}>Additional Notes</Text>
                        <FormControl>
                            <Textarea
                                type='text'
                                id='notes'
                                name='notes'
                                onChange={formik.handleChange}
                                value={formik.values.notes}
                                required={true}
                                rounded={12}
                                py={2}
                                size={'3xl'}
                            />
                        </FormControl>                       
                    </VStack>

                    <Button
                        onClick={() => {
                            // navigate('/car-details');
                        }}
                        colorScheme="red"
                        py={6}
                        mt={6}
                        rounded={'34px'}
                        w={{ base: '80%', md: '40%', lg: '30%' }}
                        fontSize={'xs'}
                        position={'absolute'}
                        bottom={'0'}
                        left={'50%'}
                        transform={'translate(-50%, 50%)'}
                    >
                        Counter
                    </Button>
                </ModalContent>
            </Modal>
        </>
    )
}

