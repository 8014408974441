import {
    Button,
    Card,
    Container,
    Flex,
    FormControl,
    HStack,
    Image,
    Input,
    Box,
    Text,
    VStack,
    Stack,
    useDisclosure
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { useFormik } from 'formik';
import { FaArrowLeftLong } from "react-icons/fa6";
import { useNavigate } from 'react-router-dom';
import { Divider } from '@chakra-ui/react';
import OfferMadeSuccess from '../../../models/OfferMadeSuccess';

export default function MarketValue() {

    const { isOpen, onOpen, onClose } = useDisclosure();

    const [currentStep, setCurrentStep] = useState(0);

    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            offer_price: "",
        },
        onSubmit: (values) => {
            console.log(values);
        }
    })

    return (
        <Card
            bg='white' borderRadius={'48px'}
            style={{
                boxShadow: '0px 10px 99px #00000029'
            }}
            p={{ base: '4', md: '6' }}
            maxW={'100%'}
            mx={{ md: '15%' }}
        >
            <VStack maxW='100%' spacing='8' justifyContent='center' alignItems='center'>
                <HStack
                    w='100%'
                    justifyContent='start'
                >
                    <HStack w={{ base: '20%', md: '8%' }}>
                        {currentStep < 1 ?
                            <></>
                            :
                            <Box
                                bg='gray.200'
                                w='40px'
                                h='40px'
                                display='flex'
                                justifyContent='center'
                                alignItems='center'
                                rounded='50%'
                            >
                                <FaArrowLeftLong onClick={() => setCurrentStep(currentStep - 1)} />
                            </Box>
                        }
                    </HStack>
                </HStack>
            </VStack>

            <HStack display={{base:'none', md:'flex'}} maxW='100%' justifyContent='center'>
                <Flex
                    w={{ md: '100%', lg: '65%' }}
                    bg={'white'}
                    borderRadius={'48px'}
                    justify={'space-between'}
                    style={{
                        boxShadow: '0px 10px 99px #00000029'
                    }}
                    fontSize={{ base: 'xs' }}
                >
                    <HStack p={2}>
                        <Container
                            borderRadius={25}
                            py={2}
                            fontSize={'xs'}
                            bg={'#1533731A'}
                            color={'#153373'}
                        >
                            Market Value
                        </Container>
                    </HStack>

                    <HStack p={2}>
                        <Container
                            borderRadius={25}
                            py={2}
                            fontSize={'xs'}
                            bg={'white'}
                            color={'#707070'}
                        >
                            Ownership Cost
                        </Container>
                    </HStack>

                    <HStack p={2}>
                        <Container
                            borderRadius={25}
                            py={2}
                            fontSize={'xs'}
                            bg={'white'}
                            color={'#707070'}
                        >
                            External Resourses
                        </Container>
                    </HStack>

                </Flex>
            </HStack>

            <Text
                textAlign={{ base: 'center', md: 'center' }}
                w={'100%'}
                fontSize={{ base: '10px', md: 'md' }}
                fontWeight={'500'}
                color={'#1D1C1A'}
                opacity={'0.9'}
                pb={{ base: 2, md: 4 }}
                mt={4}
            >
                Market Value For 2005 Toyota Corolla CE
            </Text>

            <Flex w={'100%'} alignItems={'center'} justifyContent={'center'} mt={{base:'4', md:'0'}}>
                <Image src='./market_value.png' h={150} />
            </Flex>
            {/* <Divider borderWidth={'1px'} borderColor={'#e3e1e1'} alignItems={'center'} mt={2} /> */}

            <Stack
                direction={{ base: 'column', md: 'column', lg:'row' }}
                my={4}
                mx={{base:4,md:8, lg:32}}
            >

                <VStack w={{base:'100%', md:'100%', lg:'50%'}} alignItems={'start'}>
                    <Text
                        fontSize={{ base: 'xs', md: 'sm' }}
                        color={'#153373'}
                        fontWeight={{ base: 500, md: 700 }}
                        opacity={'0.9'}
                        pt={{base:'4', md:'0'}}
                    >
                        ASSUMPTIONS
                    </Text>
                    <Text
                        fontSize={'xs'}
                        color={'#707070'}
                        opacity={'0.8'}
                        fontWeight={'400'}
                        align={'start'}
                    >
                        Current Milage: 129.914 miles Time Period: Past 6 months
                    </Text>

                </VStack>

               <VStack w={{base:'100%', md:'100%', lg:'50%'}} alignItems={'start'}>
               <Text
                    fontSize={{ base: 'xs', md: 'sm' }}
                    color={'#153373'}
                    fontWeight={{ base: 500, md: 700 }}
                    opacity={'0.9'}
                    pt={{base:'4', md:'0'}}
                >
                    ESTIMATES
                </Text>
                <Text
                        fontSize={'xs'}
                        color={'#707070'}
                        opacity={'0.8'}
                        fontWeight={'400'}                       
                        align={'start'}
                    >
                        Market Value: $5413 - $8559 Estimate Certainty: 99%
                    </Text>
               </VStack>

            </Stack>

            {currentStep === 0 ?
                <VStack align={'start'} mx={{base:4, lg:32}}>
                    <Text
                        fontSize={'xs'}
                        color={'#707070'}
                        opacity={'0.8'}
                        fontWeight={'400'}
                        w={'100%'}
                        align={'center'}
                        pt={{base:4, md:0}}
                    >
                        How much do you wanna offer?
                    </Text>

                    <FormControl>
                        <Input
                            type='number'
                            id='offer_price'
                            name='offer_price'
                            onChange={formik.handleChange}
                            value={formik.values.offer_price}
                            required={true}
                            rounded={26}
                            placeholder='Enter your offer price'
                            py={4}
                            mb={6}
                            fontSize={'xs'}
                        />
                    </FormControl>
                </VStack>
                :
                <></>
            }

           <OfferMadeSuccess isOpen={isOpen} onClose={onClose} onOpen={onOpen} />

            {/* button  */}
            {currentStep < 1 ?
                <Button
                    colorScheme="red"
                    py={4}
                    rounded={'34px'}
                    w={{ base: '60%', md: '30%', lg: '20%' }}
                    position={'absolute'}
                    bottom={'0'}
                    left={'50%'}
                    transform={'translate(-50%, 50%)'}
                    fontSize={'xs'}
                    onClick={() => onOpen()}
                >
                    Make Offer
                </Button>
                :
                <Button
                    colorScheme="red"
                    py={4}
                    rounded={'34px'}
                    w={{ base: '60%', md: '30%', lg: '20%' }}
                    position={'absolute'}
                    bottom={'0'}
                    left={'50%'}
                    transform={'translate(-50%, 50%)'}
                    fontSize={'xs'}
                >
                    Make Offer
                </Button>
            }
        </Card >
    )
}

