import { Button, Container, HStack, Image, Text, VStack, useDisclosure } from '@chakra-ui/react';
import React from 'react';
import { Divider } from '@chakra-ui/react';
import AddNewCard from '../../models/AddNewCard';

export default function SavedCards() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
     <VStack  p={4} w={'100%'} alignItems={'start'} bg={'#FBFBFB'} mb={8}>
        <HStack w={{base:'100%', md:'75%'}} alignItems={'flex-end'} justifyContent={'space-between'} mb={2}>
        <Text color={'#575757'} fontSize={{base:'10px', md:'sm'}} fontWeight={600} pb={4}>
          Saved Cards
        </Text>

        <Button
            variant='outline' 
            color={'#153373'}
            borderColor={'#153373'}
            py={{base:2, md:4}}
            rounded={'22px'}
            w={130} 
            h={10}
            fontSize={{base:'10px', md:'xs'}}
            style={{
                boxShadow: '0px 10px 20px #00000029'
            }}
            opacity={'0.8'}
            onClick={()=>  onOpen()}
        >
            Add New Card
        </Button>
        </HStack>
        <Divider borderWidth={'1px'} borderColor={'#EDECF1'} alignItems={'center'} w={'100%'} />

        <HStack 
         w={{base:'100%', md:'80%', lg:'50%'}}
         border={'2px'}
         borderColor={'#d9d9d9'}
         borderRadius={'22px'}

         >
            <Container 
             w={'2%'} 
             h={100} 
             borderRadius="22px 0 0 22px" 
             bg={'#153373'} 
             display={{base:'none', md:'flex'}}
            >

            </Container>

            <HStack w={'100%'} alignItems={'start'} justify={'space-between'} p={4}>
                <HStack w={'100%'} alignItems={'start'}>

                  <Image src='./saved_cards.png' h={30} />

                  <VStack w={'100%'} alignItems={'start'} spacing={0}>
                    <Text fontSize={'xs'}>Amex ending in 6756</Text>
                    <Text fontSize={'xs'}>Expires: 12/2023</Text>
                    <Text color={'#888888'} fontSize={'xs'}>(Default)</Text>
                  </VStack>
                </HStack>

                <HStack w={{base:'70%', md:'80%', lg:'50%'}} justifyContent={'end'}>
                    <Text color={'#888888'} fontSize={'xs'}>Edit | </Text>
                    <Text color={'#E22937'} fontSize={'xs'}>Delete</Text>
                </HStack>
            </HStack>
        </HStack>

        <AddNewCard isOpen={isOpen} onClose={onClose} onOpen={onOpen} />
         
     </VStack>
    </>
  )
}

