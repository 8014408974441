import axios from "axios";

export const Axios = axios.create({
    baseURL: `https://dev-clmpd-api.flynautstaging.com/api/v1`,
    // baseURL: `  http://localhost:5010/api/v1`,
    timeout :   1000*36,
  });
  
  Axios.interceptors.request.use(
    function (config) {
      // Do something before request is sent
      return config;
    },
    function (error) {
      // Do something with request error
      return Promise.reject(error);
    }
  );
  
  Axios.interceptors.response.use(
    function (response) {
      return response.data;
    },
    function (error) {
      return Promise.reject(error);
    }
  );