import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalFooter,
    Button,
    Text,
    VStack,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

export default function OfferPriceLess({ isOpen, onClose, onOpen }) {
    const navigate = useNavigate();

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose} isCentered onOpen size={{base:'xs', md:'md'}} >
                <ModalOverlay />
                <ModalContent>
                    <VStack mx={'6'} pt={6}>
                        <Text
                            textAlign='center'
                            w={{ base: '100%', md: '80%' }}
                            fontSize={{ base: 'md', md: 'xl' }}
                            fontWeight={'500'}
                            color={'#E22937'}
                            opacity={'0.8'}>
                            Offer Price Is Less!
                        </Text>

                        <Text
                            color={'#707070'}
                            opacity={'0.8'}
                            fontWeight={'400'}
                            fontSize={'xs'}
                            pt={2}
                            textAlign='center'
                        >
                            The offer price that you suggested is less. Do you still want to go ahead with this offer value?
                        </Text>
                    </VStack>
                    <ModalFooter mx={'8'} py={6}>
                        <Button
                            variant='outline'
                            rounded={'25px'}
                            w={'100%'}
                            h={12}
                            fontSize={'xs'}
                            color={'#E22937'}
                            borderColor={'#E22937'}
                            onClick={onClose}
                            mr={2}
                        >
                            Cancel
                        </Button>

                        <Button
                            rounded={'25px'}
                            w={'100%'}
                            h={12}
                            fontSize={{base:'10px', md:'xs'}}
                            color={'#ffffff'}
                            backgroundColor={'#E22937'}
                            borderColor={'#E22937'}
                            ml={{base:1, md:2}}
                            onClick={()=> navigate('/all-request') }
                        >
                            Yes, continue
                        </Button>

                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}
