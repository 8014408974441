import React, { useState, useEffect } from 'react';
import { Box, Button, Card, CardBody, CardFooter, HStack, Heading, Image, Skeleton, Stack, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { FaBookmark, FaRegBookmark } from "react-icons/fa";
import Img from '../../Assets/Images/defaultCarImage.png';
import { BookmarkVehicleApi, RemoveBookmarkVehicleApi } from '../../Axios/Api';
import { notifyError, notifySuccess } from '../utils/ToastNotifications';
import { useAuth } from '../../providers/AuthProvider';

export default function Car({ carImage, heading, vehicle }) {
  const { user, updateUserData,logged } = useAuth();
  const navigate = useNavigate();
  const [isBookmarked, setIsBookmarked] = useState(vehicle?.isBookmarked);


  useEffect(() => {
    if (!logged)return;
    const bookmarked = user?.bookmarkedVehicles?.some(
      (bookmark) => bookmark.vehicleId === vehicle._id && bookmark.isBookmarked
    );

    setIsBookmarked(bookmarked);

  }, [logged, user?.bookmarkedVehicles, vehicle._id]);

  const handleBookmark = async () => {
    if (!logged)return;
    
    try {
      if (isBookmarked) {
        const response = await RemoveBookmarkVehicleApi({
          vehicleId: vehicle?._id
        });
        if (response.success) {
          notifySuccess(response.message);
          updateUserData('bookmarkedVehicles', user.bookmarkedVehicles.filter(bookmark => bookmark.vehicleId !== vehicle._id));
        }
      } else {
        const response = await BookmarkVehicleApi({
          vehicleId: vehicle?._id,
          isBookmarked: true
        });
        if (response.success) {
          notifySuccess(response.message);
          updateUserData('bookmarkedVehicles', [...user.bookmarkedVehicles, { vehicleId: vehicle._id, isBookmarked: true }]);
        }
      }
      setIsBookmarked(!isBookmarked);
    } catch (error) {
      notifyError(error.response.data.message);
    }
  };

  return (
    <Card maxW='xs'>
      <CardBody>
        <Image
          src={carImage}
          alt='car image'
          width={500}
          maxHeight={190}
          fit={'cover'}
          align={'center'}
          borderRadius='lg'
          fallbackSrc={Img}
          fallback={<Skeleton height="190px" borderRadius={'lg'} />}
          // fallbackStrategy={Img}
        />

        <HStack
          w={'88%'}
          justifyContent={'space-between'}
          position={'absolute'}
          top='5'
        >
          <Box
            bg='#FFFFFF'
            display='flex'
            justifyContent='center'
            alignItems='center'
            rounded='25'
            p={1}
            ml={2}
          >
            <HStack>
              <Image src='./star.png' height={18} />
              <Text opacity={'0.9'} fontSize={'xs'} pr={1} fontWeight={600}>{vehicle?.vehicleCondition?.totalScore || '0'} Points</Text>
            </HStack>
          </Box>

          <Button onClick={handleBookmark} variant="ghost" size="sm" p={2} m={3} backgroundColor={'#fff'} borderRadius={100}>
            {isBookmarked ? (
              <FaBookmark color='#000' size={16} />
            ) : (
              <FaRegBookmark color='#000' size={16} />
            )}
          </Button>
        </HStack>

        <Stack mt='6' spacing='3'>
          <Heading fontWeight={800} opacity={'0.8'} letterSpacing={'1px'} size={'sm'}>{heading}</Heading>
          <HStack justify={'space-between'}>
            <Text color={'#707070'} opacity={'0.8'} fontSize={'sm'} fontWeight={'500'}>{vehicle?.year || '-'}</Text>
            <Text color={'#E22937'} opacity={'0.8'} fontSize={'md'} fontWeight={'700'}>${vehicle?.price || '0'}</Text>
          </HStack>
          <HStack justify={'space-between'}>
            <Text color={'#707070'} opacity={'0.8'} fontSize={'xs'} fontWeight={'400'}>Miles</Text>
            <Text color={'#707070'} opacity={'0.8'} fontSize={'xs'} fontWeight={'400'}>{vehicle?.miles || '0'}</Text>
          </HStack>
          <HStack justify={'space-between'}>
            <Text color={'#707070'} opacity={'0.8'} fontSize={'xs'} fontWeight={'400'}>Location</Text>
            <Text color={'#707070'} opacity={'0.8'} fontSize={'xs'} fontWeight={'400'}>New York, New York</Text>
          </HStack>
        </Stack>
      </CardBody>
      <CardFooter>
        <Button
          onClick={() => navigate('/car-details', { state: { id: vehicle?._id } })}
          bg="#153373" color={'white'}
          py={4}
          rounded={'34px'}
          width="100%"
          fontSize={'xs'}
        >
          View Details
        </Button>
      </CardFooter>
    </Card>
  );
}
