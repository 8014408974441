import React from 'react';
import {
    VStack,
    Heading,
    Text,
    Button,
    FormControl,
    Input,
    FormErrorMessage,
    HStack,
    Checkbox
} from '@chakra-ui/react';

export default function FinalRegister({ formik }) {
    return (
        <VStack w='100%' align={'start'} my={2}>
            <VStack align={'start'} color={'#ffffff'}>
                <Heading fontSize='2xl' fontWeight={'bold'}>Welcome,</Heading>
                <Text fontSize='sm' mb={2} opacity={'0.7'}>Please enter details to create your account.</Text>
            </VStack>

            <FormControl isInvalid={formik.touched.fname && formik.errors.fname}>
                <Input
                    type='text'
                    id='fname'
                    name='fname'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.fname}
                    rounded={34}
                    bg={'#FFFFFF'}
                    placeholder='First Name'
                    py={4}
                    fontSize={'sm'}
                />
                <FormErrorMessage>{formik.errors.fname}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={formik.touched.lname && formik.errors.lname}>
                <Input
                    type='text'
                    id='lname'
                    name='lname'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.lname}
                    rounded={34}
                    bg={'#FFFFFF'}
                    placeholder='Last Name'
                    py={4}
                    fontSize={'sm'}
                />
                <FormErrorMessage>{formik.errors.lname}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={formik.touched.phone && formik.errors.phone}>
                <Input
                    type='number'
                    id='phone'
                    name='phone'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.phone}
                    rounded={34}
                    bg={'#FFFFFF'}
                    placeholder='Phone Number'
                    py={4}
                    fontSize={'sm'}
                />
                <FormErrorMessage>{formik.errors.phone}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={formik.touched.city && formik.errors.city}>
                <Input
                    type='text'
                    id='city'
                    name='city'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.city}
                    rounded={34}
                    bg={'#FFFFFF'}
                    placeholder='City'
                    py={4}
                    fontSize={'sm'}
                />
                <FormErrorMessage>{formik.errors.city}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={formik.touched.state && formik.errors.state}>
                <Input
                    type='text'
                    id='state'
                    name='state'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.state}
                    rounded={34}
                    bg={'#FFFFFF'}
                    placeholder='State'
                    py={4}
                    fontSize={'sm'}
                />
                <FormErrorMessage>{formik.errors.state}</FormErrorMessage>
            </FormControl>

            <HStack m={2}>
                <HStack>
                    <Checkbox bg={'#FFFFFF'} size={'sm'} rounded={'md'} />
                    <Text color={'#FFFFFF'} fontSize={'sm'} opacity={'0.7'}>I agree to all</Text>
                </HStack>
                <Text fontSize={'sm'} color={'#25BC9D'}>Terms & Conditions</Text>
            </HStack>

            <Button
                type='submit'
                onClick={formik.handleSubmit}
                colorScheme="red"
                py={6}
                rounded={'34px'}
                width='200px'
            >
                Finish
            </Button>
        </VStack>
    );
}
