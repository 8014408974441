import {
    Modal,
    ModalOverlay,
    ModalContent,
    Text,
    VStack,
    Flex,
    Image,
    ModalCloseButton,
    HStack,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { IoCheckmarkCircleSharp } from "react-icons/io5";
import { RxCrossCircled } from "react-icons/rx";

export default function ReportDetails({ isOpen, onClose, onOpen }) {
    const navigate = useNavigate();

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose} isCentered onOpen size={{base:'sm', md:'xl'}}>
                <ModalOverlay />
                <ModalContent>               
                 <ModalCloseButton />
                    <VStack mx={'6'} pt={4}>

                        <Text 
                        textAlign='start' 
                        w={{ base: '100%', md: '100%' }} 
                        fontSize={{ base: 'md', md: 'xl' }} 
                        fontWeight={'500'} 
                        color={'#153373'} 
                        opacity={'0.8'}
                        >
                          Report Details
                        </Text>

                        <Text
                            color={'#707070'}
                            opacity={'0.8'}
                            fontWeight={'400'}
                            fontSize={'xs'}
                            textAlign='start'
                        >
                          Lorem ipsum dolor sit amet consectetur. Et urna neque dolor lorem. Eleifend ultrices non egestas
                        </Text>

                        <VStack w={'100%'} alignItems={'start'} mt={4}>
                          <HStack>
                            <Image src='./drivability_outline.svg' h={22} />
                            <Text fontSize={'xs'} fontWeight={600}>Drivability</Text>
                          </HStack>

                          <HStack w={'100%'} justify={'space-between'}>
                            <Text color={'#575757'} fontSize={'xs'}>
                            1. Is the vehicle drivable?
                            </Text>

                            <HStack spacing={0}>
                                <IoCheckmarkCircleSharp color='#25BC9D' />
                                <Text color={'#25BC9D'} fontSize={'xs'}>Yes</Text>
                            </HStack>
                          </HStack>

                          <HStack w={'100%'} justify={'space-between'}>
                            <Text color={'#575757'} fontSize={'xs'}>
                            2. Can and/or has the vehicle passed the state emissions test in the last 30 days?
                            </Text>

                            <HStack spacing={0}>
                                <IoCheckmarkCircleSharp color='#25BC9D' />
                                <Text color={'#25BC9D'} fontSize={'xs'}>Yes</Text>
                            </HStack>
                          </HStack>

                          <HStack w={'100%'} justify={'space-between'}>
                            <Text color={'#575757'} fontSize={'xs'}>
                            3. Has the vehicle ever been in an accident?
                            </Text>

                            <HStack spacing={1}>
                                <RxCrossCircled color='#E22937' />
                                <Text color={'#E22937'} fontSize={'xs'}>No</Text>
                            </HStack>
                          </HStack>

                          <HStack w={'100%'} justify={'space-between'}>
                            <Text color={'#575757'} fontSize={'xs'}>
                            4. Is the vehicle salvaged/rebuilt/flood/fire/frame damaged? Are there any title issues?
                            </Text>

                            <HStack spacing={0}>
                                <IoCheckmarkCircleSharp color='#25BC9D' />
                                <Text color={'#25BC9D'} fontSize={'xs'}>Yes</Text>
                            </HStack>
                          </HStack>
                        </VStack>

                        <VStack w={'100%'} alignItems={'start'} mt={2}>
                          <HStack>
                            <Image src='./safety_outline.svg' h={22} />
                            <Text fontSize={'xs'} fontWeight={600}>Safety</Text>
                          </HStack>

                          <HStack w={'100%'} justify={'space-between'}>
                            <Text color={'#575757'} fontSize={'xs'}>
                            1. Does the vehicle meet safety standards?
                            </Text>

                            <HStack spacing={1}>
                                <RxCrossCircled color='#E22937' />
                                <Text color={'#E22937'} fontSize={'xs'}>No</Text>
                            </HStack>
                          </HStack>

                          <HStack w={'100%'} justify={'space-between'}>
                            <Text color={'#575757'} fontSize={'xs'}>
                            2. Are any windows cracked or broken?
                            </Text>

                            <HStack spacing={0}>
                                <IoCheckmarkCircleSharp color='#25BC9D' />
                                <Text color={'#25BC9D'} fontSize={'xs'}>Yes</Text>
                            </HStack>
                          </HStack>

                          <HStack w={'100%'} justify={'space-between'}>
                            <Text color={'#575757'} fontSize={'xs'}>
                            3. Are any of the tires worn or old, with less than 2" tread?
                            </Text>

                            <HStack spacing={1}>
                                <RxCrossCircled color='#E22937' />
                                <Text color={'#E22937'} fontSize={'xs'}>No</Text>
                            </HStack>
                          </HStack>

                          <HStack w={'100%'} justify={'space-between'}>
                            <Text color={'#575757'} fontSize={'xs'}>
                            4. Have the brakes been changed in the last two years?
                            </Text>

                            <HStack spacing={0}>
                                <IoCheckmarkCircleSharp color='#25BC9D' />
                                <Text color={'#25BC9D'} fontSize={'xs'}>Yes</Text>
                            </HStack>
                          </HStack>

                          <HStack w={'100%'} justify={'space-between'}>
                            <Text color={'#575757'} fontSize={'xs'}>
                            5. Have the oil and fluids been changed in the last year?
                            </Text>

                            <HStack spacing={0}>
                                <IoCheckmarkCircleSharp color='#25BC9D' />
                                <Text color={'#25BC9D'} fontSize={'xs'}>Yes</Text>
                            </HStack>
                          </HStack>

                          <HStack w={'100%'} justify={'space-between'}>
                            <Text color={'#575757'} fontSize={'xs'}>
                            6. Do all the seat belts, windows and doors work?
                            </Text>

                            <HStack spacing={0}>
                                <IoCheckmarkCircleSharp color='#25BC9D' />
                                <Text color={'#25BC9D'} fontSize={'xs'}>Yes</Text>
                            </HStack>
                          </HStack>

                          <HStack w={'100%'} justify={'space-between'}>
                            <Text color={'#575757'} fontSize={'xs'}>
                            7. Do all the lights work?
                            </Text>

                            <HStack spacing={0}>
                                <IoCheckmarkCircleSharp color='#25BC9D' />
                                <Text color={'#25BC9D'} fontSize={'xs'}>Yes</Text>
                            </HStack>
                          </HStack>
                        </VStack>

                        <VStack w={'100%'} alignItems={'start'} mt={4}>
                          <HStack>
                            <Image src='./appearance_outline.svg' h={22} />
                            <Text fontSize={'xs'} fontWeight={600}>Appearance</Text>
                          </HStack>

                          <HStack w={'100%'} justify={'space-between'}>
                            <Text color={'#575757'} fontSize={'xs'}>
                            1. Has the vehicle ever been repainted?
                            </Text>

                            <HStack spacing={1}>
                                <RxCrossCircled color='#E22937' />
                                <Text color={'#E22937'} fontSize={'xs'}>No</Text>
                            </HStack>
                          </HStack>

                          <HStack w={'100%'} justify={'space-between'}>
                            <Text color={'#575757'} fontSize={'xs'}>
                            2. Are there any tears, stains, or smells in the vehicle?
                            </Text>

                            <HStack spacing={0}>
                                <IoCheckmarkCircleSharp color='#25BC9D' />
                                <Text color={'#25BC9D'} fontSize={'xs'}>Yes</Text>
                            </HStack>
                          </HStack>

                          <HStack w={'100%'} justify={'space-between'}>
                            <Text color={'#575757'} fontSize={'xs'}>
                            3. Are there any dents, dings, road rash or scuffs?
                            </Text>

                            <HStack spacing={1}>
                                <RxCrossCircled color='#E22937' />
                                <Text color={'#E22937'} fontSize={'xs'}>No</Text>
                            </HStack>
                          </HStack>

                          <HStack w={'100%'} justify={'space-between'}>
                            <Text color={'#575757'} fontSize={'xs'}>
                            4. Are there any missing or broken parts, buttons or pieces?
                            </Text>

                            <HStack spacing={0}>
                                <IoCheckmarkCircleSharp color='#25BC9D' />
                                <Text color={'#25BC9D'} fontSize={'xs'}>Yes</Text>
                            </HStack>
                          </HStack>
                        </VStack>

                        <VStack w={'100%'} alignItems={'start'} mt={4}>
                          <HStack>
                            <Image src='./conform_outline.svg' h={22} />
                            <Text fontSize={'xs'} fontWeight={600}>Conform</Text>
                          </HStack>

                          <HStack w={'100%'} justify={'space-between'}>
                            <Text color={'#575757'} fontSize={'xs'}>
                            1. Does the air-condition/heating work?
                            </Text>

                            <HStack spacing={1}>
                                <RxCrossCircled color='#E22937' />
                                <Text color={'#E22937'} fontSize={'xs'}>No</Text>
                            </HStack>
                          </HStack>

                          <HStack w={'100%'} justify={'space-between'}>
                            <Text color={'#575757'} fontSize={'xs'}>
                            2. Does the radio work?
                            </Text>

                            <HStack spacing={0}>
                                <IoCheckmarkCircleSharp color='#25BC9D' />
                                <Text color={'#25BC9D'} fontSize={'xs'}>Yes</Text>
                            </HStack>
                          </HStack>

                          <HStack w={'100%'} justify={'space-between'}>
                            <Text color={'#575757'} fontSize={'xs'}>
                            3. Does the windows go up and down?
                            </Text>

                            <HStack spacing={1}>
                                <RxCrossCircled color='#E22937' />
                                <Text color={'#E22937'} fontSize={'xs'}>No</Text>
                            </HStack>
                          </HStack>

                        </VStack>

                        <VStack w={'100%'} alignItems={'start'} mt={4}>
                          <HStack>
                            <Image src='./paperwork_outline.svg' h={22} />
                            <Text fontSize={'xs'} fontWeight={600}>Paperwork</Text>
                          </HStack>

                          <HStack w={'100%'} justify={'space-between'}>
                            <Text color={'#575757'} fontSize={'xs'}>
                            Are you the registered owner of the vehicle?
                            </Text>

                            <HStack spacing={1}>
                                <RxCrossCircled color='#E22937' />
                                <Text color={'#E22937'} fontSize={'xs'}>No</Text>
                            </HStack>
                          </HStack>

                            <Text color={'#575757'} fontSize={'xs'} pt={2}>
                            Seller Description
                            </Text>

                            <Text color={'#575757'} fontSize={'9px'} pb={4}>
                            Seller Description Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s
                            </Text>
                          
                        </VStack>

                    </VStack>
                </ModalContent>
            </Modal>
        </>
    )
}



