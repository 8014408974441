import React, { useState } from 'react';
import {
    Button,
    Checkbox,
    HStack,
    Image,
    Table,
    TableContainer,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useDisclosure
} from '@chakra-ui/react';
import { Link, useNavigate } from 'react-router-dom';
import CounterOffer from '../../models/CounterOffer';

export default function TradeRequests() {
    const { isOpen, onOpen, onClose } = useDisclosure();

    const navigate = useNavigate();

    const [isAccept1, setIsAccept1] = useState(false);
    const [isAccept2, setIsAccept2] = useState(false);
    const [isAccept3, setIsAccept3] = useState(false);

    return (
        <>
            <TableContainer w={'100%'} maxH={'100vh'} overflowY={'auto'}>
                <Table variant='striped' size='sm'>
                    <Thead>
                        <Tr>
                            <Th w={9}> <Checkbox /> </Th>
                            <Th w={20}></Th>
                            <Th pb='4'>Request Id</Th>
                            <Th pb='4'>Request Date</Th>
                            <Th pb='4'>VIN</Th>
                            <Th pb='4'>Current Miles</Th>
                            <Th pb='4'>Year</Th>
                            <Th pb='4'>Make</Th>
                            <Th pb='4'>Model</Th>
                            <Th pb='4'>Original Price</Th>
                            <Th pb='4'>Trade Request</Th>
                            <Th textAlign='center'>Actions</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        <Tr>
                            <Td> <Checkbox /> </Td>
                            <Td p={0}> <Image src='./car1.png' w={70} /></Td>
                            <Td fontSize={'xs'} color={'#06A7E2'} onClick={() => navigate('/car-details')} cursor={'pointer'}>
                                #3456719
                            </Td>
                            <Td fontSize={'xs'}>15-11-2022</Td>
                            <Td fontSize={'xs'}>12345678</Td>
                            <Td fontSize={'xs'}>131,576</Td>
                            <Td fontSize={'xs'}>2006</Td>
                            <Td fontSize={'xs'}>-</Td>
                            <Td fontSize={'xs'}>-</Td>
                            <Td fontSize={'xs'}>$2500</Td>
                            <Td fontSize={'xs'}>$2500</Td>
                            <Td fontSize={'xs'}>
                                {
                                    isAccept1 ?
                                        <Link to={'/user-details'}>
                                            <Text textAlign={'center'} fontSize={'xs'} color={'#06A7E2'}>
                                                View User Details
                                            </Text>
                                        </Link>
                                        :
                                        <HStack>
                                            <Button
                                                rounded={'3xl'}
                                                w={'100%'}
                                                h={9}
                                                fontSize={'xs'}
                                                color={'#ffffff'}
                                                backgroundColor={'#25BC9D'}
                                                borderColor={'#25BC9D'}
                                                onClick={() => setIsAccept1(true)}
                                            >
                                                Accept
                                            </Button>

                                            <Button
                                                variant='outline'
                                                rounded={'3xl'}
                                                w={'100%'}
                                                h={9}
                                                fontSize={'xs'}
                                                color={'#153373'}
                                                borderColor={'#153373'}
                                                onClick={() => onOpen()}
                                            >
                                                Counter
                                            </Button>

                                            <Button
                                                rounded={'3xl'}
                                                w={'100%'}
                                                h={9}
                                                fontSize={'xs'}
                                                color={'#ffffff'}
                                                backgroundColor={'#E22937'}
                                                borderColor={'#E22937'}
                                            >
                                                Reject
                                            </Button>
                                        </HStack>
                                }
                            </Td>
                        </Tr>

                        <Tr>
                            <Td> <Checkbox /> </Td>
                            <Td p={0}> <Image src='./car1.png' w={70} /></Td>
                            <Td fontSize={'xs'} color={'#06A7E2'} onClick={() => navigate('/car-details')} cursor={'pointer'}>
                                #3456719
                            </Td>
                            <Td fontSize={'xs'}>15-11-2022</Td>
                            <Td fontSize={'xs'}>12345678</Td>
                            <Td fontSize={'xs'}>131,576</Td>
                            <Td fontSize={'xs'}>2006</Td>
                            <Td fontSize={'xs'}>-</Td>
                            <Td fontSize={'xs'}>-</Td>
                            <Td fontSize={'xs'}>$2500</Td>
                            <Td fontSize={'xs'}>$2500</Td>
                            <Td fontSize={'xs'}>
                                {
                                    isAccept2 ?
                                        <Link to={'/user-details'}>
                                            <Text textAlign={'center'} fontSize={'xs'} color={'#06A7E2'}>
                                                View User Details
                                            </Text>
                                        </Link>
                                        :
                                        <HStack>
                                            <Button
                                                rounded={'3xl'}
                                                w={'100%'}
                                                h={9}
                                                fontSize={'xs'}
                                                color={'#ffffff'}
                                                backgroundColor={'#25BC9D'}
                                                borderColor={'#25BC9D'}
                                                onClick={() => setIsAccept2(true)}
                                            >
                                                Accept
                                            </Button>

                                            <Button
                                                variant='outline'
                                                rounded={'3xl'}
                                                w={'100%'}
                                                h={9}
                                                fontSize={'xs'}
                                                color={'#153373'}
                                                borderColor={'#153373'}
                                                onClick={() => onOpen()}
                                            >
                                                Counter
                                            </Button>

                                            <Button
                                                rounded={'3xl'}
                                                w={'100%'}
                                                h={9}
                                                fontSize={'xs'}
                                                color={'#ffffff'}
                                                backgroundColor={'#E22937'}
                                                borderColor={'#E22937'}
                                            >
                                                Reject
                                            </Button>
                                        </HStack>
                                }
                            </Td>
                        </Tr>

                        <Tr>
                            <Td> <Checkbox /> </Td>
                            <Td p={0}> <Image src='./car1.png' w={70} /></Td>
                            <Td fontSize={'xs'} color={'#06A7E2'} onClick={() => navigate('/car-details')} cursor={'pointer'}>
                                #3456719
                            </Td>
                            <Td fontSize={'xs'}>15-11-2022</Td>
                            <Td fontSize={'xs'}>12345678</Td>
                            <Td fontSize={'xs'}>131,576</Td>
                            <Td fontSize={'xs'}>2006</Td>
                            <Td fontSize={'xs'}>-</Td>
                            <Td fontSize={'xs'}>-</Td>
                            <Td fontSize={'xs'}>$2500</Td>
                            <Td fontSize={'xs'}>$2500</Td>
                            <Td fontSize={'xs'}>
                                {
                                    isAccept3 ?
                                        <Link to={'/user-details'}>
                                            <Text textAlign={'center'} fontSize={'xs'} color={'#06A7E2'}>
                                                View User Details
                                            </Text>
                                        </Link>
                                        :
                                        <HStack>
                                            <Button
                                                rounded={'3xl'}
                                                w={'100%'}
                                                h={9}
                                                fontSize={'xs'}
                                                color={'#ffffff'}
                                                backgroundColor={'#25BC9D'}
                                                borderColor={'#25BC9D'}
                                                onClick={() => setIsAccept3(true)}
                                            >
                                                Accept
                                            </Button>

                                            <Button
                                                variant='outline'
                                                rounded={'3xl'}
                                                w={'100%'}
                                                h={9}
                                                fontSize={'xs'}
                                                color={'#153373'}
                                                borderColor={'#153373'}
                                                onClick={() => onOpen()}
                                            >
                                                Counter
                                            </Button>

                                            <Button
                                                rounded={'3xl'}
                                                w={'100%'}
                                                h={9}
                                                fontSize={'xs'}
                                                color={'#ffffff'}
                                                backgroundColor={'#E22937'}
                                                borderColor={'#E22937'}
                                            >
                                                Reject
                                            </Button>
                                        </HStack>
                                }
                            </Td>
                        </Tr>
                    </Tbody>
                </Table>
            </TableContainer>
            <CounterOffer isOpen={isOpen} onClose={onClose} onOpen={onOpen} />
        </>
    )
}

