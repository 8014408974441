import { 
    Button, 
    FormControl,  
    Input, 
    Modal, 
    ModalContent, 
    ModalOverlay, 
    SimpleGrid, 
    Text, 
    VStack,
    ModalCloseButton
} from '@chakra-ui/react';
import React from 'react';
import { Divider } from '@chakra-ui/react';
import { useFormik } from 'formik';

export default function AddNewCard({ isOpen, onClose, onOpen }) {

    const formik = useFormik({
        initialValues: {
            card_no: "",
            expiry: "",
            cvc: "",
        },
        onSubmit: (values) => {
            console.log(values);
        }
    })

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered onOpen size={{base:'xs', md:'lg'}}>
    <ModalOverlay />
    <ModalContent>
        <VStack maxW='100%' w='100%' justifyContent='center' alignItems='start' p={6} mb={6}>
            <ModalCloseButton />
            <Text 
            textAlign='start' 
            w={{ base: '100%', md: '80%' }} 
            fontSize={{ base: 'md', md: 'xl' }} 
            fontWeight={'500'} 
            color={'#153373'} 
            opacity={'0.8'}
            >
            Add New Card
            </Text>

            <Divider borderWidth={'1px'} borderColor={'#e3e1e1'} alignItems={'center'} w={'100%'} />

            <Text color={'#707070'} fontSize={'xs'} opacity={'0.8'} fontWeight={'400'} mt={6}>Card Number</Text>
            <FormControl>
                <Input
                    type='number'
                    id='cvc'
                    name='cvc'
                    onChange={formik.handleChange}
                    value={formik.values.cvc}
                    required={true}
                    rounded={26}
                    placeholder='1234 1234 1234 1234'
                    py={2}
                    fontSize={'xs'}
                />
            </FormControl>

            <SimpleGrid
                columns={{ base: 1, md: 2 }}
                spacing='4'
                width={'100%'}
                justifyContent={'space-evenly'}
                pb={18}
                pt={4}
            >

                <VStack align={'start'}>
                    <Text color={'#707070'} fontSize={'xs'} opacity={'0.8'} fontWeight={'400'}>Expiry</Text>
                    <FormControl>
                        <Input
                            type='number'
                            id='expiry'
                            name='expiry'
                            onChange={formik.handleChange}
                            value={formik.values.expiry}
                            required={true}
                            rounded={26}
                            placeholder='MM / YY'
                            py={2}
                            fontSize={'xs'}
                        />
                    </FormControl>
                </VStack>

                <VStack align={'start'}>
                    <Text color={'#707070'} fontSize={'xs'} opacity={'0.8'} fontWeight={'400'}>CVC</Text>
                    <FormControl>
                        <Input
                            type='number'
                            id='cvc'
                            name='cvc'
                            onChange={formik.handleChange}
                            value={formik.values.cvc}
                            required={true}
                            rounded={26}
                            placeholder='CVC'
                            py={2}
                            fontSize={'xs'}
                        />
                    </FormControl>
                </VStack>

            </SimpleGrid>

        </VStack>

        <Button
            onClick={() => {
                onClose();
            }}
            colorScheme="red"
            py={6}
            mt={6}
            rounded={'34px'}
            w={{ base: '80%', md: '40%', lg: '30%' }}
            fontSize={'xs'}
            position={'absolute'}
            bottom={'0'}
            left={'50%'}
            transform={'translate(-50%, 50%)'}
        >
            Add Card
        </Button>
    </ModalContent>
</Modal>
  )
}


