import React from 'react';
import NavBar from '../../components/home/navBar/NavBar';
import { Box, Container, HStack, VStack } from '@chakra-ui/react';
import ButtomBar from '../../components/home/footer/BottomBar';
import { useNavigate } from 'react-router-dom';
import { FaArrowLeftLong } from "react-icons/fa6";
import UserDetailsC from '../../components/user/UserDetails';

export default function UserDetails() {
    const navigate = useNavigate();

    return (
        <Box
            maxW={'100%'}
            h={'100vh'}
            style={{
                backgroundImage: "url('slider_bg.png')",
                backgroundSize: 'cover',
                backgroundRepeat: "no-repeat",
                backgroundAttachment: "fixed",
            }}
        >
            <NavBar />

            <VStack
                display={{ base: 'none', md: 'none', lg: 'flex' }}
                w={'100%'}
                alignItems={'start'}
                justifyContent={'start'}
                pt={6}
                px={36}
            >
                <Box
                    bg='gray.200'
                    w='50px'
                    h='50px'
                    display='flex'
                    justifyContent='center'
                    alignItems='center'
                    rounded='50%'>
                    <FaArrowLeftLong onClick={() => navigate(-1)} />
                </Box>
            </VStack>

            <HStack>
                <Container maxW={{ base: '90%', md: '80%' }} pt={{ base: 6, md: 0 }} mb={{base:2, lg:14}}>
                    <UserDetailsC />
                </Container>
            </HStack>

            <ButtomBar />
        </Box>
    )
}

