import React from 'react';
import TopBar from '../../components/home/navBar/TopBar';
import NavBar from '../../components/home/navBar/NavBar';
import BreadCrumb from '../../components/home/navBar/BreadCrumb';
import SubscribeForm from '../../components/SubscribeForm';
import Buttom from '../../components/home/footer/Bottom';
import Footer from '../../components/home/footer/Footer';
import BottomBar from '../../components/home/footer/BottomBar';
import { Box, HStack, Text, VStack } from '@chakra-ui/react';
import { Divider } from '@chakra-ui/react';
import SellVehicle from '../../components/car/SellVehicle';
import { useAuth } from '../../providers/AuthProvider';
import SellaCar from '../../components/car/sellACar/SellaCar';

export default function SellACar() {
    const { logged } = useAuth();
console.log(logged);
    return(
        <>
        { logged ? 
        <>
         <NavBar />
          <SellaCar />
          <BottomBar />
        </>
        :
        <> 
            <NavBar />
            <BreadCrumb imageUrl={"url('./sell_a_vehicle.png')"} title={'Sell a Vehicle'} />

            <Box maxW={'100%'} px={{ base: '8', md: '44' }} py={12}>
                <SellVehicle />
            </Box>

            <VStack pb={12} maxW={'100%'} mx={'4'}>
                <Text
                    fontSize='2xl'
                    fontWeight={'500'}
                    color={'#153373'}
                    pt={4}
                    letterSpacing={1}
                    opacity={'0.9'}
                    align={'center'}
                >
                    BEST PRICE FOR YOU VEHICLE
                </Text>

                <Divider borderWidth={'2px'} borderColor={'red'} alignItems={'center'} w={{ base: '25%', md: '20%', lg: '10%' }} mb={4} />

                <Text
                    fontSize={'14px'}
                    w={{ base: 'auto', md: '85%', lg: '65%' }}
                    color={'#707070'}
                    opacity={'0.8'}
                >
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                </Text>

                <Text
                    fontSize={'14px'}
                    w={{ base: 'auto', md: '85%', lg: '65%' }}
                    color={'#707070'}
                    opacity={'0.8'}
                >
                    It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                </Text>
            </VStack>

            <SubscribeForm />
            <Buttom />
            <Footer />
        </>
        }
        </>
    )  
}


