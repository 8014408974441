import React from 'react';
import {
    Button,
    Checkbox,
    HStack,
    Image,
    Table,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    useDisclosure,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import CounterOffer from '../../models/CounterOffer';
import AcceptOffer from '../../models/AcceptOffer';

export default function BuyRequests() {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isAcceptOfferOpen , onOpen: onAcceptOfferOpen, onClose: onAcceptOfferClose } = useDisclosure()

    const navigate = useNavigate();
    return (
        <>
        <TableContainer w={'100%'} maxH={'100vh'} overflowY={'auto'}>
            <Table variant='striped'>
                <Thead>
                    <Tr>
                        <Th w={9}> <Checkbox /> </Th>
                        <Th w={20}></Th>
                        <Th>Request Id</Th>
                        <Th>Buy Request Date</Th>
                        <Th>Buy Request</Th>
                        <Th></Th>
                        <Th></Th>
                        <Th w={'8%'} textAlign='center'>Actions</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    <Tr>
                        <Td> <Checkbox /> </Td>
                        <Td p={0}> <Image src='./car1.png' w={70} /></Td>
                        <Td fontSize={'xs'} color={'#06A7E2'} onClick={()=>navigate('/car-details')} cursor={'pointer'}>
                            #3456719
                        </Td>
                        <Td fontSize={'xs'}>15-11-2022</Td>
                        <Td fontSize={'xs'}>$33,999</Td>
                        <Td fontSize={'xs'}></Td>
                        <Td fontSize={'xs'}></Td>                                                                    
                        <Td fontSize={'xs'}>
                            <HStack>
                                <Button
                                    rounded={'3xl'}
                                    w={'100%'}
                                    h={9}
                                    fontSize={'xs'}
                                    color={'#ffffff'}
                                    backgroundColor={'#25BC9D'}
                                    borderColor={'#25BC9D'}
                                    onClick={()=> onAcceptOfferOpen()}
                                >
                                    Accept
                                </Button>

                                <Button
                                    variant='outline'
                                    rounded={'3xl'}
                                    w={'100%'}
                                    h={9}
                                    fontSize={'xs'}
                                    color={'#153373'}
                                    borderColor={'#153373'}
                                    onClick={() => onOpen()}
                                >
                                    Counter
                                </Button>

                                <Button
                                    rounded={'3xl'}
                                    w={'100%'}
                                    h={9}
                                    fontSize={'xs'}
                                    color={'#ffffff'}
                                    backgroundColor={'#E22937'}
                                    borderColor={'#E22937'}
                                >
                                    Reject
                                </Button>
                            </HStack>
                        </Td>
                    </Tr>

                </Tbody>
            </Table>
        </TableContainer>
        <CounterOffer isOpen={isOpen} onClose={onClose} onOpen={onOpen} />
        <AcceptOffer isOpen={isAcceptOfferOpen} onClose={onAcceptOfferClose} onOpen={onAcceptOfferClose} />
        </>
    )
}


