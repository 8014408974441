import React, { useState } from 'react';
import {
    VStack,
    Heading,
    Text,
    Button,
    FormControl,
    Input,
    FormErrorMessage
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Link, useNavigate } from 'react-router-dom';
import NotAuthLayout from '../../components/auth/NotAuthLayout';
import { notifyError, notifySuccess } from '../../components/utils/ToastNotifications';
import { SignUp } from '../../Axios/Api';
import FinalRegister from './FinalRegister';


const validationSchema = Yup.object({
    email: Yup.string().email('*Invalid email address').required('*Email is required'),
    password: Yup.string().required('*Password is required'),
    confirmPassword: Yup.string()
        .required('*Confirm Password is required')
        .oneOf([Yup.ref('password'), null], 'Password Does Not Match'),
    fname: Yup.string()
        .matches(/^[a-zA-Z\s]+$/, '*First Name should not contain numbers or symbols')
        .required('*First Name is required'),
    lname: Yup.string()
        .matches(/^[a-zA-Z\s]+$/, '*Last Name should not contain numbers or symbols')
        .required('*Last Name is required'),
    phone: Yup.string()
        .matches(/^\d{10}$/, '*Phone Number must be 10 digits long')
        .required('*Phone Number is required'),
    city: Yup.string().required('*City is required'),
    state: Yup.string().required('*State is required')
});

export default function Register() {
    const [currentStep, setCurrentStep] = useState(0);
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            fname: "",
            lname: "",
            phone: "",
            email: "",
            city: "",
            state: "",
            password: "",
            confirmPassword: ""
        },
        validationSchema: validationSchema,
        onSubmit: values => {
            if (currentStep === 0) {
                handleContinue();
            } else {
                handleSubmit(values);
            }
        },
        validateOnChange: true,
        validateOnBlur: true,
    });

    const handleSubmit = (values) => {
        SignUp(values)
            .then((response) => {
                notifySuccess(response.message);
                navigate('/verificationcode', { state: { refId: response.data.id, email: values.email, vrf: 1 } });
            }).catch((error) => {
                notifyError(error.response.data.message);
            });
    };

    const handleContinue = () => {
        formik.validateForm().then(errors => {
            if (Object.keys(errors).length === 5) {
                setCurrentStep(currentStep + 1);
            } else {
                notifyError('Fill all required fields correctly!');
            }
        });
    };

    return (
        <NotAuthLayout>
            {
                currentStep === 0 ? (
                    <VStack w='100%' align={'start'} my={6}>
                        <VStack align={'start'} color={'#ffffff'}>
                            <Heading fontSize='2xl' fontWeight={'bold'}>Welcome back,</Heading>
                            <Text fontSize='sm' mb={2} opacity={'0.7'}>Please enter details to create your account.</Text>
                        </VStack>

                        <FormControl isInvalid={formik.touched.email && formik.errors.email}>
                            <Input
                                type='text'
                                id='email'
                                name='email'
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.email}
                                rounded={34}
                                bg={'#FFFFFF'}
                                placeholder='Email address'
                                py={6}
                                fontSize={'sm'}
                            />
                            <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
                        </FormControl>

                        <FormControl isInvalid={formik.touched.password && formik.errors.password}>
                            <Input
                                type='password'
                                id='password'
                                name='password'
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.password}
                                rounded={34}
                                bg={'#FFFFFF'}
                                placeholder='Password'
                                py={6}
                                fontSize={'sm'}
                            />
                            <FormErrorMessage>{formik.errors.password}</FormErrorMessage>
                        </FormControl>

                        <FormControl isInvalid={formik.touched.confirmPassword && formik.errors.confirmPassword}>
                            <Input
                                type='password'
                                id='confirmPassword'
                                name='confirmPassword'
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.confirmPassword}
                                rounded={34}
                                bg={'#FFFFFF'}
                                placeholder='Confirm Password'
                                py={6}
                                fontSize={'sm'}
                            />
                            <FormErrorMessage>{formik.errors.confirmPassword}</FormErrorMessage>
                        </FormControl>

                        <Button mt={4} onClick={handleContinue} colorScheme="red" py={6} rounded={'34px'} width="200px">
                            Next
                        </Button>

                        <Link to='/login'>
                            <Text mt={2} color={'#FFFFFF'} align={'right'} fontSize={'sm'} opacity={'0.7'}>
                                Have an account?
                                <span style={{ color: '#25BC9D', fontWeight: '500', paddingLeft: '4px' }}>
                                    Sign in
                                </span>
                            </Text>
                        </Link>
                    </VStack>
                ) : (
                    <FinalRegister formik={formik} />
                )
            }
        </NotAuthLayout>
    );
}