import React, { useEffect, useState, useCallback } from 'react';
import NavBar from '../../components/home/navBar/NavBar';
import BreadCrumb from '../../components/home/navBar/BreadCrumb';
import {  HStack, Input, InputGroup, InputLeftElement, SimpleGrid,  Text, VStack } from '@chakra-ui/react';
import { IoIosSearch } from "react-icons/io";
import Car from '../../components/car/Car';
import SubscribeForm from '../../components/SubscribeForm';
import Buttom from '../../components/home/footer/Bottom';
import Footer from '../../components/home/footer/Footer';
import ApplyFilter from '../../components/car/ApplyFilter';
import { useAuth } from '../../providers/AuthProvider';
import { SearchFilterVehicleApi } from '../../Axios/Api';
import { CdnLink } from '../../components/utils/ProviderFunctions';
import fetchAndUpdateBookmarkedVehicles from '../../components/utils/HelperFunctions';
import { useFormik } from 'formik';
import { debounce } from 'lodash';
import NoDataComponent from '../../components/utils/NoDataComponent';
import DefaultLayout from '../../components/auth/DefaultLayout';
import CustSpinner from '../../components/Spinner/CustSpinner';

export default function BuyACar() {
    const { logged, updateUserData } = useAuth();
    const [vehicles, setVehicles] = useState([]);
    const [loading, setLoading] = useState(true);
    // const [applyFilter, setapplyFilter] = useState(90);
  
    const formik = useFormik({
        initialValues: {
            searchTerm: "",
            year: "",
            make: "",
            model: "",
            trims: "",
            priceRange: "",
            mileageRange: "",
            selectDistance: ""
        },
        onSubmit: (values) => {
            fetchVehicles(values);
        }
    });


    const fetchVehicles = (values) => {
        setLoading(true);
        SearchFilterVehicleApi(values).then((response)=>{
            if (response.data!==null) {
                setVehicles(response.data);
            } else {
                setVehicles([]);
            }
        })
        .catch((err)=>{
            console.log('====================================');
            console.log(err);
            console.log('====================================');
            setVehicles([]);
        })
        .finally(()=>{
            setLoading(false);
        })
    };




    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedSearch = useCallback(
        debounce((values) => {
            fetchVehicles(values);
        }, 500),
        []
    );

    useEffect(() => {
        if(logged){
        fetchAndUpdateBookmarkedVehicles(updateUserData);
        }
        fetchVehicles(formik.values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [logged]);

    useEffect(() => {
        if (formik.values.searchTerm !=="") {
            debouncedSearch(formik.values);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik.values.searchTerm]);

    return (
        <DefaultLayout>
            <NavBar />
            {logged ?
                <BreadCrumb imageUrl={"url('./sell_a_vehicle.png')"} title={'Verified Used Vehicles'} />
                :
                <BreadCrumb imageUrl={"url('./sell_a_vehicle.png')"} title={'Used Vehicles'} />
            }

            <VStack w={'100%'} py={'8'}>
                <HStack w={'100%'} justify={{ base: 'center', md: 'space-around' }}>
                    <Text fontSize='1xl' color={'#1D1C1A'} opacity={'0.8'} fontWeight={'600'}>All Cars</Text>

                    <InputGroup w={{ base: '50%', md: '40%', lg: '20%' }}>
                        <InputLeftElement
                            pointerEvents="none"
                            children={<IoIosSearch color="#707070" />}
                        />
                        <Input
                            type='text'
                            id='searchTerm'
                            name='searchTerm'
                            rounded={34}
                            placeholder='Search for your favourite car'
                            fontSize={'xs'}
                            onChange={(e) => {
                                formik.handleChange(e);
                                debouncedSearch(e.target.value);
                            }}
                            value={formik.values.searchTerm}
                        />
                    </InputGroup>
                </HStack>

                <SimpleGrid
                        columns={{ base: 1, md: 2, lg: 3 }}
                        w={'auto'}
                        alignContent={'center'}
                        py={12}
                        p={15}
                        spacing={2}
                    >
                    {loading ? (
                        <CustSpinner/>
                    ) : vehicles?.length > 0 ? (
                        
                            vehicles?.map(vehicle => (
                                <Car
                                    key={vehicle._id}
                                    carImage={vehicle.vehiclePhotos[0] && CdnLink(vehicle?.vehiclePhotos[0])}
                                    heading={`${vehicle.make} ${vehicle.vehicleModel}`}
                                    vehicle={vehicle}
                                />
                            ))
                       
                    ) : (
                        <NoDataComponent type='nocars' />
                    )}
                 </SimpleGrid>

            </VStack>

            <SubscribeForm />
            <Buttom />
            <Footer />
            <ApplyFilter formik={formik} />
        </DefaultLayout>
    );
}
