import { GetBookmarkVehicleApi } from "../../Axios/Api";

const fetchAndUpdateBookmarkedVehicles = async (updateUserData) => {
    try {
      const response = await GetBookmarkVehicleApi();
      if (response.success) {
        const bookmarks = response.bookmarks.map(bookmark => ({
          vehicleId: bookmark.vehicleId._id,
          isBookmarked: bookmark.isBookmarked
        }));
  
        updateUserData('bookmarkedVehicles', bookmarks);
      } else {
        console.error('Failed to fetch bookmarked vehicles', response);
      }
    } catch (error) {
      console.error('Error fetching bookmarked vehicles', error);
    }
  };
  
  export default fetchAndUpdateBookmarkedVehicles;