import React, { useState } from 'react'
import NavBar from '../../components/home/navBar/NavBar'
import ButtomBar from '../../components/home/footer/BottomBar'
import {
    Box,
    Button,
    HStack,
    Text,
    VStack
} from '@chakra-ui/react';
import { FaArrowLeftLong } from "react-icons/fa6";
import { useNavigate } from 'react-router-dom';
import RequestTradeTable from '../../components/car/RequestTradeTable';
import SupplementalResourses from '../../components/car/sellACar/SupplementalResourses';

export default function RequestTrade() {
    const [isSupplementalResourses, setIsSupplementalResourses] = useState(false);

    const navigate = useNavigate();

    return (
        <>
            {isSupplementalResourses ?
                <>
                    <NavBar />
                    <VStack h={'100vh'} maxW={'100%'} mx={{ base: 6, md: 4 }} mt={4}>
                        <HStack w={'90%'}>
                            <Box bg='gray.200' w='40px' h='40px' display='flex' justifyContent='center' alignItems='center' rounded='50%'>
                                <FaArrowLeftLong onClick={() => setIsSupplementalResourses(false)} />
                            </Box>

                            <Text
                                w={'100%'}
                                textAlign={'center'}
                                fontSize={{ base: 'sm', md: 'md', lg: 'xl' }}
                                color={'#1D1C1A'}
                                fontWeight={'600'}
                            >
                                Request Trade to Sell Car
                            </Text>
                        </HStack>

                        <SupplementalResourses />
                    </VStack>
                    <ButtomBar />
                </>
                :
                <>
                    <NavBar />

                    <VStack h={'80vh'} maxW={'100%'} mx={{ base: 6, md: 16 }} mt={6}>

                        <Text
                            w={'100%'}
                            textAlign={'center'}
                            fontSize={{ base: 'sm', md: 'md', lg: 'xl' }}
                            color={'#1D1C1A'}
                            fontWeight={'600'}
                        >
                            Request Trade to Sell Car
                        </Text>

                        {/* My Listings */}
                        <HStack w={'100%'} justifyContent={'space-between'} mt={4}>
                            <Text
                                fontSize={{ base: 'xs', md: 'md' }}
                                fontWeight={'600'}
                                color={'#1D1C1A'}
                                opacity={'0.9'}
                            >
                                My Listings
                            </Text>

                            <Button
                                colorScheme="red"
                                rounded={'22px'}
                                w={{ base: 28, md: 44 }}
                                h={10}
                                fontSize={'xs'}
                                onClick={() => navigate('/sell-a-car')}
                            >
                                Add Listing
                            </Button>
                        </HStack>
                        <RequestTradeTable setIsSupplementalResourses={setIsSupplementalResourses} />
                    </VStack>
                    <ButtomBar />

                </>
            }
        </>
    )
}
