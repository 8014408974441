import {
    Modal,
    ModalOverlay,
    ModalContent,
    Text,
    VStack,
    Flex,
    Image,
    ModalCloseButton,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

export default function TheftReport({ isOpen, onClose, onOpen }) {
    const navigate = useNavigate();

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose} isCentered onOpen size={{base:'xs', md:'xl'}}>
                <ModalOverlay />
                <ModalContent>               
                 <ModalCloseButton />
                    <VStack mx={'6'} pt={4}>

                        <Text 
                        textAlign='start' 
                        w={{ base: '100%', md: '100%' }} 
                        fontSize={{ base: 'md', md: 'xl' }} 
                        fontWeight={'500'} 
                        color={'#153373'} 
                        opacity={'0.8'}
                        >
                          Theft Report
                        </Text>

                        <VStack w={'100%'} alignItems={'start'} mb={4}>
                        <Text
                            color={'#707070'}
                            opacity={'0.8'}
                            fontWeight={'400'}
                            fontSize={'xs'}
                            textAlign='start'
                        >
                          Lorem ipsum dolor sit amet consectetur. Et urna neque dolor lorem. Eleifend ultrices non egestas at. Viverra leo metus pharetra aenean. Tempus egestas aliquam velit etiam facilisi.
                        </Text>
                        <Text
                            color={'#707070'}
                            opacity={'0.8'}
                            fontWeight={'400'}
                            fontSize={'xs'}
                            textAlign='start'
                        >
                          Lorem ipsum dolor sit amet consectetur. Et urna neque dolor lorem. Eleifend ultrices non egestas at. Viverra leo metus pharetra aenean. Tempus egestas aliquam velit etiam facilisi.
                        </Text>
                        <Text
                            color={'#707070'}
                            opacity={'0.8'}
                            fontWeight={'400'}
                            fontSize={'xs'}
                            textAlign='start'
                        >
                          Lorem ipsum dolor sit amet consectetur. Et urna neque dolor lorem. Eleifend ultrices non egestas at. Viverra leo metus pharetra aenean. Tempus egestas aliquam velit etiam facilisi.
                        </Text>
                        <Text
                            color={'#707070'}
                            opacity={'0.8'}
                            fontWeight={'400'}
                            fontSize={'xs'}
                            textAlign='start'
                        >
                          Lorem ipsum dolor sit amet consectetur. Et urna neque dolor lorem. Eleifend ultrices non egestas at. Viverra leo metus pharetra aenean. Tempus egestas aliquam velit etiam facilisi.
                        </Text>
                        <Text
                            color={'#707070'}
                            opacity={'0.8'}
                            fontWeight={'400'}
                            fontSize={'xs'}
                            textAlign='start'
                        >
                          Lorem ipsum dolor sit amet consectetur. Et urna neque dolor lorem. Eleifend ultrices non egestas at. Viverra leo metus pharetra aenean. Tempus egestas aliquam velit etiam facilisi.
                        </Text>
                        <Text
                            color={'#707070'}
                            opacity={'0.8'}
                            fontWeight={'400'}
                            fontSize={'xs'}
                            textAlign='start'
                        >
                          Lorem ipsum dolor sit amet consectetur. Et urna neque dolor lorem. Eleifend ultrices non egestas at. Viverra leo metus pharetra aenean. Tempus egestas aliquam velit etiam facilisi.
                        </Text>
                        </VStack>
                    </VStack>

                </ModalContent>
            </Modal>
        </>
    )
}


