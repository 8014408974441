import React from 'react';
import {
    Checkbox,
    Image,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Table,
    TableContainer,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
} from '@chakra-ui/react';
import { RiMoreLine } from "react-icons/ri";
import { useNavigate } from 'react-router-dom';

export default function LoanRequests() {
    const navigate = useNavigate();
    return (
        <TableContainer w={'100%'} maxH={'100vh'} overflowY={'auto'} mb={4}>
            <Table variant='striped'>
                <Thead >
                    <Tr>
                        <Th w={12}> <Checkbox /> </Th>
                        <Th w={20}></Th>
                        <Th>Request Id</Th>
                        <Th>Submission Date</Th>
                        <Th>Submission Time</Th>
                        <Th>Status</Th>
                        <Th></Th>
                    </Tr>
                </Thead>
                <Tbody>
                    <Tr>
                        <Td> <Checkbox /> </Td>
                        <Td p={0}> <Image src='./car1.png' w={70} /></Td>
                        <Td fontSize={'xs'} color={'#06A7E2'} onClick={()=>navigate('/car-details')} cursor={'pointer'}>
                            #3456719
                        </Td>
                        <Td fontSize={'xs'}>15-11-2022</Td>
                        <Td fontSize={'xs'}>12:45 PM</Td>
                        <Td fontSize={'xs'} color={'#EE6700'}>Quoted</Td>
                        <Td>
                            <Menu>
                                <MenuButton>
                                    <RiMoreLine />
                                </MenuButton>

                                <MenuList >
                                    <MenuItem>
                                        <Text>Edit</Text>
                                    </MenuItem>
                                    <MenuItem>
                                        <Text color={'#FC4656'}>Delete</Text>
                                    </MenuItem>
                                </MenuList>
                            </Menu>
                        </Td>
                    </Tr>

                    <Tr>
                        <Td> <Checkbox /> </Td>
                        <Td p={0}> <Image src='./car1.png' w={70} /></Td>
                        <Td fontSize={'xs'} color={'#06A7E2'} onClick={()=>navigate('/car-details')} cursor={'pointer'}>
                            #3456719
                        </Td>
                        <Td fontSize={'xs'}>15-11-2022</Td>
                        <Td fontSize={'xs'}>12:45 PM</Td>
                        <Td fontSize={'xs'} color={'#EE6700'}>Approve</Td>
                        <Td>
                            <Menu>
                                <MenuButton>
                                    <RiMoreLine />
                                </MenuButton>

                                <MenuList minWidth='120px'>
                                    <MenuItem>
                                        <Text>Edit</Text>
                                    </MenuItem>
                                    <MenuItem>
                                        <Text color={'#FC4656'}>Delete</Text>
                                    </MenuItem>
                                </MenuList>
                            </Menu>
                        </Td>
                    </Tr>

                    <Tr>
                        <Td> <Checkbox /> </Td>
                        <Td p={0}> <Image src='./car1.png' w={70} /></Td>
                        <Td fontSize={'xs'} color={'#06A7E2'} onClick={()=>navigate('/car-details')} cursor={'pointer'}>
                            #3456719
                        </Td>
                        <Td fontSize={'xs'}>15-11-2022</Td>
                        <Td fontSize={'xs'}>12:45 PM</Td>
                        <Td fontSize={'xs'} color={'#EE6700'}>Approve</Td>
                        <Td>
                            <Menu>
                                <MenuButton>
                                    <RiMoreLine />
                                </MenuButton>

                                <MenuList minWidth='120px'>
                                    <MenuItem>
                                        <Text>Edit</Text>
                                    </MenuItem>
                                    <MenuItem>
                                        <Text color={'#FC4656'}>Delete</Text>
                                    </MenuItem>
                                </MenuList>
                            </Menu>
                        </Td>
                    </Tr>
                </Tbody>
            </Table>
        </TableContainer>
    )
}

