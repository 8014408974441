import React from 'react';
import { Navigate, Outlet } from 'react-router-dom'
import { useAuth } from '../../providers/AuthProvider';

export default function PrivateRoutes() {
    const { loading, logged } = useAuth();

    if (loading) {
        return (
            <React.Fragment>
                <p>Loading...</p>
            </React.Fragment>
        )
    }
    return (
        logged ?
            <>
                <Outlet />
            </>
            : <Navigate to='/login' />
    )
}

