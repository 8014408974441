import {
    Button,
    Card,
    VStack,
    Text,
    HStack,
    Image,
    Stack,
    Flex,
    RadioGroup,
    Radio,
    Textarea
} from '@chakra-ui/react';
import React, {  useState } from 'react';
import { Divider } from '@chakra-ui/react';
// import { useNavigate } from 'react-router-dom';

export default function CarCondition({ currentStep, setCurrentStep ,formik}) {
    const [carConditionStep, setCarConditionStep] = useState(0);

    const [sellerDesc, setSellerDesc] = useState('');

    const handleChange = (event) => {
        setSellerDesc(event.target.value);
        formik.setFieldValue(`vehicleCondition.paperwork.sellerDesciption`, event.target.value);
    };

    const characterCount = sellerDesc.length;
    const handleDrivabilityChange = (index, value , field) => {
        const updatedAnswers = [...formik.values.vehicleCondition[field].answers];
        updatedAnswers[index] = value === 'true';
        formik.setFieldValue(`vehicleCondition.${field}.answers`, updatedAnswers);
    };
    return (
        <Card
            bg='white'
            borderRadius={'48px'}
            style={{
                boxShadow: '0px 10px 99px #00000029'
            }}
            py={{ base: '8', lg: '6' }}
            px={{ base: '6', md: '12', lg: '16' }}
            w={{ base: '80%', md: '90%', lg: '60%' }}
            my={{ base: 4, md: 6 }}
            mb={{ base: 4, md: 52, lg: 28 }}
        >
            {carConditionStep === 0 || carConditionStep === 1 || carConditionStep === 2 || carConditionStep === 3 || carConditionStep === 4 ?
                <VStack
                    maxW='100%'
                    w='100%'
                    justifyContent={{ base: 'center', md: 'start', lg: 'start' }}
                    alignItems={{ base: 'center', md: 'start', lg: 'start' }}
                    mb={6}
                >
                    <Text
                        textAlign={{ base: 'center', md: 'start' }}
                        w={{ base: '100%', md: '80%' }}
                        fontSize={{ base: 'xs', md: 'sm', lg: 'md' }}
                        fontWeight={'500'}
                        color={'#153373'}
                        opacity={'0.8'}
                    >
                        How is the condition of your car?
                    </Text>

                    <Divider borderWidth={'1px'} borderColor={'#e3e1e1'} alignItems={'center'} w={'100%'} my={2} />

                    {/* Mobile View */}
                    <Flex
                        mt={4}
                        w={'70%'}
                        alignItems={'center'}
                        justify={'center'}
                        bg={'white'}
                        borderRadius={'48px'}
                        style={{
                            boxShadow: '0px 10px 99px #00000029'
                        }}
                        fontSize={{ base: 'xs' }}
                        display={{ base: 'flex', md: 'none', lg: 'none' }}
                        py={2}
                    >

                        {carConditionStep === 0 ?
                            <HStack p={2} ml={4}>
                                <Image src={'./drivability_outline.svg'} h={35} />
                                <Text color={'#153373'} fontSize={'xs'} fontWeight={'500'} opacity={'0.8'}>Drivability</Text>
                            </HStack>
                        : carConditionStep === 1 ?
                            <HStack p={2} ml={4}>
                                <Image src={'./safety_outline.svg'} h={35} />
                                <Text color={'#153373'} fontSize={'xs'} fontWeight={'500'} opacity={'0.8'}>Safety</Text>
                            </HStack>
                        : carConditionStep === 2 ?
                            <HStack p={2} ml={4}>
                                <Image src={'./appearance_outline.svg'} h={35} />
                                <Text color={'#153373'} fontSize={'xs'} fontWeight={'500'} opacity={'0.8'}>Appearance</Text>
                            </HStack>
                        : carConditionStep === 3 ?
                            <HStack p={2} ml={4}>
                                <Image src={'./conform_outline.svg'} h={35} />
                                <Text color={'#153373'} fontSize={'xs'} fontWeight={'500'} opacity={'0.8'}>Conform</Text>
                            </HStack>
                        :
                            <HStack p={2} ml={4}>
                                <Image src={'./paperwork_outline.svg'} h={35} />
                                <Text color={'#153373'} fontSize={'xs'} fontWeight={'500'} opacity={'0.8'}>Paperwork</Text>
                            </HStack>
                        }

                    </Flex>

                    {/* web View */}
                    <Flex
                        // w={{ md: '90%', lg: '52%' }}
                        w={'100%'}
                        justify={'space-between'}
                        fontSize={{ base: 'xs' }}
                        display={{ base: 'none', md: 'flex', lg: 'flex' }}
                    >

                        {carConditionStep === 0 ?
                            <HStack >
                                <Image src={'./drivability_outline.svg'} h={30} />
                                <Text color={'#153373'} fontSize={'xs'} opacity={'0.8'}>Drivability</Text>
                            </HStack>
                            :
                            <>
                                <HStack >
                                    <Image src={'./drivability_fill.svg'} h={30} />
                                    <Text color={'#153373'} fontSize={'xs'} opacity={'0.8'}>Drivability</Text>
                                </HStack>
                            </>
                        }

                        {carConditionStep === 1 ?
                            <HStack >
                                <Image src={'./safety_outline.svg'} h={30} />
                                <Text color={'#153373'} fontSize={'xs'} fontWeight={'500'} opacity={'0.8'}>Safety</Text>
                            </HStack>
                            : carConditionStep === 2 || carConditionStep === 3 || carConditionStep === 4 || carConditionStep === 5 ?
                                <>
                                <HStack >
                                    <Image src={'./safety_fill.svg'} h={30} />
                                    <Text color={'#153373'} fontSize={'xs'} fontWeight={'500'} opacity={'0.8'}>Safety</Text>
                                </HStack>
                                </>
                            :
                            <>
                                <HStack>
                                    <Image src={'./safety_unfilled.svg'} h={35} />
                                    <Text color={'#153373'} fontSize={'xs'} fontWeight={'500'} opacity={'0.8'}>Safety</Text>
                                </HStack>
                            </>
                        }

                        {carConditionStep === 2 ?
                            <HStack >
                                <Image src={'./appearance_outline.svg'} h={30} />
                                <Text color={'#153373'} fontSize={'xs'} fontWeight={'500'} opacity={'0.8'}>Appearance</Text>
                            </HStack>
                            : carConditionStep === 3 || carConditionStep === 4 || carConditionStep === 5 ?
                                <>
                                    <HStack >
                                        <Image src={'./appearance_fill.svg'} h={30} />
                                        <Text color={'#153373'} fontSize={'xs'} fontWeight={'500'} opacity={'0.8'}>Appearance</Text>
                                    </HStack>
                                </>
                                :
                                <>
                                    <HStack>
                                        <Image src={'./appearance_unfilled.svg'} h={35} />
                                        <Text color={'#153373'} fontSize={'xs'} fontWeight={'500'} opacity={'0.8'}>Appearance</Text>
                                    </HStack>
                                </>
                        }

                        {carConditionStep === 3 ?
                            <HStack >
                                <Image src={'./conform_outline.svg'} h={30} />
                                <Text color={'#153373'} fontSize={'xs'} fontWeight={'500'} opacity={'0.8'}>Conform</Text>
                            </HStack>
                            : carConditionStep === 4 || carConditionStep === 5 ?
                                <>
                                    <HStack >
                                        <Image src={'./conform_fill.svg'} h={30} />
                                        <Text color={'#153373'} fontSize={'xs'} fontWeight={'500'} opacity={'0.8'}>Conform</Text>
                                    </HStack>
                                </>
                                :
                                <>
                                    <HStack>
                                        <Image src={'./conform_unfilled.svg'} h={35} />
                                        <Text color={'#153373'} fontSize={'xs'} fontWeight={'500'} opacity={'0.8'}>Conform</Text>
                                    </HStack>
                                </>
                        }

                        {carConditionStep === 4 ?
                            <HStack >
                                <Image src={'./paperwork_outline.svg'} h={30} />
                                <Text color={'#153373'} fontSize={'xs'} fontWeight={'500'} opacity={'0.8'}>Paperwork</Text>
                            </HStack>
                            : carConditionStep === 5 ?
                                <>
                                    <HStack >
                                        <Image src={'./paperwork_fill.svg'} h={30} />
                                        <Text color={'#153373'} fontSize={'xs'} fontWeight={'500'} opacity={'0.8'}>Paperwork</Text>
                                    </HStack>
                                </>
                                :
                                <>
                                    <HStack>
                                        <Image src={'./paperwork_unfilled.svg'} h={35} />
                                        <Text color={'#153373'} fontSize={'xs'} fontWeight={'500'} opacity={'0.8'}>Paperwork</Text>
                                    </HStack>
                                </>
                        }
                    </Flex>

                    {carConditionStep === 0 ?
                        <VStack w={'100%'} pt={4}>
                            <Stack direction={{base:'column', md:'row'}} pt={4} w={'100%'} justify={'space-between'}>
                                <Text color={'#575757'} fontSize={'xs'}>
                                    1. Is the vehicle drivable?
                                </Text>
                                <HStack>
                                    <RadioGroup 
                                        value={formik.values.vehicleCondition.drivability.answers[0] ? 'true' : 'false'}
                                        onChange={(value) => handleDrivabilityChange(0, value,'drivability')}
                                        >
                                        <Stack direction='row'>
                                            <Radio value='true' spacing={1} >
                                                <Text fontSize={'xs'} color={'#153373'}>Yes</Text>
                                            </Radio>
                                            <Radio value='false' spacing={1} ml={2}>
                                                <Text fontSize={'xs'} color={'#153373'}>No</Text>
                                            </Radio>
                                        </Stack>
                                    </RadioGroup>
                                </HStack>
                            </Stack>

                            <Stack direction={{base:'column', md:'row'}} pt={4} w={'100%'} justify={'space-between'}>
                                <Text color={'#575757'} fontSize={'xs'}>
                                    2. Can and/or has the vehicle passed the state emissions test in the last 30 days?
                                </Text>
                                <HStack>
                                <RadioGroup 
                                        value={formik.values.vehicleCondition.drivability.answers[1] ? 'true' : 'false'}
                                        onChange={(value) => handleDrivabilityChange(1, value,'drivability')}
                                        >
                                        <Stack direction='row'>
                                            <Radio value='true' spacing={1} >
                                                <Text fontSize={'xs'} color={'#153373'} >Yes</Text>
                                            </Radio>
                                            <Radio value='false' spacing={1} ml={2}>
                                                <Text fontSize={'xs'} color={'#153373'}>No</Text>
                                            </Radio>
                                        </Stack>
                                    </RadioGroup>
                                </HStack>
                            </Stack>

                            <Stack direction={{base:'column', md:'row'}} pt={4} w={'100%'} justify={'space-between'}>
                                <Text color={'#575757'} fontSize={'xs'}>
                                    3. Has the vehicle ever been in an accident?
                                </Text>
                                <HStack>
                                <RadioGroup 
                                        value={formik.values.vehicleCondition.drivability.answers[2] ? 'true' : 'false'}
                                        onChange={(value) => handleDrivabilityChange(2, value,'drivability')}
                                        >
                                        <Stack direction='row'>
                                            <Radio value='true' spacing={1} >
                                                <Text fontSize={'xs'} color={'#153373'} >Yes</Text>
                                            </Radio>
                                            <Radio value='false' spacing={1} ml={2}>
                                                <Text fontSize={'xs'} color={'#153373'}>No</Text>
                                            </Radio>
                                        </Stack>
                                    </RadioGroup>
                                </HStack>
                            </Stack>

                            <Stack direction={{base:'column', md:'row'}} pt={4} w={'100%'} justify={'space-between'}>
                                <Text color={'#575757'} fontSize={'xs'}>
                                    4. Is the vehicle salvaged/rebuilt/flood/fire/frame damaged? Are there any title issues?
                                </Text>
                                <HStack>
                                <RadioGroup 
                                        value={formik.values.vehicleCondition.drivability.answers[3] ? 'true' : 'false'}
                                            onChange={(value) => handleDrivabilityChange(3, value,'drivability')}
                                        >
                                        <Stack direction='row'>
                                            <Radio value='true' spacing={1} >
                                                <Text fontSize={'xs'} color={'#153373'} >Yes</Text>
                                            </Radio>
                                            <Radio value='false' spacing={1} ml={2}>
                                                <Text fontSize={'xs'} color={'#153373'}>No</Text>
                                            </Radio>
                                        </Stack>
                                    </RadioGroup>
                                </HStack>
                            </Stack>

                        </VStack>
                        : carConditionStep === 1 ?
                            <VStack w={'100%'} pt={4}>
                                <Stack direction={{base:'column', md:'row'}} pt={4} w={'100%'} justify={'space-between'}>
                                    <Text color={'#575757'} fontSize={'xs'}>
                                        1. Does the vehicle meet safety standards?
                                    </Text>
                                    <HStack>
                                    <RadioGroup 
                                        value={formik.values.vehicleCondition.safety.answers[0] ? 'true' : 'false'}
                                            onChange={(value) => handleDrivabilityChange(0, value,'safety')}
                                        >
                                            <Stack direction='row'>
                                                <Radio value='true' spacing={1} >
                                                    <Text fontSize={'xs'} color={'#153373'}>Yes</Text>
                                                </Radio>
                                                <Radio value='false' spacing={1} ml={2}>
                                                    <Text fontSize={'xs'} color={'#153373'}>No</Text>
                                                </Radio>
                                            </Stack>
                                        </RadioGroup>
                                    </HStack>
                                </Stack>

                                <Stack direction={{base:'column', md:'row'}} pt={4} w={'100%'} justify={'space-between'}>
                                    <Text color={'#575757'} fontSize={'xs'}>
                                        2. Are any windows cracked or broken?
                                    </Text>
                                    <HStack>
                                    <RadioGroup 
                                        value={formik.values.vehicleCondition.safety.answers[1] ? 'true' : 'false'}
                                            onChange={(value) => handleDrivabilityChange(1, value,'safety')}
                                        >
                                            <Stack direction='row'>
                                                <Radio value='true' spacing={1} >
                                                    <Text fontSize={'xs'} color={'#153373'} >Yes</Text>
                                                </Radio>
                                                <Radio value='false' spacing={1} ml={2}>
                                                    <Text fontSize={'xs'} color={'#153373'}>No</Text>
                                                </Radio>
                                            </Stack>
                                        </RadioGroup>
                                    </HStack>
                                </Stack>

                                <Stack direction={{base:'column', md:'row'}} pt={4} w={'100%'} justify={'space-between'}>
                                    <Text color={'#575757'} fontSize={'xs'}>
                                        3. Are any of the tires worn or old, with less than 2" tread?
                                    </Text>
                                    <HStack>
                                    <RadioGroup 
                                        value={formik.values.vehicleCondition.safety.answers[2] ? 'true' : 'false'}
                                            onChange={(value) => handleDrivabilityChange(2, value,'safety')}
                                        >
                                            <Stack direction='row'>
                                                <Radio value='true' spacing={1} >
                                                    <Text fontSize={'xs'} color={'#153373'} >Yes</Text>
                                                </Radio>
                                                <Radio value='false' spacing={1} ml={2}>
                                                    <Text fontSize={'xs'} color={'#153373'}>No</Text>
                                                </Radio>
                                            </Stack>
                                        </RadioGroup>
                                    </HStack>
                                </Stack>

                                <Stack direction={{base:'column', md:'row'}} pt={4} w={'100%'} justify={'space-between'}>
                                    <Text color={'#575757'} fontSize={'xs'}>
                                        4. Have the brakes been changed in the last two years?
                                    </Text>
                                    <HStack>
                                    <RadioGroup 
                                        value={formik.values.vehicleCondition.safety.answers[3] ? 'true' : 'false'}
                                            onChange={(value) => handleDrivabilityChange(3, value,'safety')}
                                        >
                                            <Stack direction='row'>
                                                <Radio value='true' spacing={1} >
                                                    <Text fontSize={'xs'} color={'#153373'} >Yes</Text>
                                                </Radio>
                                                <Radio value='false' spacing={1} ml={2}>
                                                    <Text fontSize={'xs'} color={'#153373'}>No</Text>
                                                </Radio>
                                            </Stack>
                                        </RadioGroup>
                                    </HStack>
                                </Stack>

                                <Stack direction={{base:'column', md:'row'}} pt={4} w={'100%'} justify={'space-between'}>
                                    <Text color={'#575757'} fontSize={'xs'}>
                                        5. Have the oil and fluids been changed in the last year?
                                    </Text>
                                    <HStack>
                                    <RadioGroup 
                                        value={formik.values.vehicleCondition.safety.answers[4] ? 'true' : 'false'}
                                            onChange={(value) => handleDrivabilityChange(4, value,'safety')}
                                        >
                                            <Stack direction='row'>
                                                <Radio value='true' spacing={1} >
                                                    <Text fontSize={'xs'} color={'#153373'} >Yes</Text>
                                                </Radio>
                                                <Radio value='false' spacing={1} ml={2}>
                                                    <Text fontSize={'xs'} color={'#153373'}>No</Text>
                                                </Radio>
                                            </Stack>
                                        </RadioGroup>
                                    </HStack>
                                </Stack>

                                <Stack direction={{base:'column', md:'row'}} pt={4} w={'100%'} justify={'space-between'}>
                                    <Text color={'#575757'} fontSize={'xs'}>
                                        6. Do all the seat belts, windows and doors work?
                                    </Text>
                                    <HStack>
                                    <RadioGroup 
                                        value={formik.values.vehicleCondition.safety.answers[5] ? 'true' : 'false'}
                                            onChange={(value) => handleDrivabilityChange(5, value,'safety')}
                                        >
                                            <Stack direction='row'>
                                                <Radio value='true' spacing={1} >
                                                    <Text fontSize={'xs'} color={'#153373'} >Yes</Text>
                                                </Radio>
                                                <Radio value='false' spacing={1} ml={2}>
                                                    <Text fontSize={'xs'} color={'#153373'}>No</Text>
                                                </Radio>
                                            </Stack>
                                        </RadioGroup>
                                    </HStack>
                                </Stack>

                                <Stack direction={{base:'column', md:'row'}} pt={4} w={'100%'} justify={'space-between'}>
                                    <Text color={'#575757'} fontSize={'xs'}>
                                        7. Do all the lights work?
                                    </Text>
                                    <HStack>
                                    <RadioGroup 
                                        value={formik.values.vehicleCondition.safety.answers[6] ? 'true' : 'false'}
                                            onChange={(value) => handleDrivabilityChange(6, value,'safety')}
                                        >
                                            <Stack direction='row'>
                                                <Radio value='true' spacing={1} >
                                                    <Text fontSize={'xs'} color={'#153373'} >Yes</Text>
                                                </Radio>
                                                <Radio value='false' spacing={1} ml={2}>
                                                    <Text fontSize={'xs'} color={'#153373'}>No</Text>
                                                </Radio>
                                            </Stack>
                                        </RadioGroup>
                                    </HStack>
                                </Stack>
                            </VStack>
                            : carConditionStep === 2 ?
                                <VStack w={'100%'} pt={4}>
                                    <Stack direction={{base:'column', md:'row'}} pt={4} w={'100%'} justify={'space-between'}>
                                        <Text color={'#575757'} fontSize={'xs'}>
                                            1. Has the vehicle ever been repainted?
                                        </Text>
                                        <HStack>
                                        <RadioGroup 
                                        value={formik.values.vehicleCondition.appearance.answers[0] ? 'true' : 'false'}
                                            onChange={(value) => handleDrivabilityChange(0, value,'appearance')}
                                        >
                                                <Stack direction='row'>
                                                    <Radio value='true' spacing={1} >
                                                        <Text fontSize={'xs'} color={'#153373'}>Yes</Text>
                                                    </Radio>
                                                    <Radio value='false' spacing={1} ml={2}>
                                                        <Text fontSize={'xs'} color={'#153373'}>No</Text>
                                                    </Radio>
                                                </Stack>
                                            </RadioGroup>
                                        </HStack>
                                    </Stack>

                                    <Stack direction={{base:'column', md:'row'}} pt={4} w={'100%'} justify={'space-between'}>
                                        <Text color={'#575757'} fontSize={'xs'}>
                                            2. Are there any tears, stains, or smells in the vehicle?
                                        </Text>
                                        <HStack>
                                        <RadioGroup 
                                        value={formik.values.vehicleCondition.appearance.answers[1] ? 'true' : 'false'}
                                            onChange={(value) => handleDrivabilityChange(1, value,'appearance')}
                                        >
                                                <Stack direction='row'>
                                                    <Radio value='true' spacing={1} >
                                                        <Text fontSize={'xs'} color={'#153373'} >Yes</Text>
                                                    </Radio>
                                                    <Radio value='false' spacing={1} ml={2}>
                                                        <Text fontSize={'xs'} color={'#153373'}>No</Text>
                                                    </Radio>
                                                </Stack>
                                            </RadioGroup>
                                        </HStack>
                                    </Stack>

                                    <Stack direction={{base:'column', md:'row'}} pt={4} w={'100%'} justify={'space-between'}>
                                        <Text color={'#575757'} fontSize={'xs'}>
                                            3. Are there any dents, dings, road rash or scuffs?
                                        </Text>
                                        <HStack>
                                        <RadioGroup 
                                        value={formik.values.vehicleCondition.appearance.answers[2] ? 'true' : 'false'}
                                            onChange={(value) => handleDrivabilityChange(2, value,'appearance')}
                                        >
                                                <Stack direction='row'>
                                                    <Radio value='true' spacing={1} >
                                                        <Text fontSize={'xs'} color={'#153373'} >Yes</Text>
                                                    </Radio>
                                                    <Radio value='false' spacing={1} ml={2}>
                                                        <Text fontSize={'xs'} color={'#153373'}>No</Text>
                                                    </Radio>
                                                </Stack>
                                            </RadioGroup>
                                        </HStack>
                                    </Stack>

                                    <Stack direction={{base:'column', md:'row'}} pt={4} w={'100%'} justify={'space-between'}>
                                        <Text color={'#575757'} fontSize={'xs'}>
                                            4. Are there any missing or broken parts, buttons or pieces?
                                        </Text>
                                        <HStack>
                                        <RadioGroup 
                                        value={formik.values.vehicleCondition.appearance.answers[3] ? 'true' : 'false'}
                                            onChange={(value) => handleDrivabilityChange(3, value,'appearance')}
                                        >
                                                <Stack direction='row'>
                                                    <Radio value='true' spacing={1} >
                                                        <Text fontSize={'xs'} color={'#153373'} >Yes</Text>
                                                    </Radio>
                                                    <Radio value='false' spacing={1} ml={2}>
                                                        <Text fontSize={'xs'} color={'#153373'}>No</Text>
                                                    </Radio>
                                                </Stack>
                                            </RadioGroup>
                                        </HStack>
                                    </Stack>

                                </VStack>
                                : carConditionStep === 3 ?
                                    <VStack w={'100%'} pt={4}>
                                        <Stack direction={{base:'column', md:'row'}} pt={4} w={'100%'} justify={'space-between'}>
                                            <Text color={'#575757'} fontSize={'xs'}>
                                                1. Does the air-condition/heating work?
                                            </Text>
                                            <HStack>
                                            <RadioGroup 
                                        value={formik.values.vehicleCondition.conform.answers[0] ? 'true' : 'false'}
                                            onChange={(value) => handleDrivabilityChange(0, value,'conform')}
                                        >
                                                    <Stack direction='row'>
                                                        <Radio value='true' spacing={1} >
                                                            <Text fontSize={'xs'} color={'#153373'}>Yes</Text>
                                                        </Radio>
                                                        <Radio value='false' spacing={1} ml={2}>
                                                            <Text fontSize={'xs'} color={'#153373'}>No</Text>
                                                        </Radio>
                                                    </Stack>
                                                </RadioGroup>
                                            </HStack>
                                        </Stack>

                                        <Stack direction={{base:'column', md:'row'}} pt={4} w={'100%'} justify={'space-between'}>
                                            <Text color={'#575757'} fontSize={'xs'}>
                                                2. Does the radio work?
                                            </Text>
                                            <HStack>
                                            <RadioGroup 
                                        value={formik.values.vehicleCondition.conform.answers[1] ? 'true' : 'false'}
                                            onChange={(value) => handleDrivabilityChange(1, value,'conform')}
                                        >
                                                    <Stack direction='row'>
                                                        <Radio value='true' spacing={1} >
                                                            <Text fontSize={'xs'} color={'#153373'} >Yes</Text>
                                                        </Radio>
                                                        <Radio value='false' spacing={1} ml={2}>
                                                            <Text fontSize={'xs'} color={'#153373'}>No</Text>
                                                        </Radio>
                                                    </Stack>
                                                </RadioGroup>
                                            </HStack>
                                        </Stack>

                                        <Stack direction={{base:'column', md:'row'}} pt={4} w={'100%'} justify={'space-between'}>
                                            <Text color={'#575757'} fontSize={'xs'}>
                                                3. Does the windows go up and down?
                                            </Text>
                                            <HStack>
                                            <RadioGroup 
                                        value={formik.values.vehicleCondition.conform.answers[2] ? 'true' : 'false'}
                                            onChange={(value) => handleDrivabilityChange(2, value,'conform')}
                                        >
                                                    <Stack direction='row'>
                                                        <Radio value='true' spacing={1} >
                                                            <Text fontSize={'xs'} color={'#153373'} >Yes</Text>
                                                        </Radio>
                                                        <Radio value='false' spacing={1} ml={2}>
                                                            <Text fontSize={'xs'} color={'#153373'}>No</Text>
                                                        </Radio>
                                                    </Stack>
                                                </RadioGroup>
                                            </HStack>
                                        </Stack>

                                    </VStack>
                                    : carConditionStep === 4 ?
                                        <VStack w={'100%'} pt={4}>
                                            <Stack direction={{base:'column', md:'row'}} pt={4} w={'100%'} justify={'space-between'}>
                                                <Text color={'#575757'} fontSize={'xs'}>
                                                    Are you the registered owner of the vehicle?
                                                </Text>
                                                <HStack>
                                                <RadioGroup 
                                        value={formik.values.vehicleCondition.paperwork.answers[0] ? 'true' : 'false'}
                                            onChange={(value) => handleDrivabilityChange(0, value,'paperwork')}
                                        >
                                                        <Stack direction='row'>
                                                            <Radio value='true' spacing={1} >
                                                                <Text fontSize={'xs'} color={'#153373'}>Yes</Text>
                                                            </Radio>
                                                            <Radio value='false' spacing={1} ml={2}>
                                                                <Text fontSize={'xs'} color={'#153373'}>No</Text>
                                                            </Radio>
                                                        </Stack>
                                                    </RadioGroup>
                                                </HStack>
                                            </Stack>

                                            <Text w={'100%'} color={'#575757'} fontSize={'xs'} textAlign={'start'} pt={4}>
                                                Seller Description
                                            </Text>

                                            <Textarea
                                                value={sellerDesc}
                                                onChange={handleChange}
                                                maxLength={250}
                                                resize="none"
                                            />
                                            <Text w={'100%'} textAlign={'end'} fontSize="xs" color={characterCount <= 250 ? 'gray.500' : 'red.500'}>
                                                {characterCount}/250
                                            </Text>
                                        </VStack>
                         : <></>
                    }
                </VStack>
                :
                <VStack
                maxW='100%'
                w='100%'
                justifyContent={{ base: 'center', md: 'start', lg: 'start' }}
                alignItems={{ base: 'center', md: 'start', lg: 'start' }}
                mb={6}
            >
                <Text
                    textAlign={'center'}
                    w={'100%'}
                    fontSize={{ base: 'sm', md: 'md', lg: 'xl' }}
                    fontWeight={'500'}
                    color={'#153373'}
                    opacity={'0.8'}
                >
                    Your Score is <span style={{color:'#25BC9D', fontSize:'20px', fontWeight:'600'}}>78</span>
                </Text>

                <Divider borderWidth={'1px'} borderColor={'#e3e1e1'} alignItems={'center'} w={'100%'} my={2} />

                <Stack direction={{base:'column', md:'row'}} spacing={{base:'6', md:'0'}} w={'100%'} justify={'space-between'}>
                    <VStack>
                        <Image src='./half_score.png' h={90}/>
                        <Text color={'#707070'} fontSize={'sm'} fontWeight={600} pt={2}>
                        Drivability
                        </Text>
                    </VStack>

                    <VStack>
                        <Image src='./full_score.png' h={90}/>
                        <Text color={'#707070'} fontSize={'sm'} fontWeight={600} pt={2}>
                        Safety
                        </Text>
                    </VStack>

                    <VStack>
                        <Image src='./full_score.png' h={90}/>
                        <Text color={'#707070'} fontSize={'sm'} fontWeight={600} pt={2}>
                        Appearance
                        </Text>
                    </VStack>

                    <VStack>
                        <Image src='./half_score.png' h={90}/>
                        <Text color={'#707070'} fontSize={'sm'} fontWeight={600} pt={2}>
                        Conform
                        </Text>
                    </VStack>

                    <VStack>
                        <Image src='./full_score.png' h={90}/>
                        <Text color={'#707070'} fontSize={'sm'} fontWeight={600} pt={2}>
                        Paperwork
                        </Text>
                    </VStack>
                </Stack>

                </VStack>

            }

            {carConditionStep === 0 || carConditionStep === 1 || carConditionStep === 2 || carConditionStep === 3 ?
                <Button
                    colorScheme="red"
                    py={6}
                    rounded={'34px'}
                    w={{ base: '60%', md: '30%', lg: '20%' }}
                    position={'absolute'}
                    bottom={'0'}
                    left={'50%'}
                    transform={'translate(-50%, 50%)'}
                    fontSize={'xs'}
                    onClick={() => setCarConditionStep(carConditionStep + 1)}
                >
                    Save & Next
                </Button>
                : carConditionStep === 4 ?
                    <Button
                        colorScheme="red"
                        py={6}
                        rounded={'34px'}
                        w={{ base: '60%', md: '30%', lg: '20%' }}
                        position={'absolute'}
                        bottom={'0'}
                        left={'50%'}
                        transform={'translate(-50%, 50%)'}
                        fontSize={'xs'}
                        onClick={() => formik.handleSubmit()}
                    >
                        Finish
                    </Button>
                    :
                    <Button
                        colorScheme="red"
                        py={6}
                        rounded={'34px'}
                        w={{ base: '60%', md: '30%', lg: '20%' }}
                        position={'absolute'}
                        bottom={'0'}
                        left={'50%'}
                        transform={'translate(-50%, 50%)'}
                        fontSize={'xs'}
                        onClick={() => setCurrentStep(currentStep + 1)}
                    >
                        Continue
                    </Button>
            }

        </Card>
    )
}


