import {
    Button,
    Card,
    FormControl,
    Input,
    VStack,
    Text,
    HStack,
    Image,
    Box,
    Stack,
    useDisclosure,
    FormErrorMessage
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { Divider } from '@chakra-ui/react';
// import { useNavigate } from 'react-router-dom';
// import { useFormik } from 'formik';
import { FaRegQuestionCircle } from "react-icons/fa";
import VinNumberM from '../../../models/VinNumber';
import CameraModal from '../../utils/CameraModal';
import { notifyError } from '../../utils/ToastNotifications';

export default function VinNumber({currentStep, setCurrentStep ,formik}) {
    const { isOpen, onOpen, onClose } = useDisclosure();

    // const navigate = useNavigate();
    const [isCameraModalOpen, setIsCameraModalOpen] = useState(false);
    const [capturedValue, setCapturedValue] = useState('');

  console.log('====================================');
  console.log(capturedValue);
  console.log('====================================');


  
    const openCameraModal = () => setIsCameraModalOpen(true);
    const closeCameraModal = () => setIsCameraModalOpen(false);
  
    const handleCapture = (value) => {
      setCapturedValue(value);
      formik.setFieldValue('vin_number',value);
    };
    const handleContinue = async () => {
        const errors = await formik.validateForm();
        formik.setTouched({
            vin_number: true
        });

        if (Object.keys(errors).length === 16) {
            setCurrentStep(currentStep + 1);
        }else if(Object.keys(errors).length === 0){
            setCurrentStep(currentStep + 1);
        }
        else{
            notifyError('VIN Number is Required To Processed!')
        }
    };

    return (
    <>
        <Card
            bg='white' 
            borderRadius={'48px'}
            style={{
                boxShadow: '0px 10px 99px #00000029'
            }}
            py={{base:'8', lg:'6'}}
            px={{ base: '6', md: '12', lg:'16' }}
            w={{base:'80%', md:'90%', lg:'60%'}}
            my={{base:4, md:6}}
            mb={{base:4, md:52, lg:24}}
        >

            <VStack maxW='100%' w='100%' justifyContent='start' alignItems='start'>
                <HStack w={'100%'} justify={'space-between'} mr={4}>
                <Text
                    textAlign={{base:'center', md:'start'}}
                    w={{ base: '100%', md: '80%' }}
                    fontSize={{ base: 'xs', md: 'sm', lg:'md' }}
                    fontWeight={'500'}
                    color={'#153373'}
                    opacity={'0.8'}
                >
                    Enter the VIN Number
                </Text>
               
                <FaRegQuestionCircle 
                cursor={'pointer'}
                onClick={()=>onOpen()} 
                color='#153373' 
                />

                <VinNumberM isOpen={isOpen} onClose={onClose} onOpen={onOpen} />

                </HStack>
                <Divider borderWidth={'1px'} borderColor={'#e3e1e1'} alignItems={'center'} w={'100%'} my={2} />
            </VStack>

            <Stack direction={{base:'column', md:'row'}} spacing={8} my={6} alignItems={'center'}>
             
            <Image src='./scan.png' h={150} onClick={openCameraModal}/>

            <Box bg='gray.200' w='40px' h='40px' display='flex' justifyContent='center' alignItems='center' rounded='50%'>
                <Text color={'#575757'} fontSize={'xs'} fontWeight={600}>
                    Or
                </Text>
            </Box>

            <VStack align={'start'} w={{base:'90%', md:'35%'}}>
                    <Text color={'#707070'} fontSize={'sm'} opacity={'0.8'} fontWeight={'600'}>Enter VIN Number Manually<Text color={'#f33'} as='sup'>*</Text></Text>
                    <Text color={'#707070'} fontSize={'xs'} opacity={'0.8'} fontWeight={'400'}>VIN</Text>
                    <FormControl isInvalid={formik.errors.vin_number && formik.touched.vin_number}>
                        <Input
                            type='text'
                            id='vin_number'
                            name='vin_number'
                            onChange={formik.handleChange}
                            value={formik.values.vin_number}
                            required={true}
                            rounded={26}
                            placeholder='Vehicle Identification Number'
                            fontSize={'xs'}
                            py={4}
                            mb={4}
                            color={'#707070'}
                            sx={{ textTransform: 'uppercase' }}
                        />
                        <FormErrorMessage>{formik.errors.vin_number}</FormErrorMessage>
                    </FormControl>
                </VStack>               
            </Stack>

            <Button
                colorScheme="red"
                py={6}
                rounded={'34px'}
                w={{ base: '60%', md: '30%', lg: '20%' }}
                position={'absolute'}
                bottom={'0'}
                left={'50%'}
                transform={'translate(-50%, 50%)'}
                fontSize={'xs'}
                onClick={() => handleContinue()}
            >
                Continue
            </Button>
        </Card>
        <CameraModal
        isOpen={isCameraModalOpen}
        onRequestClose={closeCameraModal}
        onCapture={handleCapture}
      />
    </>
    )
}

