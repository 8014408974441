import { Card, HStack, Image, SimpleGrid, Stack, Text, VStack } from '@chakra-ui/react';
import React, { useState } from 'react';
import { Divider } from '@chakra-ui/react';
import { BiSolidUser } from "react-icons/bi";
import { IoCarSport } from "react-icons/io5";

export default function UserDetails() {

    return (
        <>
            <Card
                bg='white' borderRadius={'48px'}
                style={{
                    boxShadow: '0px 10px 99px #00000029'
                }}
                p={{ base: '0', md: '8' }}
                maxW='100%'
                mx={{ md: '15%' }}
                mb={{base:0, lg:14}}
            >

                {/* User Details */}
                <VStack 
                maxW='100%' 
                w='100%' 
                justifyContent='center' 
                alignItems='center' 
                px={{base:10, md:0}} 
                >
                    <HStack w={'95%'} justifyContent={'start'} pt={{base:4, md:0}}>
                        <BiSolidUser color='#153373' size={'25px'} />
                        <Text
                            fontSize={{ base: 'sm', md: 'lg' }}
                            fontWeight={'500'}
                            color={'#153373'}
                            opacity={'0.9'}
                            pl={2}
                        >
                            User Details
                        </Text>
                    </HStack>
                    <Divider borderWidth={'1px'} borderColor={'#e3e1e1'} alignItems={'center'} w={'95%'} my={2} />

                    <Stack direction={{base:'column', md:'row'}} w={'95%'} justifyContent={'space-between'}>
                        <VStack spacing={0} alignItems={'start'}>
                            <Text fontSize={'xs'} color={'#707070'} fontWeight={400}>
                              First Name
                            </Text>
                            <Text fontSize={{base:'xs', lg:'sm'}} color={'#000000'} fontWeight={500} opacity={0.8}>
                              John
                            </Text>
                        </VStack>

                        <VStack spacing={0} alignItems={'start'}>
                            <Text fontSize={'xs'} color={'#707070'} fontWeight={400}>
                             Last Name
                            </Text>
                            <Text fontSize={{base:'xs', lg:'sm'}}  color={'#000000'} fontWeight={500} opacity={0.8}>
                             Michell
                            </Text>
                        </VStack>

                        <VStack spacing={0} alignItems={'start'}>
                            <Text fontSize={'xs'} color={'#707070'} fontWeight={400}>
                             Email
                            </Text>
                            <Text fontSize={{base:'xs', lg:'sm'}}  color={'#000000'} fontWeight={500} opacity={0.8}>
                            johndoe@gmail.com
                            </Text>
                        </VStack>

                        <VStack spacing={0} alignItems={'start'}>
                            <Text fontSize={'xs'} color={'#707070'} fontWeight={400}>
                             Mobile Number
                            </Text>
                            <Text fontSize={{base:'xs', lg:'sm'}}  color={'#000000'} fontWeight={500} opacity={0.8}>
                            +1 (123) 456-789
                            </Text>
                        </VStack>
                    </Stack>
                </VStack>
                
                {/* Car Details */}
                <VStack 
                maxW='100%' 
                w='100%' 
                justifyContent='center' 
                alignItems='center' 
                mt={{base:2, md:8, lg:16}}
                px={{base:10, md:0}}
                py={{base:4, md:0}}
                >
                    <HStack w={'95%'} justifyContent={'start'}  pt={{base:4, md:0}}>
                        <IoCarSport color='#153373' size={'25px'} />
                        <Text
                            fontSize={{ base: 'sm', md: 'lg' }}
                            fontWeight={'500'}
                            color={'#153373'}
                            opacity={'0.9'}
                            pl={2} 
                        >
                            Car Details
                        </Text>
                    </HStack>
                    <Divider borderWidth={'1px'} borderColor={'#e3e1e1'} alignItems={'center'} w={'95%'} my={2} />

                    <Stack direction={{base:'column', md:'row'}} w={'95%'} alignItems={'start'} justifyContent={'space-between'} mt={2}>
                        <Image src='./car_details.png' h={{base:100, lg: 120}} />
                        
                        <Stack
                         direction={{base:'column', md:'row'}}
                         mt={2}
                         w={'100%'}
                         justifyContent={'space-between'}
                        >
                         
                        <VStack spacing={0} alignItems={'start'}>
                            <Text fontSize={'xs'} color={'#707070'} fontWeight={400}>
                            Current Miles
                            </Text>
                            <Text fontSize={{base:'xs', lg:'sm'}}  color={'#000000'} fontWeight={500} opacity={0.8}>
                            24 kmpl
                            </Text>
                        </VStack>

                        <VStack spacing={0} alignItems={'start'}>
                            <Text fontSize={'xs'} color={'#707070'} fontWeight={400}>
                             VIN
                            </Text>
                            <Text fontSize={{base:'xs', lg:'sm'}}  color={'#000000'} fontWeight={500} opacity={0.8}>
                            12345678
                            </Text>
                        </VStack>

                        <VStack spacing={0} alignItems={'start'}>
                            <Text fontSize={'xs'} color={'#707070'} fontWeight={400}>
                            Year
                            </Text>
                            <Text fontSize={{base:'xs', lg:'sm'}}  color={'#000000'} fontWeight={500} opacity={0.8}>
                            2006
                            </Text>
                        </VStack>

                        <VStack spacing={0} alignItems={'start'}>
                            <Text fontSize={'xs'} color={'#707070'} fontWeight={400}>
                            Make
                            </Text>
                            <Text fontSize={{base:'xs', lg:'sm'}}  color={'#000000'} fontWeight={500} opacity={0.8}>
                            -
                            </Text>
                        </VStack>

                        <VStack spacing={0} alignItems={'start'}>
                            <Text fontSize={'xs'} color={'#707070'} fontWeight={400}>
                            Model
                            </Text>
                            <Text fontSize={{base:'xs', lg:'sm'}}  color={'#000000'} fontWeight={500} opacity={0.8}>
                            -
                            </Text>
                        </VStack>

                        <VStack spacing={0} alignItems={'start'}>
                            <Text fontSize={'xs'} color={'#707070'} fontWeight={400}>
                            Trim
                            </Text>
                            <Text fontSize={{base:'xs', lg:'sm'}}  color={'#000000'} fontWeight={500} opacity={0.8}>
                            Vroom
                            </Text>
                        </VStack>
                        </Stack>

                        {/* <SimpleGrid
                         columns={{ base: 2, md: 3, lg: 6 }}
                         spacing={{base:4, lg:12}}
                         mt={2}
                         w={'100%'}
                        >
                         
                        <VStack spacing={0} alignItems={'start'}>
                            <Text fontSize={'xs'} color={'#707070'} fontWeight={400}>
                            Current Miles
                            </Text>
                            <Text fontSize={'sm'} color={'#000000'} fontWeight={500} opacity={0.8}>
                            24 kmpl
                            </Text>
                        </VStack>

                        <VStack spacing={0} alignItems={'start'}>
                            <Text fontSize={'xs'} color={'#707070'} fontWeight={400}>
                             VIN
                            </Text>
                            <Text fontSize={'sm'} color={'#000000'} fontWeight={500} opacity={0.8}>
                            12345678
                            </Text>
                        </VStack>

                        <VStack spacing={0} alignItems={'start'}>
                            <Text fontSize={'xs'} color={'#707070'} fontWeight={400}>
                            Year
                            </Text>
                            <Text fontSize={'sm'} color={'#000000'} fontWeight={500} opacity={0.8}>
                            2006
                            </Text>
                        </VStack>

                        <VStack spacing={0} alignItems={'start'}>
                            <Text fontSize={'xs'} color={'#707070'} fontWeight={400}>
                            Make
                            </Text>
                            <Text fontSize={'sm'} color={'#000000'} fontWeight={500} opacity={0.8}>
                            -
                            </Text>
                        </VStack>

                        <VStack spacing={0} alignItems={'start'}>
                            <Text fontSize={'xs'} color={'#707070'} fontWeight={400}>
                            Model
                            </Text>
                            <Text fontSize={'sm'} color={'#000000'} fontWeight={500} opacity={0.8}>
                            -
                            </Text>
                        </VStack>

                        <VStack spacing={0} alignItems={'start'}>
                            <Text fontSize={'xs'} color={'#707070'} fontWeight={400}>
                            Trim
                            </Text>
                            <Text fontSize={'sm'} color={'#000000'} fontWeight={500} opacity={0.8}>
                            Vroom
                            </Text>
                        </VStack>
                        </SimpleGrid> */}
                    </Stack>
                </VStack>
            </Card >
        </>
    )
}


