export const ensureNoSpace = ({value})=>{
 console.log('va',value);
    return value.replace(/ /g,"");
 
}
 
export const ensureString = ({value})=>{
 
    let x=value.match(/[a-zA-Z ]+/g,"");
    return x !== null ? x[0] : "";
 
}
 
export const ensureNumber = ({value,maxLength=null})=>{
 
    let x = value.match(/[0-9]+/g);
    if(x !== null){
 
        if(maxLength !== null){
            x = x[0].substr(0,maxLength);
        }
        else{
            x= x[0];
        }
        
    }
    else{
        x = "";
    }
 
    return x;
 
}

export const validatePass=({value})=>{
    //console.log(value);  
    const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
    let errorMessage="";
    if (!passwordRegex.test(value)) {
    errorMessage =
        "Password must be Alphanumeric with minimum of 8 characters.";
    }
    return{
        success : errorMessage ? false : true,
        message: errorMessage
      }

}

export const isValidPhoneNumber = (phoneNumber) => {
    const regex = /^\d{10}$/;
    return regex.test(phoneNumber);
  };

  export const ensureNameWithoutNumberAndSymbolBoolean = (value) => {
    const isValid = /^[a-zA-Z\s]+$/;
    return isValid.test(value);
}
  export const isValidEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // const regex =/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return regex.test(email);
  };

  export const comparePasswords=(password, confirmPassword)=> {
    return password === confirmPassword;
}

export const ensureMinLength = ({value, minLength}) => {
    return value.length >= minLength;
}

export const ensureMaxLength = ({value, maxLength}) => {
    return value.length <= maxLength;
}

export const ensureAlphaNumericWithoutSpace = ({value}) => {
    let x = value.replace(/ /g,"");  // Remove all spaces
    x = x.match(/^[a-zA-Z0-9]+$/g);  // Ensure only alphanumeric characters
    return x !== null ? x[0] : "";
}

export const ensureNameWithoutNumberAndSymbol = ({value}) => {
    console.log('this is ',value);
    let x = value.replace(/\s/g, ''); 
     x = value.match(/^[a-zA-Z\s]+$/g);  // Allow only letters and spaces

    return x !== null ? x[0] : "";
}



export const ensureNumberWithoutSpace = ({value}) => {
    let x = value.replace(/\s/g, '');  // Remove all spaces
    x = x.match(/^[0-9]+$/g);  // Ensure only numbers
    return x !== null ? x[0] : "";
}

export const errorMessage =({value})=>{
    return <div style={{display:'flex',flexDirection:'row',color:'#ff3333',fontFamily:'serif',fontSize: '1rem'}}>{value ? value : null}</div>
}