import React from 'react';
import {
    Button,
    Checkbox,
    Image,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Table,
    TableContainer,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
} from '@chakra-ui/react';
import { RiMoreLine } from "react-icons/ri";
import { Route, useNavigate } from 'react-router-dom';
import SellaCar from './sellACar/SellaCar';

export default function RequestTradeTable({setIsSupplementalResourses}) {
    const navigate = useNavigate();
    return (
        <TableContainer w={'100%'} maxH={'100vh'} overflowY={'auto'}>
            <Table variant='striped'>
                <Thead >
                    <Tr>
                        <Th w={12}> <Checkbox /> </Th>
                        <Th w={20}></Th>
                        <Th>Request Id</Th>
                        <Th>Request Date</Th>
                        <Th>VIN</Th>
                        <Th>Current Miles</Th>
                        <Th>Year</Th>
                        <Th>Make</Th>
                        <Th>Model</Th>
                        <Th>Price</Th>
                        <Th w={52}></Th>
                    </Tr>
                </Thead>
                <Tbody>
                    <Tr>
                        <Td> <Checkbox defaultChecked></Checkbox> </Td>
                        <Td p={0}> <Image src='./car1.png' w={70} /></Td>
                        <Td fontSize={'xs'} color={'#06A7E2'} onClick={()=>navigate('/car-details')} cursor={'pointer'}>
                            #3456719
                        </Td>
                        <Td fontSize={'xs'}>15-11-2022</Td>
                        <Td fontSize={'xs'}>12345678</Td>
                        <Td fontSize={'xs'}>131,576</Td>
                        <Td fontSize={'xs'}>2006</Td>
                        <Td fontSize={'xs'}>-</Td>
                        <Td fontSize={'xs'}>-</Td>
                        <Td fontSize={'xs'}>$2500</Td>
                        <Td fontSize={'xs'}>
                        <Button
                            rounded={'25px'}
                            w={'100%'}
                            h={10}
                            fontSize={'9px'}
                            color={'#ffffff'}
                            backgroundColor={'#25BC9D'}
                            borderColor={'#25BC9D'}
                            ml={2}
                            onClick={()=> setIsSupplementalResourses(true)}
                        >
                            submit
                        </Button>
                        </Td>
                    </Tr>

                    <Tr>
                        <Td> <Checkbox ></Checkbox> </Td>
                        <Td p={0}> <Image src='./car1.png' w={70} /></Td>
                        <Td fontSize={'xs'} color={'#06A7E2'} onClick={()=>navigate('/car-details')} cursor={'pointer'}>
                            #3456719
                        </Td>
                        <Td fontSize={'xs'}>15-11-2022</Td>
                        <Td fontSize={'xs'}>12345678</Td>
                        <Td fontSize={'xs'}>131,576</Td>
                        <Td fontSize={'xs'}>2006</Td>
                        <Td fontSize={'xs'}>-</Td>
                        <Td fontSize={'xs'}>-</Td>
                        <Td fontSize={'xs'}>$2500</Td>
                        <Td fontSize={'xs'}></Td>
                    </Tr>

                </Tbody>
            </Table>
        </TableContainer>
    )
}

