import {
    Modal,
    ModalOverlay,
    ModalContent,
    Text,
    VStack,
    Flex,
    Image,
    ModalCloseButton,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

export default function UploadPictureInstructions({ isOpen, onClose, onOpen }) {
    const navigate = useNavigate();

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose} isCentered onOpen size={{base:'xs', md:'xl'}}>
                <ModalOverlay />
                <ModalContent>
                
                    <VStack mx={'6'} pt={8}>
                    <ModalCloseButton />

                        <Text 
                        textAlign='center' 
                        w={{ base: '100%', md: '80%' }} 
                        fontSize={{ base: 'md', md: 'xl' }} 
                        fontWeight={'500'} 
                        color={'#153373'} 
                        opacity={'0.8'}
                        pb={2}
                        >
                          Instructions
                        </Text>

                        <VStack w={'100%'} alignItems={'start'}>
                        <Text
                            color={'#707070'}
                            opacity={'0.8'}
                            fontWeight={'400'}
                            fontSize={'xs'}
                            textAlign='start'
                        >
                          1) To upload photo, click on upload {'->'} select the photo {'->'} click on ok
                        </Text>

                        <Text
                            color={'#707070'}
                            opacity={'0.8'}
                            fontWeight={'400'}
                            fontSize={'xs'}
                            textAlign='start'
                        >
                          2) Accepted formats: JPEG, PNG
                        </Text>

                        <Text
                            color={'#707070'}
                            opacity={'0.8'}
                            fontWeight={'400'}
                            fontSize={'xs'}
                            textAlign='start'
                        >
                          3) Max file size: 5MB
                        </Text>

                        <Text
                            color={'#707070'}
                            opacity={'0.8'}
                            fontWeight={'400'}
                            fontSize={'xs'}
                            textAlign='start'
                        >
                          4) Ensure clear and high-resolution images
                        </Text>

                        <Text
                            color={'#707070'}
                            opacity={'0.8'}
                            fontWeight={'400'}
                            fontSize={'xs'}
                            textAlign='start'
                            pb={8}
                        >
                          5) Use a clear naming convention for files
                        </Text>
                        </VStack>
                    </VStack>

                </ModalContent>
            </Modal>
        </>
    )
}

