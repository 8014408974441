import { Flex, HStack, Text } from '@chakra-ui/react'
import React from 'react';
import { IoMdMail } from "react-icons/io";

export default function TopBar() {
    return (
        <Flex
            maxW='100%'
            h={'5%'}
            bg={'#102756'}
            fontSize='12px'
            justifyContent={['center', 'end', 'end', 'end']}
        >
            <HStack
                p={2}
                spacing={6}
                mr={{ md: '12' }}
                color={'#FFFFFF'}
                opacity={'0.7'}
            >
                <HStack>
                    <IoMdMail />
                    <Text>sales@clmpd.com</Text>
                </HStack>
            </HStack>
        </Flex>
    )
}

