import { VStack, Text, SimpleGrid, HStack, Flex, Image, Button, useDisclosure } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { BsArrowUpRightCircle } from "react-icons/bs";
import { useNavigate } from 'react-router-dom';
import UpgradeReport from '../../models/UpgradeReport';
import NeedLoginSignup from '../../models/NeedLoginSignup';
import { useAuth } from '../../providers/AuthProvider';
import ReportDetails from '../../models/ReportDetails';

export default function VehicleRecords({vehicle}) {
    const { logged,user } = useAuth();
    let belongs = false;
    useEffect(()=>{
        
        if (logged && user.id === vehicle.owner_id) {
            belongs = true;
        }
    },[]);

    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isUpgradeOpen, onOpen: onUpgradeOpen, onClose: onUpgradeClose } = useDisclosure();
    const { isOpen: isReportDetailOpen, onOpen: onReportDetailOpen, onClose: onReportDetailClose } = useDisclosure();

    const navigate = useNavigate();

    return (
        <>
            {/* Web View */}
            <Flex
                w={'100%'}
                bg={'#FFFFFF'}
                align={'center'}
                py={2}
                position={'sticky'}
                bottom={0}
                display={['none', 'none', 'none', 'flex']}
            >

                <VStack w={'100%'} px={28}>
                    <HStack w={'100%'} justifyContent={'space-between'}>

                        <HStack>
                            <Text color={'#153373'} fontWeight={600} fontSize={'md'} letterSpacing={2}>
                                Vehicle Records <span style={{ color: '#E22937', fontWeight:'800' }}>${vehicle?.price || '0'}</span>
                            </Text>

                            <HStack
                                cursor={'pointer'}
                                onClick={() => onReportDetailOpen()}
                                alignItems={'start'}
                                spacing={1}
                                pl={4}
                            >
                                <Image src='./star.png' h={21}/>
                                <Text fontWeight={500} fontSize={'xs'}>{vehicle?.vehicleCondition?.totalScore || '0'} Points</Text>
                                <BsArrowUpRightCircle height={4} />
                            </HStack>
                        </HStack>

                        <ReportDetails isOpen={isReportDetailOpen} onClose={onReportDetailClose} onOpen={onReportDetailClose} />

                        {belongs && <HStack>
                            <Button
                                backgroundColor="#E22937"
                                rounded={'22px'}
                                w={'100%'}
                                h={8}
                                fontSize={'9px'}
                                color={'#ffffff'}
                                fontWeight={'400'}
                                onClick={() => {
                                    if (logged) {
                                        navigate('/subscription-plan');
                                    } else {
                                        onOpen()
                                    }

                                }}
                            >
                                Make Offer
                            </Button>

                            <Button
                                backgroundColor="#153373"
                                rounded={'22px'}
                                w={'100%'}
                                h={8}
                                fontSize={'9px'}
                                color={'#ffffff'}
                                fontWeight={'400'}
                                onClick={() => {
                                    if (logged) {
                                        navigate('/request-trade');
                                    } else {
                                        onOpen()
                                    }

                                }}
                            >
                                Request Trade
                            </Button>

                            <Button
                                variant='outline'
                                rounded={'22px'}
                                w={'100%'}
                                h={8}
                                fontSize={'9px'}
                                color={'#153373'}
                                borderColor={'#153373'}
                                fontWeight={'400'}
                                onClick={() => {
                                    if (logged) {
                                        navigate('/get-a-car-loan');
                                    } else {
                                        onOpen()
                                    }

                                }}
                            >
                                Get a Car Loan
                            </Button>

                            <NeedLoginSignup isOpen={isOpen} onClose={onClose} onOpen={onOpen} />

                            <Button
                                variant='outline'
                                rounded={'22px'}
                                w={'100%'}
                                h={8}
                                fontSize={'9px'}
                                color={'#153373'}
                                borderColor={'#153373'}
                                fontWeight={'400'}
                                onClick={() => {
                                    if (logged) {
                                        onUpgradeOpen()
                                    } else {
                                        onOpen()
                                    }
                                }}
                            >
                                Upgrade Report
                            </Button>

                            <UpgradeReport isOpen={isUpgradeOpen} onClose={onUpgradeClose} onOpen={onUpgradeOpen} />

                        </HStack>}


                    </HStack>

                    <SimpleGrid
                        columns={{ base: 1, md: 2, lg: 3 }}
                        w={'100%'}
                        alignContent={'center'}
                        py={1}
                    >
                        <HStack >
                            <Text fontWeight={'700'} fontSize={'11px'}>Theft Record</Text>
                            <HStack spacing={0}>
                                <Image src='./done.png' h={18} />
                                <Text fontSize={'11px'} pl={0} >No theft or theft recovery records are found</Text>
                            </HStack>
                        </HStack>

                        <HStack>
                            <Text fontWeight={700} fontSize={'11px'}>Accident Record</Text>
                            <HStack spacing={0}>
                                <Image src='./done.png' h={18} />
                                <Text fontSize={'11px'} pl={0} >No accident records are found</Text>
                            </HStack>
                        </HStack>

                        <HStack>
                            <Text fontWeight={700} fontSize={'11px'}>Salvage Record</Text>
                            <HStack spacing={0}>
                                <Image src='./done.png' h={18} />
                                <Text fontSize={'11px'} pl={0} >{vehicle?.vehicleCondition?.drivability[3]?.answer || 'No salvage records are found'}</Text>
                            </HStack>
                        </HStack>
                    </SimpleGrid>

                    <Flex w={'100%'} justifyContent={'space-between'} >
                        <Text fontWeight={700} fontSize={'11px'}>Lien / Impound / Export Records</Text>
                        <HStack>
                            <Text fontWeight={500} fontSize={'11px'}>Record Type:</Text>
                            <Text fontSize={'11px'} pl={0} >Lien Record</Text>
                        </HStack>

                        <HStack>
                            <Text fontWeight={500} fontSize={'11px'}>Lien Holder:</Text>
                            <Text fontSize={'11px'} pl={0} >Drive Financial Inc</Text>
                        </HStack>

                        <HStack>
                            <Text fontWeight={500} fontSize={'11px'}>Lien Date:</Text>
                            <Text fontSize={'11px'} pl={0} >2011-08-25</Text>
                        </HStack>

                        <HStack>
                            <Text fontWeight={500} fontSize={'11px'}>VIN:</Text>
                            <Text fontSize={'11px'} pl={0} >{vehicle?.vin || '-'}</Text>
                        </HStack>
                    </Flex>

                </VStack>
            </Flex>

            {/* Mobile View */}
            <Flex
                w={'100%'}
                bg={'#FFFFFF'}
                align={'center'}
                py={2}
                display={['flex', 'flex', 'flex', 'none']}
            >

                <VStack maxW={'100%'} alignItems={"center"} px={6} py={4}>
                    <VStack w={'100%'} justifyContent={'space-between'}>

                        <HStack>
                            <Text color={'#153373'} fontWeight={500} fontSize={'sm'}>
                                Vehicle Records <span style={{ color: '#E22937' }}>${vehicle?.price || '0'}</span>
                            </Text>

                            <HStack
                                cursor={'pointer'}
                                onClick={() => onReportDetailOpen()}
                                alignItems={'start'}
                                spacing={1}
                                pl={2}
                            >
                                {/* <FaStar color='orange' /> */}
                                <Image src='./star.png' h={15}/>
                                <Text fontWeight={400} fontSize={'xs'}>{vehicle?.vehicleCondition?.totalScore || '0'} Points</Text>
                                <BsArrowUpRightCircle height={2} />
                            </HStack>
                        </HStack>

                        <HStack w={'100%'} mx={2} mt={2}>
                            <Button
                                backgroundColor="#E22937"
                                rounded={'22px'}
                                w={'100%'}
                                h={10}
                                fontSize={'9px'}
                                color={'#ffffff'}
                                onClick={() => {
                                    if (logged) {
                                        navigate('/subscription-plan');
                                    } else {
                                        onOpen()
                                    }

                                }}
                            >
                                Make Offer
                            </Button>

                            <Button
                                backgroundColor="#153373"
                                rounded={'22px'}
                                w={'100%'}
                                h={10}
                                fontSize={'9px'}
                                color={'#ffffff'}
                                onClick={() => {
                                    if (logged) {
                                        navigate('/request-trade');
                                    } else {
                                        onOpen()
                                    }

                                }}
                            >
                                Request Trade
                            </Button>
                        </HStack>

                        <HStack w={'100%'} mx={2} mt={2}>
                            <Button
                                variant='outline'
                                rounded={'22px'}
                                w={'100%'}
                                h={10}
                                fontSize={'9px'}
                                color={'#153373'}
                                borderColor={'#153373'}
                                onClick={() => {
                                    if (logged) {
                                        navigate('/get-a-car-loan');
                                    } else {
                                        onOpen()
                                    }

                                }}
                            >
                                Get a Car Loan
                            </Button>

                            <NeedLoginSignup isOpen={isOpen} onClose={onClose} onOpen={onOpen} />

                            <Button
                                variant='outline'
                                rounded={'22px'}
                                w={'100%'}
                                h={10}
                                fontSize={'9px'}
                                color={'#153373'}
                                borderColor={'#153373'}
                                onClick={() => {
                                    if (logged) {
                                        onUpgradeOpen()
                                    } else {
                                        onOpen()
                                    }
                                }}
                            >
                                Upgrade Report
                            </Button>

                            <UpgradeReport isOpen={isUpgradeOpen} onClose={onUpgradeClose} onOpen={onUpgradeOpen} />

                        </HStack>
                    </VStack>

                        <VStack w={'100%'} alignItems={'start'} mt={4}>
                            <Text fontWeight={700} fontSize={'11px'}>Theft Record</Text>
                            <HStack spacing={0}>
                                <Image src='./done.png' h={18} />
                                <Text fontSize={'11px'} pl={0} >No theft or theft recovery records are found</Text>
                            </HStack>
                        </VStack>

                        <VStack w={'100%'} alignItems={'start'} mt={2}>
                            <Text fontWeight={700} fontSize={'11px'}>Accident Record</Text>
                            <HStack spacing={0}>
                                <Image src='./done.png' h={18} />
                                <Text fontSize={'11px'} pl={0} >No accident records are found</Text>
                            </HStack>
                        </VStack>

                        <VStack w={'100%'} alignItems={'start'} mt={2}>
                            <Text fontWeight={700} fontSize={'11px'}>Salvage Record</Text>
                            <HStack spacing={0}>
                                <Image src='./done.png' h={18} />
                                <Text fontSize={'11px'} pl={0} >No salvage records are found</Text>
                            </HStack>
                        </VStack>

                        <Text w={'100%'} mt={'4'} textAlign={'start'} fontWeight={700} fontSize={'11px'}>
                            Lien / Impound / Export Records
                        </Text>
                        <VStack w={'100%'} alignItems={'start'} mt={2}>
                            <Text fontWeight={500} fontSize={'11px'}>Record Type:</Text>
                            <Text fontSize={'11px'} pl={0} >Lien Record</Text>
                        </VStack>

                        <VStack w={'100%'} alignItems={'start'} mt={2}>
                            <Text fontWeight={500} fontSize={'11px'}>Lien Holder:</Text>
                            <Text fontSize={'11px'} pl={0} >Drive Financial Inc</Text>
                        </VStack>

                        <VStack w={'100%'} alignItems={'start'} mt={2}>
                            <Text fontWeight={500} fontSize={'11px'}>Lien Date:</Text>
                            <Text fontSize={'11px'} pl={0} >2011-08-25</Text>
                        </VStack>

                        <VStack w={'100%'} alignItems={'start'} mt={2}>
                            <Text fontWeight={500} fontSize={'11px'}>VIN:</Text>
                            <Text fontSize={'11px'} pl={0} >{vehicle?.vin || '-'}</Text>
                        </VStack>

                </VStack>
            </Flex>
        </>
    )
}
