import { 
    Button,
    Card, 
    Container, 
    Flex, 
    FormControl, 
    HStack, 
    Image, 
    Input, 
    SimpleGrid, 
    Box, 
    Text, 
    VStack, 
    Select, 
    Checkbox
    } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useFormik } from 'formik';
import { FaArrowLeftLong } from "react-icons/fa6";
import { useNavigate } from 'react-router-dom';
import { Divider } from '@chakra-ui/react';
import { useAuth } from '../../providers/AuthProvider';

export default function GetCarLoan() {

    const { logged } = useAuth();

    const navigate = useNavigate();

    const [currentStep, setCurrentStep] = useState(0);

    const formik = useFormik({
        initialValues: {
            fname: "",
            lname: "",
            phone: "",
            email: "",
            password: "",
            confirmPassword: "",
            lamount: "",
            dpamount: "",
            ccscore: ""
        },
        onSubmit: (values) => {
            console.log(values);
        }
    })

    return (
        <>
            {logged ?
                <Card
                    bg='white' borderRadius={'48px'}
                    style={{
                        boxShadow: '0px 10px 99px #00000029'
                    }}
                    p={{ base: '6', md: '8' }}
                    maxW='100%'
                    mx={{ md: '15%' }}
                >

                    <VStack maxW='100%' w='100%' justifyContent='center' alignItems='center'>
                        <Text textAlign='center' w={{ base: '100%', md: '80%' }} fontSize={{ base: 'md', md: 'xl' }} fontWeight={'500'} color={'#153373'} opacity={'0.8'}>
                            Get a Loan For a car
                        </Text>
                        <Divider borderWidth={'1px'} borderColor={'#e3e1e1'} alignItems={'center'} w={'85%'} my={4} />
                    </VStack>

                    <VStack>
                        <SimpleGrid
                            columns={{ base: 1, md: 3 }}
                            spacing={{ base: '6', md: '12' }}
                            w={'85%'}
                            pb={18}
                        >
                            <VStack align={'start'}>
                                <Text color={'#707070'} fontSize={'sm'} opacity={'0.8'} fontWeight={'400'}>Loan Amount</Text>
                                <FormControl>
                                    <Input
                                        type='number'
                                        id='lamount'
                                        name='lamount'
                                        onChange={formik.handleChange}
                                        value={formik.values.lamount}
                                        required={true}
                                        rounded={26}
                                        placeholder='Enter Loan Amount'
                                        fontSize={'xs'}
                                        py={4}
                                    />
                                </FormControl>
                            </VStack>

                            <VStack align={'start'}>
                                <Text color={'#707070'} fontSize={'sm'} opacity={'0.8'} fontWeight={'400'}>Down Payment Amount</Text>
                                <FormControl>
                                    <Input
                                        type='number'
                                        id='dpamount'
                                        name='dpamount'
                                        onChange={formik.handleChange}
                                        value={formik.values.dpamount}
                                        required={true}
                                        rounded={26}
                                        placeholder='Down Payment Amount'
                                        py={4}
                                        fontSize={'xs'}
                                    />
                                </FormControl>
                            </VStack>

                            <VStack align={'start'}>
                                <Text color={'#707070'} opacity={'0.8'} fontSize={'sm'} fontWeight={'400'}>Current Credit Score</Text>
                                <FormControl>
                                    <Select
                                        placeholder='select'
                                        id='dpamount'
                                        name='dpamount'
                                        onChange={formik.handleChange}
                                        value={formik.values.dpamount}
                                        required
                                        rounded={26}
                                        bg={'#fffff'}
                                        fontSize={'xs'}
                                        color={'#707070'}
                                    >
                                        <option value='option1'>1</option>
                                        <option value='option1'>2</option>
                                        <option value='option2'>3</option>
                                        <option value='option3'>4</option>
                                    </Select>
                                </FormControl>
                            </VStack>
                        </SimpleGrid>

                        <HStack
                            bg={'#F2F2F2'}
                            border='2px'
                            borderColor='#adacac'
                            w={{ md: '100%', lg: '25%' }}
                            mb={4}
                            p={4}
                            justifyContent={'space-between'}
                        >
                            <HStack>
                                <Checkbox size='lg' backgroundColor={'white'} borderColor='#adacac' ></Checkbox>
                                <Text
                                    color={'#707070'}
                                    fontSize={'13px'}
                                >
                                    I'm not a robot
                                </Text>
                            </HStack>
                            <Image src='./captcha.png' height={30} />
                        </HStack>

                        <HStack mb={4}>
                            <Checkbox size='sm' backgroundColor={'white'} borderColor='#adacac' ></Checkbox>
                            <Text color={'#707070'} fontSize={'12px'} fontWeight={400}>
                                I accept the
                            </Text>
                            <Text fontSize={'12px'} fontWeight={400} color='#153373'>Terms & Conditions</Text>
                        </HStack>

                    </VStack>

                    <Button
                        onClick={() => {
                            navigate('/all-request')
                        }}
                        colorScheme="red"
                        py={6}
                        rounded={'34px'}
                        w={{ base: '60%', md: '30%', lg: '20%' }}
                        position={'absolute'}
                        bottom={'0'}
                        left={'50%'}
                        transform={'translate(-50%, 50%)'}
                        fontSize={'xs'}
                    >
                        Submit
                    </Button>

                </Card>
                :
                <Card
                    bg='white' borderRadius={'48px'}
                    style={{
                        boxShadow: '0px 10px 99px #00000029'
                    }}
                    p={{ base: '6', md: '8' }}
                    maxW='100%'
                    mx={{ md: '15%' }}
                >
                    <VStack maxW='100%' w='100%' spacing='8' justifyContent='center' alignItems='center'>
                        <HStack
                            w='100%'
                            justifyContent='start'
                        >
                            <HStack w={{ base: '20%', md: '8%' }}>
                                {currentStep < 1 ?
                                    <></>
                                    :
                                    <Box bg='gray.200' w='50px' h='50px' display='flex' justifyContent='center' alignItems='center' rounded='50%'>
                                        <FaArrowLeftLong onClick={() => setCurrentStep(currentStep - 1)} />
                                    </Box>
                                }
                            </HStack>
                            <Text 
                            textAlign={{ base: 'start', md: 'center' }} 
                            w={{base:'100%', md:'80%'}} 
                            fontSize={{base:'md', md:'xl'}} 
                            fontWeight={'500'} 
                            color={'#153373'} 
                            opacity={'0.8'} 
                            pb={6}
                            >
                            Get a Loan For a car
                            </Text>
                        </HStack>
                    </VStack>

                    <HStack maxW='100%' justifyContent='center'>

                        <Flex
                            w={{ md: '100%', lg: '45%' }}
                            bg={'white'}
                            borderRadius={'48px'}
                            justify={'space-between'}
                            style={{
                                boxShadow: '0px 10px 99px #00000029'
                            }}
                        >
                            <HStack p={4}>
                                <Image src={'./check.png'} h={35} />
                                <Text color={'#153373'} fontSize={{ base: '12px' }} fontWeight={'500'} opacity={'0.8'}>Step 1</Text>
                            </HStack>

                            {currentStep === 1 ?
                                <HStack p={4}>
                                    <Image src={'./done_outline.png'} h={45} />
                                    <Text color={'#153373'} fontSize={{ base: '12px' }} fontWeight={'500'} opacity={'0.8'}>Step 2</Text>
                                </HStack>
                                : currentStep === 2 ?
                                    <>
                                        <HStack p={4}>
                                            <Image src={'./check.png'} h={35} />
                                            <Text color={'#153373'} fontSize={{ base: '12px' }} fontWeight={'500'} opacity={'0.8'}>Step 2</Text>
                                        </HStack>
                                    </>
                                    :
                                    <>
                                        <HStack p={4}>
                                            <Container w={8} h={8} borderRadius={50} bg={'#EFEFEF'} ></Container>
                                            <Text color={'#153373'} fontSize={{ base: '12px' }} fontWeight={'500'} opacity={'0.8'}>Step 2</Text>
                                        </HStack>
                                    </>
                            }

                            {currentStep === 2 ?
                                <HStack p={4}>
                                    <Image src={'./done_outline.png'} h={45} />
                                    <Text color={'#153373'} fontSize={{ base: '12px' }} fontWeight={'500'} opacity={'0.8'}>Step 3</Text>
                                </HStack>
                                : <>
                                    <HStack p={4}>
                                        <Container w={8} h={8} borderRadius={50} bg={'#EFEFEF'} ></Container>
                                        <Text color={'#153373'} fontSize={{ base: '12px' }} fontWeight={'500'} opacity={'0.8'}>Step 3</Text>
                                    </HStack>
                                </>}
                        </Flex>
                    </HStack>

                    {
                        currentStep === 0 ?
                            <SimpleGrid
                                columns={{ base: 1, md: 2, lg: 3 }}
                                spacing='8'
                                width={'auto'}
                                justifyContent={'space-evenly'}
                                py={4}
                                mx={{ base: '4', md: 16 }}
                            >
                                <VStack align={'start'}>
                                    <Text fontSize={'sm'} color={'#707070'} opacity={'0.8'} fontWeight={'400'}>First Name</Text>
                                    <FormControl>
                                        <Input
                                            type='text'
                                            id='fname'
                                            name='fname'
                                            onChange={formik.handleChange}
                                            value={formik.values.fname}
                                            required={true}
                                            rounded={26}
                                            placeholder='Your First Name'
                                            py={6}
                                            fontSize={'xs'}
                                        />
                                    </FormControl>
                                </VStack>

                                <VStack align={'start'}>
                                    <Text fontSize={'sm'} color={'#707070'} opacity={'0.8'} fontWeight={'400'}>Last Name</Text>
                                    <FormControl>
                                        <Input
                                            type='text'
                                            id='lname'
                                            name='lname'
                                            onChange={formik.handleChange}
                                            value={formik.values.lname}
                                            required={true}
                                            rounded={26}
                                            placeholder='Your Last Name'
                                            py={6}
                                            fontSize={'xs'}
                                        />
                                    </FormControl>
                                </VStack>

                                <VStack align={'start'}>
                                    <Text fontSize={'sm'} color={'#707070'} opacity={'0.8'} fontWeight={'400'}>Cell Phone Number</Text>
                                    <FormControl>
                                        <Input
                                            type='number'
                                            id='phone'
                                            name='phone'
                                            onChange={formik.handleChange}
                                            value={formik.values.phone}
                                            required={true}
                                            rounded={26}
                                            placeholder='Your Cell Phone Number'
                                            py={6}
                                            fontSize={'xs'}
                                        />
                                    </FormControl>
                                </VStack>

                                <VStack align={'start'}>
                                    <Text fontSize={'sm'} color={'#707070'} opacity={'0.8'} fontWeight={'400'}>Email ID</Text>
                                    <FormControl>
                                        <Input
                                            type='email'
                                            id='email'
                                            name='email'
                                            onChange={formik.handleChange}
                                            value={formik.values.email}
                                            required={true}
                                            rounded={26}
                                            placeholder='Your Email ID'
                                            py={6}
                                            fontSize={'xs'}
                                        />
                                    </FormControl>
                                </VStack>
                            </SimpleGrid>
                            :
                            <></>
                    }

                    {
                        currentStep === 1 ?
                            <SimpleGrid
                                columns={{ base: 1, md: 2 }}
                                spacing='8'
                                width={'auto'}
                                justifyContent={'space-between'}
                                pt={8}
                                pb={18}
                                mx={{ base: '4', md: 16 }}
                            >
                                <VStack align={'start'}>
                                    <Text fontSize={'sm'} color={'#707070'} opacity={'0.8'} fontWeight={'400'}>Create New Password</Text>
                                    <FormControl>
                                        <Input
                                            type='password'
                                            id='password'
                                            name='password'
                                            onChange={formik.handleChange}
                                            value={formik.values.password}
                                            required={true}
                                            rounded={26}
                                            placeholder='Create New Password'
                                            py={6}
                                            fontSize={'xs'}
                                        />
                                    </FormControl>
                                </VStack>

                                <VStack align={'start'}>
                                    <Text fontSize={'sm'} color={'#707070'} opacity={'0.8'} fontWeight={'400'}>Confirm Password</Text>
                                    <FormControl>
                                        <Input
                                            type='password'
                                            id='confirmPassword'
                                            name='confirmPassword'
                                            onChange={formik.handleChange}
                                            value={formik.values.confirmPassword}
                                            required={true}
                                            rounded={26}
                                            placeholder='Confirm New Password'
                                            py={6}
                                            fontSize={'xs'}
                                        />
                                    </FormControl>
                                </VStack>
                            </SimpleGrid>
                            :
                            <></>
                    }

                    {
                        currentStep === 2 ?
                            <VStack>
                                <SimpleGrid
                                    columns={{ base: 1, md: 3 }}
                                    spacing='8'
                                    width={{ base: '100%', md: 'auto' }}
                                    justifyContent={'space-between'}
                                    pt={8}
                                    pb={18}
                                >
                                    <VStack align={'start'}>
                                        <Text fontSize={'sm'} color={'#707070'} opacity={'0.8'} fontWeight={'400'}>Loan Amount</Text>
                                        <FormControl>
                                            <Input
                                                type='number'
                                                id='lamount'
                                                name='lamount'
                                                onChange={formik.handleChange}
                                                value={formik.values.lamount}
                                                required={true}
                                                rounded={26}
                                                placeholder='Enter Loan Amount'
                                                py={4}
                                                fontSize={'xs'}
                                            />
                                        </FormControl>
                                    </VStack>

                                    <VStack align={'start'}>
                                        <Text fontSize={'sm'} color={'#707070'} opacity={'0.8'} fontWeight={'400'}>Down Payment Amount</Text>
                                        <FormControl>
                                            <Input
                                                type='number'
                                                id='dpamount'
                                                name='dpamount'
                                                onChange={formik.handleChange}
                                                value={formik.values.dpamount}
                                                required={true}
                                                rounded={26}
                                                placeholder='Down Payment Amount'
                                                py={4}
                                                fontSize={'xs'}
                                            />
                                        </FormControl>
                                    </VStack>

                                    <VStack align={'start'}>
                                        <Text fontSize={'sm'} color={'#707070'} opacity={'0.8'} fontWeight={'400'}>Current Credit Score</Text>
                                        <FormControl>
                                            <Select
                                                placeholder='select'
                                                id='dpamount'
                                                name='dpamount'
                                                onChange={formik.handleChange}
                                                value={formik.values.dpamount}
                                                required
                                                rounded={26}
                                                bg={'#fffff'}
                                                fontSize={'xs'}
                                            >
                                                <option value='option1'>1</option>
                                                <option value='option1'>2</option>
                                                <option value='option2'>3</option>
                                                <option value='option3'>4</option>
                                            </Select>
                                        </FormControl>
                                    </VStack>
                                </SimpleGrid>

                                <HStack
                                    bg={'#F2F2F2'}
                                    border='2px'
                                    borderColor='#adacac'
                                    w={{ md: '100%', lg: '25%' }}
                                    mb={4}
                                    p={4}
                                    justifyContent={'space-between'}
                                >
                                    <HStack>
                                        <Checkbox size='lg' backgroundColor={'white'} borderColor='#adacac' ></Checkbox>
                                        <Text
                                            color={'#707070'}
                                            fontSize={'13px'}
                                        >
                                            I'm not a robot
                                        </Text>
                                    </HStack>
                                    <Image src='./captcha.png' height={30} />
                                </HStack>

                                <HStack mb={4}>
                                    <Checkbox size='sm' backgroundColor={'white'} borderColor='#adacac' ></Checkbox>
                                    <Text color={'#707070'} fontSize={'12px'} fontWeight={400}>
                                        I accept the
                                    </Text>
                                    <Text fontSize={'12px'} fontWeight={400} color='#153373'>Terms & Conditions</Text>
                                </HStack>

                            </VStack>

                            :
                            <></>
                    }

                    {/* button  */}
                    {
                        currentStep < 1 ?
                            <Button
                                onClick={() => {
                                    setCurrentStep(currentStep + 1);
                                }}
                                colorScheme="red"
                                py={6}
                                rounded={'34px'}
                                w={{ base: '60%', md: '30%', lg: '20%' }}
                                position={'absolute'}
                                bottom={'0'}
                                left={'50%'}
                                transform={'translate(-50%, 50%)'}
                                fontSize={'xs'}
                            >
                                Continue
                            </Button>
                            : currentStep < 2 ?
                                <Button
                                    onClick={() => {
                                        setCurrentStep(currentStep + 1);
                                    }}
                                    colorScheme="red"
                                    py={6}
                                    rounded={'34px'}
                                    w={{ base: '60%', md: '30%', lg: '20%' }}
                                    position={'absolute'}
                                    bottom={'0'}
                                    left={'50%'}
                                    transform={'translate(-50%, 50%)'}
                                    fontSize={'xs'}
                                >
                                    Continue
                                </Button>
                                :

                                <Button
                                    onClick={() => {
                                        navigate('/verificationcode')
                                    }}
                                    colorScheme="red"
                                    py={6}
                                    rounded={'34px'}
                                    w={{ base: '60%', md: '30%', lg: '20%' }}
                                    position={'absolute'}
                                    bottom={'0'}
                                    left={'50%'}
                                    transform={'translate(-50%, 50%)'}
                                    fontSize={'xs'}
                                >
                                    Send OTP
                                </Button>
                    }
                </Card >
            }
        </>
    )
}

