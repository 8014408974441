import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalFooter,
    Button,
    Text,
    VStack,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

export default function AcceptOffer({ isOpen, onClose, onOpen }) {
    const navigate = useNavigate();

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose} isCentered onOpen size={{base:'xs', md:'md'}}>
                <ModalOverlay />
                <ModalContent>
                    <VStack mx={'6'} pt={6}>
                        <Text
                            textAlign='center'
                            w={{ base: '100%', md: '80%' }}
                            fontSize={{ base: 'md', md: 'xl' }}
                            fontWeight={'500'}
                            color={'#153373'}
                            opacity={'0.8'}>
                            Accept Offer
                        </Text>

                        <Text
                            color={'#707070'}
                            opacity={'0.8'}
                            fontWeight={'400'}
                            fontSize={'xs'}
                            pt={2}
                            textAlign='center'
                        >
                            Are you sure you want to accept offer? Once you accept the offer, the car owner details will be shared with the user
                        </Text>
                    </VStack>
                    <ModalFooter mx={'8'} py={6}>
                        <Button
                            variant='outline'
                            rounded={'25px'}
                            w={'100%'}
                            h={12}
                            fontSize={'xs'}
                            color={'#153373'}
                            borderColor={'#153373'}
                            onClick={onClose}
                            mr={2}
                        >
                            Cancel
                        </Button>

                        <Button
                            rounded={'25px'}
                            w={'100%'}
                            h={12}
                            fontSize={'xs'}
                            color={'#ffffff'}
                            backgroundColor={'#25BC9D'}
                            borderColor={'#25BC9D'}
                            ml={2}
                            onClick={onClose}
                        >
                            Accept
                        </Button>

                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}
