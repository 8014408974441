import React from 'react';
import {
    VStack,
    Heading,
    Text,
    Button,
    Checkbox,
    FormControl,
    Input,
    HStack,
    FormErrorMessage,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import * as Yup from 'yup'; // Import Yup
import { Link } from 'react-router-dom';
import { useAuth } from '../../providers/AuthProvider';
import { LoginApi } from '../../Axios/Api';
import { notifyError, notifySuccess } from '../../components/utils/ToastNotifications';
import NotAuthPendingLayout from '../../components/auth/NotAuthPendingLayout';

export default function Login() {
    const { login } = useAuth();

    // Define the validation schema
    const validationSchema = Yup.object({
        email: Yup.string()
            .email('Invalid email address')
            .required('Email is required'),
        password: Yup.string()
            .min(4, 'Password must be at least 8 characters')
            .required('Password is required'),
    });

    const formik = useFormik({
        initialValues: {
            email: "",
            password: "",
            keepMeLoggedIn: false,
        },
        validationSchema: validationSchema, 
        validateOnChange: true,
        validateOnBlur: true,
        onSubmit: async (values) => {
            LoginApi(values).then((response) => {
                notifySuccess(response.message);
                console.log(response);
                const user = response.data.user;
                const token = response.data.token;
                login(user, token);
            }).catch((error) => {
                console.log('====================================');
                console.log(error);
                console.log('====================================');
                notifyError(error.response.data.message);
            });
        }
    });
    return (
        <NotAuthPendingLayout>
            <form onSubmit={formik.handleSubmit}>
                <VStack w='100%' align={'start'} my={6}>
                    <VStack color={'#ffffff'} alignItems={'start'}>
                        <Heading fontSize='2xl' fontWeight={'bold'}>Welcome back,</Heading>
                        <Text fontSize='sm' mb={2} opacity={'0.7'}>Please login to your account</Text>
                    </VStack>
                    <FormControl isInvalid={formik.errors.email && formik.touched.email}>
                        <Input
                            type='text'
                            id='email'
                            name='email'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.email}
                            required={true}
                            rounded={34}
                            bg={'#FFFFFF'}
                            placeholder='Email address'
                            py={6}
                            fontSize={'sm'}
                        />
                        <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
                    </FormControl>

                    <FormControl isInvalid={formik.errors.password && formik.touched.password}>
                        <Input
                            type='password'
                            id='password'
                            name='password'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.password}
                            required={true}
                            rounded={34}
                            bg={'#FFFFFF'}
                            placeholder='Password'
                            py={6}
                            fontSize={'sm'}
                        />
                        <FormErrorMessage>{formik.errors.password}</FormErrorMessage>
                    </FormControl>

                    <HStack m={2} justifyContent={'space-between'}>
                        <HStack fontSize={'sm'} mr={{ md: 4 }}>
                            <Checkbox
                                id='keepMeLoggedIn'
                                name='keepMeLoggedIn'
                                isChecked={formik.values.keepMeLoggedIn} 
                                onChange={(e) => formik.setFieldValue('keepMeLoggedIn', e.target.checked)}
                                bg={'#FFFFFF'}
                                size={'sm'}
                                rounded={'md'}
                            />
                            <Text color={'#FFFFFF'} opacity={'0.7'}>Keep Me logged in.</Text>
                        </HStack>
                        <Link to='/forgotpassword'>
                            <Text ml={{ md: 4 }} fontSize={'sm'} color={'#25BC9D'}>Forgot Password?</Text>
                        </Link>
                    </HStack>

                    <Button type='submit' colorScheme="red" py={6} rounded={'34px'} width="200px">
                        Login
                    </Button>
                    <Link to='/register'>
                        <Text mt={2} color={'#FFFFFF'} align={'right'} opacity={'0.7'} fontSize={'sm'}>
                            Don’t have an account? <span style={{ color: '#25BC9D', fontWeight: '500', paddingLeft: '4px' }}> Signup</span>
                        </Text>
                    </Link>
                </VStack>
            </form>
        </NotAuthPendingLayout>
    );
}
