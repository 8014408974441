import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalCloseButton,
    Button,
    Text,
    useDisclosure,
    VStack,
    HStack,
    Checkbox,
    FormControl,
    Input,
    SimpleGrid,
    Stack,
} from '@chakra-ui/react';
import { Divider } from '@chakra-ui/react';
import { RxQuestionMarkCircled } from "react-icons/rx";
import { useState } from 'react';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';

export default function ManualClose({ isOpen, onClose, onOpen }) {
   const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            card_no: "",
            expiry: "",
            cvc: "",
        },
        onSubmit: (values) => {
            console.log(values);
        }
    })

    const { isOpen: isPaymentOpen , onOpen: onPaymentOpen, onClose: onPaymentClose } = useDisclosure()

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose} isCentered onOpen size={{base:'xs', md:'xl'}}>
                <ModalOverlay />
                <ModalContent>
                    <VStack maxW='100%' w='100%' justifyContent='center' alignItems='start' p={6} mb={6}>
                    <ModalCloseButton />
                        <Text textAlign='start' w={{ base: '100%', md: '80%' }} fontSize={{ base: 'md', md: 'xl' }} fontWeight={'500'} color={'#153373'} opacity={'0.8'}>
                        Upgrade Report
                        </Text>

                        <Divider borderWidth={'1px'} borderColor={'#e3e1e1'} alignItems={'center'} w={'100%'} />

                        <Text
                            color={'#707070'}
                            opacity={'0.8'}
                            fontWeight={'400'}
                            fontSize={'xs'}
                            pt={4}
                        >
                            Select the Report Type
                        </Text>

                        <Stack direction={{base:'column', md:'row'}} spacing={4} mb={{base:2, md:6}}>
                            <VStack spacing={0} pr={4}>
                                <HStack>
                                    <Checkbox size='sm' backgroundColor={'white'} borderColor='#adacac' ></Checkbox>
                                    <Text color={'#000000'} opacity={'0.8'} fontSize={'xs'} fontWeight={400}>
                                        Theft Report
                                    </Text>
                                    <RxQuestionMarkCircled color='#153373' />
                                </HStack>
                                <Text
                                    color={'#707070'}
                                    opacity={'0.8'}
                                    fontWeight={'500'}
                                    fontSize={'xs'}
                                >
                                    $22.00
                                </Text>
                            </VStack>

                            <VStack spacing={0} pr={4}>
                                <HStack>
                                    <Checkbox size='sm' backgroundColor={'white'} borderColor='#adacac' ></Checkbox>
                                    <Text color={'#000000'} opacity={'0.8'} fontSize={'xs'} fontWeight={400}>
                                        Accident Report
                                    </Text>
                                    <RxQuestionMarkCircled color='#153373' />
                                </HStack>
                                <Text
                                    color={'#707070'}
                                    opacity={'0.8'}
                                    fontWeight={'500'}
                                    fontSize={'xs'}
                                >
                                    $23.08
                                </Text>
                            </VStack>

                            <VStack spacing={0}>
                                <HStack>
                                    <Checkbox size='sm' backgroundColor={'white'} borderColor='#adacac' ></Checkbox>
                                    <Text color={'#000000'} opacity={'0.8'} fontSize={'xs'} fontWeight={400}>
                                        Title Report
                                    </Text>
                                    <RxQuestionMarkCircled color='#153373' />
                                </HStack>
                                <Text
                                    color={'#707070'}
                                    opacity={'0.8'}
                                    fontWeight={'500'}
                                    fontSize={'xs'}
                                >
                                    $22.00
                                </Text>
                            </VStack>
                        </Stack>                       
                    </VStack>

                    <Button
                        onClick={onPaymentOpen}
                        colorScheme="red"
                        py={6}
                        mt={6}
                        rounded={'34px'}
                        w={{ base: '80%', md: '40%', lg: '30%' }}
                        fontSize={'xs'}
                        position={'absolute'}
                        bottom={'0'}
                        left={'50%'}
                        transform={'translate(-50%, 50%)'}
                    >
                        Make Payment
                    </Button>

                </ModalContent>
            </Modal>
          
            {/* Make Payment Model */}
            <Modal closeOnOverlayClick={false} isOpen={isPaymentOpen} onClose={onPaymentClose} isCentered size={{base:'xs', md:'xl'}}>
                <ModalOverlay />
                <ModalContent>
                    <VStack maxW='100%' w='100%' justifyContent='center' alignItems='start' p={6} mb={6} mx={{base:0, md:12}}>
                        <ModalCloseButton />
                        <Text textAlign='start' w={{ base: '100%', md: '80%' }} fontSize={{ base: 'md', md: 'xl' }} fontWeight={'500'} color={'#153373'} opacity={'0.8'}>
                            Make Payment
                        </Text>

                        <Divider borderWidth={'1px'} borderColor={'#e3e1e1'} alignItems={'center'} w={'100%'} />

                        <Text color={'#707070'} fontSize={'xs'} opacity={'0.8'} fontWeight={'400'} mt={6}>Card Number</Text>
                        <FormControl>
                            <Input
                                type='number'
                                id='cvc'
                                name='cvc'
                                onChange={formik.handleChange}
                                value={formik.values.cvc}
                                required={true}
                                rounded={26}
                                placeholder='1234 1234 1234 1234'
                                py={2}
                                fontSize={'xs'}
                                w={{md:'80%'}}
                            />
                        </FormControl>

                        <SimpleGrid
                            columns={{ base: 1, md: 2 }}
                            spacing='4'
                            width={'auto'}
                            justifyContent={'space-evenly'}
                            pb={18}
                            pt={4}
                            w={{md:'80%'}}
                        >

                            <VStack align={'start'}>
                                <Text color={'#707070'} fontSize={'xs'} opacity={'0.8'} fontWeight={'400'}>Expiry</Text>
                                <FormControl>
                                    <Input
                                        type='number'
                                        id='expiry'
                                        name='expiry'
                                        onChange={formik.handleChange}
                                        value={formik.values.expiry}
                                        required={true}
                                        rounded={26}
                                        placeholder='MM / YY'
                                        py={2}
                                        fontSize={'xs'}
                                    />
                                </FormControl>
                            </VStack>

                            <VStack align={'start'}>
                                <Text color={'#707070'} fontSize={'xs'} opacity={'0.8'} fontWeight={'400'}>CVC</Text>
                                <FormControl>
                                    <Input
                                        type='number'
                                        id='cvc'
                                        name='cvc'
                                        onChange={formik.handleChange}
                                        value={formik.values.cvc}
                                        required={true}
                                        rounded={26}
                                        placeholder='CVC'
                                        py={2}
                                        fontSize={'xs'}
                                    />
                                </FormControl>
                            </VStack>

                        </SimpleGrid>

                        <HStack w={{base:'100%', md:'80%'}} justify={{base:'start', md:'center'}} mb={8}>
                            <Checkbox size='sm' backgroundColor={'white'} borderColor='#adacac' ></Checkbox>
                            <Text color={'#707070'} fontSize={'12px'} fontWeight={400}>
                                I accept the
                            </Text>
                            <Text fontSize={'12px'} fontWeight={400} color='#153373'>Terms & Conditions</Text>
                        </HStack>

                    </VStack>

                    <Button
                        onClick={() => {
                            navigate('/car-details');
                        }}
                        colorScheme="red"
                        py={6}
                        mt={6}
                        rounded={'34px'}
                        w={{ base: '80%', md: '40%', lg: '30%' }}
                        fontSize={'xs'}
                        position={'absolute'}
                        bottom={'0'}
                        left={'50%'}
                        transform={'translate(-50%, 50%)'}
                    >
                        Pay Now $22
                    </Button>
                </ModalContent>
            </Modal>
        </>
    )
}
