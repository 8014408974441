import React, { useState, useRef, useEffect } from 'react';
import Modal from 'react-modal';
import Webcam from 'react-webcam';
import Tesseract from 'tesseract.js';

Modal.setAppElement('#root'); 

const CameraModal = ({ isOpen, onRequestClose, onCapture }) => {
  const webcamRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (isOpen) {
      navigator.mediaDevices.getUserMedia({ video: true }).catch((err) => {
        console.error('Error accessing camera:', err);
        setError('Failed to access the camera.');
      });
    }
  }, [isOpen]);

  const capture = async () => {
    setLoading(true);
    setError(null);
    const imageSrc = webcamRef.current.getScreenshot();
    if (imageSrc) {
      try {
        const alphanumericValue = await extractAlphanumeric(imageSrc);
        onCapture(alphanumericValue);
        onRequestClose();
      } catch (err) {
        setError('Failed to process the image.');
        console.error('OCR Error:', err);
      }
    } else {
      setError('Failed to capture image.');
    }
    setLoading(false);
  };

  const extractAlphanumeric = async (imageSrc) => {
    try {
      const { data: { text } } = await Tesseract.recognize(
        imageSrc,
        'eng',
        {
          logger: (m) => console.log(m),
        }
      );
      const alphanumericValue = text.replace(/[^a-z0-9]/gi, '');
      return alphanumericValue;
    } catch (error) {
      console.error('Error during OCR processing:', error);
      throw error;
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Camera Modal"
      style={{
        content: {
          width: '600px',
          height: '400px',
          margin: 'auto',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        },
      }}
    >
      <h2>Camera</h2>
      {loading ? (
        <p>Processing...</p>
      ) : (
        <>
          <Webcam
            audio={false}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            width="100%"
            videoConstraints={{
              width: 1280,
              height: 720,
              facingMode: 'user',
            }}
          />
          <button onClick={capture}>Capture</button>
          {error && <p style={{ color: 'red' }}>{error}</p>}
        </>
      )}
      <button onClick={onRequestClose}>Close</button>
    </Modal>
  );
};

export default CameraModal;
