import React from "react";
import { Box, Spinner } from "@chakra-ui/react";

const CustSpinner = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="50vh"
      width="100vw"
      textAlign="center"
      p={3}
    >
      <Spinner
        thickness="6px"
        speed="0.65s"
        emptyColor="gray.200"
        size="xl"
        color="#0635C9"
      />
    </Box>
  );
};

export default CustSpinner;
