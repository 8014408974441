import React from 'react';
import TopBar from '../../components/home/navBar/TopBar';
import NavBar from '../../components/home/navBar/NavBar';
import BreadCrumb from '../../components/home/navBar/BreadCrumb';
import { Image, Text, VStack } from '@chakra-ui/react';
import Footer from '../../components/home/footer/Footer';
import ApplyFilter from '../../components/car/ApplyFilter';

export default function NoCarAvailable() {
    return (
        <>
            <NavBar />
            <BreadCrumb imageUrl={"url('./sell_a_vehicle.png')"} title={'Verified Used Vehicles'} />

            <VStack mx={{base:'6', md:'12', lg:'40'}} py={'8'}>
            <Text 
            w={'100%'}
            textAlign={'start'} 
            fontSize='1xl' 
            color={'#1D1C1A'} 
            opacity={'0.8'} 
            fontWeight={'600'}
            >
            All Cars
            </Text>

            <VStack mb={12}>
              <Image src='./no_car.png' />
              <Text color={'#153373'} fontSize={'sm'} fontWeight={500}>
                OOPS! NO CARS AVAILABLE
               </Text>
               <Text color={'#8C8C8C'} fontSize={'xs'}>
               Sorry! This car is not available right now. We will notify you once it's available.
               </Text>
            </VStack>

            </VStack>

            <Footer />
            <ApplyFilter />
        </>
    )
}

