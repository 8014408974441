import { Axios } from './axios.interceptor';
import Cookies from 'js-cookie';

export const apiRequest = ({ endpoint, method, data = null, params = null, contentType = null }) => {
  const token = Cookies.get('Token') || localStorage.getItem('Token');

  return new Promise((resolve, reject) => {
    (async () => {
      try {
        const response = await Axios({
          url: endpoint,
          method: method,
          data: method === 'get' ? null : data,
          params: (method === 'get' || params) ? params : null,
          headers: {
            Accept: "*/*",
            "Content-Type": contentType === 'json' ? "application/json" : "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        });
        resolve(response);
      } catch (error) {
        reject(error);
      }
    })();
  });
};
