import React, { useState } from 'react';
import NavBar from '../../components/home/navBar/NavBar';
import ButtomBar from '../../components/home/footer/BottomBar';
import SubscriptionPlanCom from '../../components/car/buyACar/SubscriptionPlan';
import { Box, VStack } from '@chakra-ui/react';
import { FaArrowLeftLong } from "react-icons/fa6";
import { useNavigate } from 'react-router-dom';

export default function SubscriptionPlan() {

  const navigate = useNavigate();
  return (
    <>
      <NavBar />

      <VStack
        w={'100%'}
        alignItems={'start'}
        justifyContent={'start'}
        pt={4}
        px={36}
        display={{ base: 'none', md: 'flex' }}
      >
        <Box
          bg='gray.200'
          w='50px'
          h='50px'
          display='flex'
          justifyContent='center'
          alignItems='center'
          rounded='50%'>
          <FaArrowLeftLong onClick={() => navigate(-1)} />
        </Box>
      </VStack>

      <Box minH='73vh' maxW={'100%'} px={{ base: '8', md: '20' }} py={12}>
        <SubscriptionPlanCom />
      </Box>

      <ButtomBar />
    </>
  )
}
