import { Box, Flex, Text, VStack, Image, SimpleGrid, Center, Stack, Button, Container, HStack } from '@chakra-ui/react';
import React from 'react';
import TopBar from '../../components/home/navBar/TopBar';
import NavBar from '../../components/home/navBar/NavBar';
import SellVehicle from '../../components/car/SellVehicle';
import { Divider } from '@chakra-ui/react';
import SubscripeForm from '../../components/SubscribeForm';
import Bottom from '../../components/home/footer/Bottom';
import Footer from '../../components/home/footer/Footer';

export default function Home() {
  return (
    <Box maxW='100%'>
      <NavBar />

      <VStack
        style={{
          backgroundImage: "url('slider_bg.png')",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: 'center center',
          width: '100%'
        }}
      >
        <Container maxW={{base:'90%', md:'80%'}} pt={12}>
          <SellVehicle />
        </Container>

        <Text
          w={{ base: 'auto', md: '25%' }}
          fontSize='2xl'
          fontWeight={'500'}
          align={'center'}
          color={'#FFFFFF'}
          pt={12}
          letterSpacing={1}
        >
          Need to Buy, Sell or Finance a Vehicle?
        </Text>
        <Text align={'center'} color={'#FFFFFF'} opacity={'0.7'} pt={6}  >
          Let one of our Professional Agents help you with your search
        </Text>

        <Box position='relative'
          bottom={{ base: '-60px', md: "-70px" }}
        >
          <Image src='/front_car.png' w={{ base: '80%', md: '60%', lg: '50%' }} margin='0 auto' />
        </Box>
      </VStack>

      <VStack mt='100px'>
        <Text color={'#153373'} fontSize='3xl' fontWeight={'500'} align={'center'} letterSpacing={1}>
          WE OFFER THE FOLLOWING SERVICES
        </Text>
        <Center alignItems={'center'} w={'100%'}>
          <Divider borderWidth={'2px'} borderColor={'red'} w={{ base: '25%', md: '20%', lg: '10%' }} />
        </Center>

        <SimpleGrid
          columns={{ base: 1, md: 2, lg: 3 }}
          w={'auto'}
          alignContent={'center'}
          py={12}
          px={{ base: '8', md: '20', lg: 48 }}
        >
          <VStack w={'auto'} justify={'start'} >
            <Text
              fontSize='2xl'
              fontWeight={'500'}
              w={{ base: 'auto', md: '85%', lg: '65%' }}
              color={'#153373'}
            >
              Purchase Private & Business owned vehicles for Cash
            </Text>
            <Text
              fontSize={'14px'}
              w={{ base: 'auto', md: '85%', lg: '65%' }}
              color={'#707070'}
              opacity={'0.8'}
            >Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s
            </Text>
          </VStack>

          <VStack w={'auto'} justify={'start'}>
            <Text
              fontSize='2xl'
              fontWeight={'500'}
              w={{ base: 'auto', md: '85%', lg: '65%' }}
              color={'#153373'}
            >
              Help Car Buyers purchase cars near wholesale value
            </Text>
            <Text
              fontSize={'14px'}
              w={{ base: 'auto', md: '85%', lg: '65%' }}
              color={'#707070'}
              opacity={'0.8'}
            >Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s
            </Text>
          </VStack>

          <VStack w={'auto'} justify={'start'}>
            <Text
              fontSize='2xl'
              fontWeight={'500'}
              w={{ base: 'auto', md: '85%', lg: '65%' }}
              color={'#153373'}
            >
              Help Car Owners and Buyers find car loans.
            </Text>
            <Text
              fontSize={'14px'}
              w={{ base: 'auto', md: '85%', lg: '65%' }}
              color={'#707070'}
              opacity={'0.8'}
            >Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s
            </Text>
          </VStack>
        </SimpleGrid>

        <VStack px={{ base: '6' }} py={4}>
          <Text
            fontSize={'18px'}
            w={{ base: 'auto', md: '85%', lg: '65%' }}
            color={'#707070'}
            opacity={'0.8'}
            textAlign={'center'}
          >
            We are a small family owned an operated business always looking to improve our services. Please Let us know by calling or emailing us about how we can improve our future service.
          </Text>

          <Text
            fontSize={'18px'}
            w={{ base: 'auto', md: '85%', lg: '65%' }}
            color={'#707070'}
            opacity={'0.8'}
            textAlign={'center'}
          >
            All Vehicle are sold “as-is” with no warranties or guarantees. Vehicle Purchase Price does not include registration, shipping, or taxes. Buyer to pay vehicle registration, shipping, and taxes separately.
          </Text>

          <Text
            fontSize={'18px'}
            w={{ base: 'auto', md: '85%', lg: '65%' }}
            color={'#707070'}
            opacity={'0.8'}
            textAlign={'center'}
          >
            We provide you with a CARFAX.
          </Text>
        </VStack>

      </VStack>

      <Flex
        w={'100%'}
        h={'100%'}
        minH={{ base: '10vh', lg: '75vh' }}
        style={{
          backgroundImage: "url('./back_car.png')",
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center'
        }}
        justifyContent={['end', 'end', 'end', 'end']}
        py={12}
      >

        <VStack w='70%'>
          <Text
            fontSize='2xl'
            fontWeight={'500'}
            color={'#153373'}
            pt={12}
            letterSpacing={1}
            opacity={'0.9'}
            textAlign={'center'}

          >
            HAPPY CUSTOMERS
          </Text>

          <Divider borderWidth={'2px'} borderColor={'red'} w={{ base: '25%', md: '20%', lg: '10%' }} mb={4} />
          <Image src='\quotation_mark.png' h={35} my={2} />
          <Text
            fontSize={'14px'}
            w={{ base: 'auto', md: '85%', lg: '45%' }}
            color={'#707070'}
            opacity={'0.8'}
            px={{ base: '6' }}
          >Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s
          </Text>
          <Text
            fontSize='1xl'
            fontWeight={'500'}
            color={'#153373'}
            pt={2}
            letterSpacing={1}
            opacity={'0.9'}
            textAlign={'center'}

          >
            John Doe
          </Text>
        </VStack>
      </Flex>

      <HStack
        display={{ base: 'none', md: 'flex' }}
        position='relative'
        bottom='120px'
        w='100%'
        spacing='0'
        justifyContent={'center'}

      >
        <Box
          h='400px'
          w='380px'
          style={{
            backgroundImage: "url('card_left.png')",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: 'center center',
          }}
        >

          <VStack justifyContent='center' alignItems='center' spacing='2' py='6' px='5'>
            <Image src='/buy_a_car.png' />
            <Text fontSize='xl' color='white'>Buy a Car</Text>
            <Text
              fontSize='sm'
              color='white'
              opacity={'0.8'}
            >
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s dummy text ever</Text>
          </VStack>

          <Button
            colorScheme="red"
            py={6}
            rounded={'34px'}
            position={'relative'}
            // bottom={'-180px'}
            left='110px'
            fontSize={'xs'}
            w={32}
          >
            Buy Now
          </Button>

        </Box>

        <Box
          h='400px'
          w='380px'
          style={{
            backgroundImage: "url('card_right.png')",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: 'center center',
          }}
        >

          <VStack justifyContent='center' alignItems='center' spacing='2' py='6' px='5'>
            <Image src='/car_loan.png' />
            <Text fontSize='xl' color='white'>Buy a Car</Text>
            <Text
              fontSize='sm'
              color='white'
              opacity={'0.8'}
            >
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s dummy text ever</Text>
          </VStack>
          <Button
            colorScheme="red"
            py={6}
            rounded={'34px'}
            position={'relative'}
            // bottom={'-0px'}
            left='115px'
            fontSize={'xs'}
            w={32}
          >
            Get a Loan
          </Button>
        </Box>
      </HStack>

      <SubscripeForm />
      <Bottom />
      <Footer />
    </Box>
  )
}
