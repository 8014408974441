import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../providers/AuthProvider';
import { BackgroundAuthApi } from '../../Axios/Api';

export default function AuthenticatedLayout({ children }) {
    const { logged, user, login } = useAuth();
    const navigate = useNavigate();
    const [proceed, setProceed] = useState(false);
    const tkn = Cookies.get("Token");

    useEffect(() => {
        if (!tkn) {
            navigate('/login');
            return;
        }

        BackgroundAuthApi().then((response) => {
            const userData = response.data;
            login(userData, tkn);
        }).catch((error) => {
            console.error('Error during authentication:', error.response?.data?.message);
        });

        if (logged) {
            if (user.account_status === 1) {
                setProceed(true);
            } else if (user.account_status === 0) {
                navigate('/verificationcode');
            } else {
                navigate('/login');
            }
        }
    }, []);

    return (
        <>
            {proceed && logged && (
                <>
                    {children}
                </>
            )}
        </>
    );
}
