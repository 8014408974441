import React from 'react';
import { HStack, Image, SimpleGrid, Stack, Text, VStack } from '@chakra-ui/react';
import { GiShare } from "react-icons/gi";
import { FaFacebookF } from "react-icons/fa6";
import { FiInstagram } from "react-icons/fi";
import { FaPinterestP } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa6";
import { IoLogoYoutube } from "react-icons/io";
import { Link } from 'react-router-dom';

export default function Footer() {
    return (
        <VStack
            maxW='100%'
            bg={'#102756'}
            fontSize='12px'
            py={8}
        >
            <Image src='./auth_logo.png' h={120} />
            <Stack
                flexDirection={{ base: 'column', md: 'row' }}
                alignItems={'center'}
                py={4}
                mt={2}
                spacing={4}
                color={'#FFFFFF'}
                opacity={'0.7'}
                maxW={'100%'}
                justify={'center'}
            >
                <HStack spacing={1}>
                    <GiShare />
                    <Text>Share</Text>
                </HStack>
                <HStack spacing={1}>
                    <FaFacebookF />
                    <Text>facebook</Text>
                </HStack>
                <HStack spacing={1}>
                    <FiInstagram />
                    <Text>Instagram</Text>
                </HStack>
                <HStack spacing={1}>
                    <FaPinterestP />
                    <Text>pinterest</Text>
                </HStack>
                <HStack spacing={1}>
                    <FaTwitter />
                    <Text>twitter</Text>
                </HStack>
                <HStack spacing={1}>
                    <IoLogoYoutube />
                    <Text>youtube</Text>
                </HStack>
            </Stack>

            <HStack color={'#FFFFFF'} spacing={6} py={4}>
                <Link to={'/buy-a-car'}>
                    <Text opacity={'0.8'} _hover={{ color: '#25BC9D' }}>Buy a Car</Text>
                </Link>
                <Text opacity={'0.8'} _hover={{ color: '#25BC9D' }}>Get a Car Loan</Text>
                <Text opacity={'0.8'} _hover={{ color: '#25BC9D' }}>Sell a Car</Text>
            </HStack>

            <Text py={2} color={'#FFFFFF'} opacity={'0.8'}>©2022 by Cars for Sale & Loans Marketplace.</Text>
        </VStack>
    )
}
