import { 
    Button, 
    Card, 
    Container, 
    Flex, 
    FormControl, 
    HStack, 
    Image, 
    Input, 
    SimpleGrid, 
    Box, 
    Text, 
    VStack, 
    FormErrorMessage
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { useFormik } from 'formik';
import { FaArrowLeftLong } from "react-icons/fa6";
import { useNavigate } from 'react-router-dom';
import { comparePasswords,  ensureNameWithoutNumberAndSymbolBoolean, isValidEmail, isValidPhoneNumber } from '../utils/inputValidation';
import { SignUp } from '../../Axios/Api';
import { notifyError, notifySuccess } from '../utils/ToastNotifications';

export default function SellVehicle() {

    const [currentStep, setCurrentStep] = useState(0);

    const navigate = useNavigate();
    const handleSubmit =(values)=>{
        SignUp(values)
        .then((response)=>{
            notifySuccess(response.message);
            navigate('/verificationcode', { state: { refId: response.data.id ,email:values.email,vrf:1} });
        }).catch((error)=>{
            notifyError(error.response.data.message)
        })

    }
    const formik = useFormik({
        initialValues: {
            fname: "",
            lname: "",
            phone: "",
            email: "",
            city:"",
            state:"",
            password: "",
            confirmPassword: ""
        },
        onSubmit:values=>handleSubmit(values),
        validateOnChange: true,
        validateOnBlur:true,
        validate: values =>{
            let errors ={} 
            if (!values.fname.trim() || !ensureNameWithoutNumberAndSymbolBoolean(values?.fname)) {
                errors.fname = "*First Name is required";
              }
            if (!values.lname.trim() || !ensureNameWithoutNumberAndSymbolBoolean(values?.lname)) {
                errors.lname = "*Last Name is required";
              }
            if (!values.email.trim()) {
                errors.email = "*Email is required";

              } else if (!isValidEmail(values.email)) {
                errors.email = "*Invalid email address";
              }
            if (!isValidPhoneNumber(values.phone)) {
                errors.phone = "*Phone Number must be 10 digits long";
              }
              if (!values.password.trim()) {
                errors.password = "*Password is required";

              }
              if (!values.city.trim()) {
                errors.city = "*City is required";

              }
              if (!values.state.trim()) {
                errors.state = "*State is required";

              }
              if (!values.confirmPassword.trim()) {
                errors.confirmPassword = "*Confirm Password is required";
              }
              if(!comparePasswords(values.password,values.confirmPassword)){
                errors.password = "Password Does Not Matched" ;
                errors.confirmPassword = "Password Does Not Matched" ;
              }
              return errors;
            }
        })
        
       

    const handleContinue = async () => {
        const errors = await formik.validateForm();
        formik.setTouched({
            fname: true,
            lname: true,
            phone: true,
            email: true,
            city:true,
            state:true
        });

        if (Object.keys(errors).length === 2) {
            setCurrentStep(currentStep + 1);
        }else if (
            Object.keys(errors).length === 0
        ){
            setCurrentStep(currentStep + 1);
        }else{
            notifyError('Fill the All Required Fields!')
        }
        
    };


    return (
        <Card
            bg='white' borderRadius={'48px'}
            style={{
                boxShadow: '0px 10px 99px #00000029'
            }}
            p={{ base: '4', md: '8' }}
            maxW={'100%'}
            mx={{ md: '10%' }}
        >
            <VStack maxW='100%' spacing='8' justifyContent='center' alignItems='center'>
                <HStack
                    w='100%'
                    justifyContent='start'
                >
                    <HStack w={{ base: '20%', md: '8%' }}>
                        {currentStep < 1 ?
                            <></>
                            :
                            <Box bg='gray.200' w='40px' h='40px' display='flex' justifyContent='center' alignItems='center' rounded='50%'>
                                <FaArrowLeftLong onClick={() => setCurrentStep(currentStep - 1)} />
                            </Box>
                        }
                    </HStack>
                    <Text textAlign={{ base: 'start', md: 'center' }} w='80%' fontSize='xl' fontWeight={'500'} color={'#153373'} opacity={'0.8'} pb={4}>
                        Sell a Vehicle
                    </Text>
                </HStack>
            </VStack>

            <HStack maxW='100%' justifyContent='center'>

                <Flex
                    w={{ md: '90%', lg: '35%' }}
                    bg={'white'}
                    borderRadius={'48px'}
                    justify={'space-between'}
                    style={{
                        boxShadow: '0px 10px 99px #00000029'
                    }}
                    fontSize={{ base: 'xs' }}
                >
                    <HStack p={4}>
                        <Image src={'./check.png'} h={35} />
                        <Text color={'#153373'} fontWeight={'500'} opacity={'0.8'}>Step 1</Text>
                    </HStack>

                    {currentStep === 1 ?
                        <HStack p={4}>
                            <Image src={'./done_outline.png'} h={45} />
                            <Text color={'#153373'} fontWeight={'500'} opacity={'0.8'}>Step 2</Text>
                        </HStack>
                        : <>
                            <HStack p={4}>
                                <Container w={8} h={8} borderRadius={50} bg={'#EFEFEF'} ></Container>
                                <Text color={'#153373'} fontWeight={'500'} opacity={'0.8'}>Step 2</Text>
                            </HStack>
                        </>}
                </Flex>
            </HStack>

            {
                currentStep === 0 ?
                    <SimpleGrid
                        columns={{ base: 1, md: 2, lg: 3 }}
                        spacing='6'
                        justifyContent={'space-around'}
                        py={4}
                        px={{ base: 6, md: 22 }}
                        w={'100%'}
                    >
                        <VStack align={'start'} >
                            <Text fontSize={'sm'} color={'#707070'} opacity={'0.8'} fontWeight={'400'}>First Name</Text>
                            
                            <FormControl isInvalid={formik.errors.fname && formik.touched.fname}>
                                <Input
                                    type='text'
                                    id='fname'
                                    name='fname'
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.fname}
                                    required={true}
                                    rounded={26}
                                    placeholder='Your First Name'
                                    py={6}
                                    fontSize={'xs'}
                                />
                                  <FormErrorMessage>{formik.errors.fname}</FormErrorMessage>
                            </FormControl>
                        </VStack>

                        <VStack align={'start'}>
                            <Text fontSize={'sm'} color={'#707070'} opacity={'0.8'} fontWeight={'400'}>Last Name</Text>
                            <FormControl isInvalid={formik.errors.lname  && formik.touched.lname}>
                                <Input
                                    type='text'
                                    id='lname'
                                    name='lname'
                                    onChange={formik.handleChange}
                                    value={formik.values.lname}
                                    required={true}
                                    rounded={26}
                                    placeholder='Your Last Name'
                                    py={6}
                                    fontSize={'xs'}
                                />
                                  <FormErrorMessage>{formik.errors.lname}</FormErrorMessage>
                            </FormControl>
                        </VStack>

                        <VStack align={'start'}>
                            <Text fontSize={'sm'} color={'#707070'} opacity={'0.8'} fontWeight={'400'}>Cell Phone Number</Text>
                            <FormControl isInvalid={formik.errors.phone && formik.touched.phone}>
                                <Input
                                    type='number'
                                    id='phone'
                                    name='phone'
                                    onChange={formik.handleChange}
                                    value={formik.values.phone}
                                    required={true}
                                    rounded={26}
                                    placeholder='Your Cell Phone Number'
                                    py={6}
                                    fontSize={'xs'}
                                    maxLength={10}
                                />
                                  <FormErrorMessage>{formik.errors.phone}</FormErrorMessage>
                            </FormControl>
                        </VStack>

                        <VStack align={'start'}>
                            <Text fontSize={'sm'} color={'#707070'} opacity={'0.8'} fontWeight={'400'}>Email ID</Text>
                            <FormControl isInvalid={formik.errors.email && formik.touched.email}>
                                <Input
                                    type='email'
                                    id='email'
                                    name='email'
                                    onChange={formik.handleChange}
                                    value={formik.values.email}
                                    required={true}
                                    rounded={26}
                                    placeholder='Your Email ID'
                                    py={6}
                                    fontSize={'xs'}
                                />
                                  <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
                            </FormControl>
                        </VStack>
                        <VStack align={'start'}>
                            <Text fontSize={'sm'} color={'#707070'} opacity={'0.8'} fontWeight={'400'}>City</Text>
                            <FormControl isInvalid={formik.errors.city && formik.touched.city}>
                                <Input
                                    type='text'
                                    id='city'
                                    name='city'
                                    onChange={formik.handleChange}
                                    value={formik.values.city}
                                    required={true}
                                    rounded={26}
                                    placeholder='Your city'
                                    py={6}
                                    fontSize={'xs'}
                                />
                                  <FormErrorMessage>{formik.errors.city}</FormErrorMessage>
                            </FormControl>
                        </VStack>
                        <VStack align={'start'}>
                            <Text fontSize={'sm'} color={'#707070'} opacity={'0.8'} fontWeight={'400'}>State</Text>
                            <FormControl isInvalid={formik.errors.state && formik.touched.state}>
                                <Input
                                    type='text'
                                    id='state'
                                    name='state'
                                    onChange={formik.handleChange}
                                    value={formik.values.state}
                                    required={true}
                                    rounded={26}
                                    placeholder='Your State'
                                    py={6}
                                    fontSize={'xs'}
                                />
                                  <FormErrorMessage>{formik.errors.state}</FormErrorMessage>
                            </FormControl>
                        </VStack>
                    </SimpleGrid>
                    :
                    <></>
            }
            {
                currentStep === 1 ?
                    <SimpleGrid
                        columns={{ base: 1, md: 2 }}
                        spacing='12'
                        maxW={'100%'}
                        justifyContent={'space-evenly'}
                        pt={8}
                        pb={14}
                        mx={{ base: 0, md: 12 }}
                    >
                        <VStack align={'start'}>
                            <Text fontSize={'sm'} color={'#707070'} opacity={'0.8'} fontWeight={'400'}>Create New Password</Text>
                            <FormControl isInvalid={formik.errors.password && formik.touched.password}>
                                <Input
                                    type='password'
                                    id='password'
                                    name='password'
                                    onChange={formik.handleChange}
                                    value={formik.values.password}
                                    required={true}
                                    rounded={26}
                                    placeholder='Create New Password'
                                    py={6}
                                    fontSize={'xs'}
                                />
                                  <FormErrorMessage>{formik.errors.password}</FormErrorMessage>
                            </FormControl>
                        </VStack>

                        <VStack align={'start'}>
                            <Text fontSize={'sm'} color={'#707070'} opacity={'0.8'} fontWeight={'400'}>Confirm Password</Text>
                            <FormControl isInvalid={formik.errors.confirmPassword && formik.touched.confirmPassword}>
                                <Input
                                    type='password'
                                    id='confirmPassword'
                                    name='confirmPassword'
                                    onChange={formik.handleChange}
                                    value={formik.values.confirmPassword}
                                    required={true}
                                    rounded={26}
                                    placeholder='Confirm New Password'
                                    py={6}
                                    fontSize={'xs'}
                                />
                            <FormErrorMessage>{formik.errors.confirmPassword}</FormErrorMessage>
                            </FormControl>
                        </VStack>
                    </SimpleGrid>
                    :
                    <></>
            }

            {/* button  */}
            {
                currentStep < 1 ?
                    <Button
                       onClick={handleContinue}
                        colorScheme="red"
                        py={6}
                        rounded={'34px'}
                        w={{ base: '60%', md: '30%', lg: '20%' }}
                        position={'absolute'}
                        bottom={'0'}
                        left={'50%'}
                        transform={'translate(-50%, 50%)'}
                        fontSize={'xs'}
                    >
                        Continue
                    </Button>
                    :
                    <Button
                        onClick={() => {
                            formik.handleSubmit();
                            
                        }}
                        colorScheme="red"
                        py={6}
                        rounded={'34px'}
                        w={{ base: '60%', md: '30%', lg: '20%' }}
                        position={'absolute'}
                        bottom={'0'}
                        left={'50%'}
                        transform={'translate(-50%, 50%)'}
                        fontSize={'xs'}
                    >
                        Send OTP
                    </Button>
            }
        </Card >
    )
}
