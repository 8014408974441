import { Button, Flex, FormControl, Input, SimpleGrid, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import { useFormik } from 'formik';
import { Divider } from '@chakra-ui/react';

export default function SubscribeForm() {

    const formik = useFormik({
        initialValues: {
            fname: "",
            phone: "",
            email: ""
        },
        onSubmit: (values) => {
            console.log(values);
        }
    })

    return (
        <VStack
            w={'100%'}
            bg={'#ECF0F3'}
            align={'center'}
            py='8'
        >
            <Flex>
                <VStack >
                    <Text
                        fontSize='2xl'
                        fontWeight={'500'}
                        color={'#153373'}
                        pt={4}
                        letterSpacing={1}
                        opacity={'0.9'}
                        align={'center'}
                    >
                        CARS FOR SALE & LOANS MARKETPLACE
                    </Text>

                    <Divider borderWidth={'2px'} borderColor={'red'} w={{ base: '25%', md: '20%', lg: '10%' }} mb={4} />

                    <Text
                        fontSize='1xl'
                        fontWeight={'700'}
                        color={'#153373'}
                        letterSpacing={1}
                        opacity={'0.7'}
                        mb={4}
                    >
                        Subscribe Form
                    </Text>

                    <SimpleGrid
                        columns={{ base: 1, md: 2, lg: 3 }}
                        spacing='4'
                        width={'100%'}
                        justifyContent={'center'}
                        px={{ base: '8', md: '12', lg: '32' }}>

                        <VStack align={'start'}>
                            <Text fontSize={'xs'} color={'#707070'} opacity={'0.8'} fontWeight={'400'}>First Name</Text>
                            <FormControl>
                                <Input
                                    type='text'
                                    id='fname'
                                    name='fname'
                                    onChange={formik.handleChange}
                                    value={formik.values.fname}
                                    required={true}
                                    rounded={26}
                                    placeholder='Your First Name'
                                    bg={'#fffff'}
                                    py={6}
                                    fontSize={'xs'}
                                />
                            </FormControl>
                        </VStack>

                        <VStack align={'start'}>
                            <Text fontSize={'xs'} color={'#707070'} opacity={'0.8'} fontWeight={'400'}>Cell Phone Number</Text>
                            <FormControl>
                                <Input
                                    type='number'
                                    id='phone'
                                    name='phone'
                                    onChange={formik.handleChange}
                                    value={formik.values.phone}
                                    required={true}
                                    rounded={26}
                                    placeholder='Your Cell Phone No.'
                                    bg={'#fffff'}
                                    py={6}
                                    fontSize={'xs'}
                                />
                            </FormControl>
                        </VStack>

                        <VStack align={'start'}>
                            <Text fontSize={'xs'} color={'#707070'} opacity={'0.8'} fontWeight={'400'}>Email</Text>
                            <FormControl>
                                <Input
                                    type='email'
                                    id='email'
                                    name='email'
                                    onChange={formik.handleChange}
                                    value={formik.values.email}
                                    required={true}
                                    rounded={26}
                                    placeholder='Your Email ID'
                                    bg={'#fffff'}
                                    py={6}
                                    fontSize={'xs'}
                                />
                            </FormControl>
                        </VStack>
                    </SimpleGrid>

                    <Button
                        type='submit'
                        colorScheme="red"
                        my={2}
                        mb={12}
                        rounded={'34px'}
                        w={{ base: '80%', md: '30%', lg: '20%' }}
                        fontSize={'xs'}
                    >
                        Submit
                    </Button>

                </VStack>
            </Flex>
        </VStack>
    )
}

