import { HStack, Image, Stack, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import { IoMdMail } from "react-icons/io";
import { FaMobileAlt } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";

export default function Bottom() {
    return (
        <VStack
            maxW='100%'
            bg={'#153373'}
            fontSize='12px'
            color={'#FFFFFF'}
        >
                <HStack opacity={'0.7'} pt={6}>
                    <IoMdMail />
                    <Text fontSize='md'>sales@clmpd.com</Text>
                </HStack>
            <HStack
                opacity={'0.7'}
                width={'100%'}
                justify={'center'}
                pb={6}
                pt={2}
            >
                <FaLocationDot />
                <Text>Anaheim Hills, California 92807</Text>
            </HStack>
        </VStack>
    )
}
