import { Button, HStack, SimpleGrid, Stack, Switch, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import { Divider } from '@chakra-ui/react';

export default function SubscriptionPlanDetails() {
  return (
    <>
     <VStack  p={4} w={'100%'} alignItems={'start'} bg={'#FBFBFB'} mb={8}>
        <HStack w={{base:'100%', md:'75%'}} alignItems={'flex-end'} justifyContent={'space-between'} mb={2}>
        <Text color={'#575757'} fontSize={{base:'10px', md:'sm'}} fontWeight={600} pb={4}>
         Subscription Plan Details
        </Text>

        <Button
            variant='outline' 
            color={'#E22937'}
            borderColor={'#E22937'}
            py={{base:2, md:4}}
            rounded={'22px'}
            w={120} 
            h={10}
            fontSize={{base:'10px', md:'xs'}}
            style={{
                boxShadow: '0px 10px 20px #00000029'
            }}
            opacity={'0.8'}
        >
            Cancel Plan
        </Button>
        </HStack>
        <Divider borderWidth={'1px'} borderColor={'#EDECF1'} alignItems={'center'} w={'100%'} />

            <SimpleGrid
            columns={{ base: 1, md: 2, lg: 3 }}
            spacing={{base:6, md:0}}
            py={4}
            w={{ base: '100%', md: '85%', lg: '65%' }}
            >
             <VStack alignItems={'start'}>
                <Text fontSize={'xs'} fontWeight={400}>
                Subscription Plan
                </Text>
                <Text fontSize={'xs'} color={'#707070'}>
                Plan : $1/month
                </Text>
             </VStack>

             <VStack alignItems={'start'}>
                <Text fontSize={'xs'} fontWeight={400}>
                Plan Expires On
                </Text>
                <Text fontSize={'xs'} color={'#707070'}>
                Sept 30, 2023
                </Text>
             </VStack>

            <HStack w={'100%'}>
            <VStack alignItems={'start'} w={'100%'}>
                <Text fontSize={'xs'} fontWeight={400}>
                Auto Renewal
                </Text>
                <Text fontSize={'xs'} color={'#707070'}>
                Currently your plan is on auto renewal, you can toggle it off
                </Text>
             </VStack>
             <Switch size='lg' isChecked />
            </HStack>

            </SimpleGrid>

     </VStack>
    </>
  )
}
