import {
    Button,
    Card,
    FormControl,
    HStack,
    Input,
    SimpleGrid,
    Text,
    VStack,
    Checkbox,
    Radio,
    RadioGroup,
    Stack,
    Switch,
    useDisclosure
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { Divider } from '@chakra-ui/react';
import PaymentSuccessful from '../../../models/PaymentSuccessful';

export default function SubscriptionPlan() {

    const navigate = useNavigate();

    const { isOpen, onOpen, onClose } = useDisclosure();

    const [plan, setPlan] = useState('free');
    const [isPlanOn, setIsPlanOn] = useState(true);

    const handlePlanChange = (nextPlan) => {
        setPlan(nextPlan);
    };

    const formik = useFormik({
        initialValues: {
            card_no: "",
            expiry: "",
            cvc: "",
        },
        onSubmit: (values) => {
            console.log(values);
        }
    })

    return (
        <Card
            bg='white' borderRadius={'48px'}
            style={{
                boxShadow: '0px 10px 99px #00000029'
            }}
            p={{ base: '6', md: '8' }}
            maxW='100%'
            mx={{ md: '20%' }}
        >

            <VStack maxW='100%' w='100%' justifyContent='center' alignItems='center' px={12}>
                <Text
                    textAlign='start'
                    w={'100%'}
                    fontSize={{ base: 'md', md: 'xl' }}
                    fontWeight={'500'}
                    color={'#153373'}
                    opacity={'0.8'}
                >
                    Subscription Plan
                </Text>
                <Divider borderWidth={'1px'} borderColor={'#e3e1e1'} alignItems={'center'} w={'100%'} mt={2} />
            </VStack>

            <VStack mb={{ base: 4, md: 2 }}>
                <VStack align={'start'}>
                    <RadioGroup value={plan} onChange={handlePlanChange} py={4}>
                        <Stack direction={{ base: 'column', md: 'row' }} >
                            <Radio
                                pr={4}
                                size='md'
                                colorScheme='red'
                                value="free"
                            >
                                <Text fontSize={'xs'} color={plan === 'free' ? '#E22937' : '#C0C0C0'}>
                                    Free Trial (60 Days)
                                </Text>
                            </Radio>

                            <Radio
                                size='md'
                                colorScheme='red'
                                value="subscription"
                            >
                                <Text fontSize={'xs'} color={plan === 'free' ? '#C0C0C0' : '#E22937'}>
                                    Buy Subscription (30 Days) </Text>
                            </Radio>
                        </Stack>
                    </RadioGroup>

                    <VStack w={'100%'} alignItems={'start'}>
                        <Text color={'#707070'} fontSize={'xs'} opacity={'0.8'} fontWeight={'400'}>Card Number</Text>
                        <FormControl>
                            <Input
                                type='number'
                                id='cvc'
                                name='cvc'
                                onChange={formik.handleChange}
                                value={formik.values.cvc}
                                required={true}
                                rounded={26}
                                placeholder='1234 1234 1234 1234'
                                py={2}
                                fontSize={'xs'}
                            />
                        </FormControl>
                    </VStack>

                    <SimpleGrid
                        columns={{ base: 1, md: 2 }}
                        spacing={{ base: '2', md: '4' }}
                        // width={{ base: '100%', md: '68%' }}
                        w={'100%'}
                        pb={4}
                        pt={{ base: 2, md: 4 }}
                    >

                        <VStack align={'start'}>
                            <Text color={'#707070'} fontSize={'xs'} opacity={'0.8'} fontWeight={'400'}>Expiry</Text>
                            <FormControl>
                                <Input
                                    type='number'
                                    id='expiry'
                                    name='expiry'
                                    onChange={formik.handleChange}
                                    value={formik.values.expiry}
                                    required={true}
                                    rounded={26}
                                    placeholder='MM / YY'
                                    py={2}
                                    fontSize={'xs'}
                                />
                            </FormControl>
                        </VStack>

                        <VStack align={'start'}>
                            <Text color={'#707070'} fontSize={'xs'} opacity={'0.8'} fontWeight={'400'}>CVC</Text>
                            <FormControl>
                                <Input
                                    type='number'
                                    id='cvc'
                                    name='cvc'
                                    onChange={formik.handleChange}
                                    value={formik.values.cvc}
                                    required={true}
                                    rounded={26}
                                    placeholder='CVC'
                                    py={2}
                                    fontSize={'xs'}
                                />
                            </FormControl>
                        </VStack>

                    </SimpleGrid>

                    {plan === 'free' ?
                        <HStack mb={4} w={'100%'} justifyContent={'center'} >
                            <Checkbox size='sm' backgroundColor={'white'} borderColor='#adacac' ></Checkbox>
                            <Text color={'#707070'} fontSize={'12px'} fontWeight={400}>
                                I accept the
                            </Text>
                            <Text fontSize={'12px'} fontWeight={400} color='#153373'>Terms & Conditions</Text>
                        </HStack>
                        :
                        <HStack mb={2}>
                            <VStack w={'100%'} alignItems={'start'} mr={2}>
                                <Text opacity={'0.9'} fontSize={'xs'}>Auto Renewal</Text>
                                <Text
                                    fontSize={'xs'}
                                    color={'#707070'}
                                >
                                    Currently your plan is on auto renewal, you can toggle it off</Text>
                            </VStack>
                            <Switch size='lg' isChecked />
                        </HStack>
                    }
                </VStack>
            </VStack>

            {plan === 'free' ?
                <Button
                    onClick={() => {
                        navigate('/market-value');
                    }}
                    colorScheme="red"
                    py={6}
                    rounded={'34px'}
                    w={{ base: '60%', md: '30%', lg: '20%' }}
                    position={'absolute'}
                    bottom={'0'}
                    left={'50%'}
                    transform={'translate(-50%, 50%)'}
                    fontSize={'xs'}
                    mt={{ base: 4, md: 2 }}
                >
                    Start Trial
                </Button>
                :
                <Button
                    onClick={() =>onOpen()}
                    colorScheme="red"
                    py={6}
                    rounded={'34px'}
                    w={{ base: '60%', md: '30%', lg: '20%' }}
                    position={'absolute'}
                    bottom={'0'}
                    left={'50%'}
                    transform={'translate(-50%, 50%)'}
                    fontSize={'xs'}
                    mt={{ base: 4, md: 2 }}
                >
                    Pay Now $1
                </Button>
            }

           <PaymentSuccessful isOpen={isOpen} onClose={onClose} onOpen={onOpen} />

        </Card>
    )
}

